export enum Colors {
  'lightGrey' = '#A3A2A2',
  'gray5' = '#E0E0E0',
  'darkGrey' = '#828282',

  'blue' = '#2F80ED',

  'black' = '#1D1D1D',
  'white' = '#FFFFFF',
  'gold' = '#E9BB58',

  'red' = '#EB5757',
  'semiLight' = '#B8B6C5',

}
