import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { proposalsGetProposalsList } from '@store/proposals/actionCreators';
import { proposalsSelectors } from '@store/proposals/selectors';
import { GetProposalsListPayload, ProposalType } from '@types';

type UseGetProposalListProps = {
  pageLimit: number;
  proposalType: ProposalType;
  isSearchInputEmpty: boolean;
  page: number,
};

export function useGetProposalsList({
  pageLimit,
  proposalType,
  page,
  isSearchInputEmpty,
}: UseGetProposalListProps) {
  const dispatch = useDispatch();

  const isProposalsListLoading = useSelector(proposalsSelectors.getProp('isProposalsListLoading'));
  const commonProposalsList = useSelector(proposalsSelectors.getProp('commonProposalsList'));
  const commonProposalsTotal = useSelector(proposalsSelectors.getProp('commonProposalsTotal'));

  useEffect(() => {
    if (isSearchInputEmpty) {
      const pageOffset = page * pageLimit - pageLimit;

      const payload: GetProposalsListPayload = {
        pageLimit,
        pageOffset,
        proposalType,
      };
      dispatch(proposalsGetProposalsList(payload));
    }
  }, [
    page,
    dispatch,
    pageLimit,
    proposalType,
    isSearchInputEmpty,
  ]);

  return {
    isProposalsListLoading,
    commonProposalsList,
    commonProposalsTotal,
  };
}
