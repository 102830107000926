import {
  call, takeLeading, put, select,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import { Unwrap, ToastMessages } from '@types';
import { contracts } from '@constants';
import { createProposal } from '@api/back';
import {
  createVoting, approveRPVToken, allowanceRPVToken, getCreatingContractAddress,
  getChainId,
} from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalItemCreate, proposalItemSetState } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { toastInfo, toastClose, toastSuccess } from '@components';
import { ethers } from 'ethers';

const qtyVotersPayload = 1;
const minPercentageVotersPayload = 50;

function* createProposalSaga({ payload }: ReturnType<typeof proposalItemCreate>) {
  try {
    const salt = ethers.utils.hexlify(ethers.utils.randomBytes(32));
    yield put(proposalItemSetState({ isCreatingProposal: true }));
    const {
      time, date,
    } = payload;
    date!.setHours(+time!.split(':')[0]);
    date!.setMinutes(+time!.split(':')[1]);

    const now = +((Date.now() / 1000).toFixed(0));
    const dateSec = +(+date!.getTime() / 1000);
    const duration = +((dateSec - now).toFixed(0)) as unknown as string;
    const land = { country: payload.land.country };
    const organizationEmployees = payload.organizationEmployees
      ? payload.organizationEmployees : [''];

    const chainId:number = yield call(getChainId);

    const priceForCreate:string = yield select(proposalItemSelectors.getProp('priceForCreate'));
    const allowanceRPVTokenValue:string = yield call(
      allowanceRPVToken,
      contracts[chainId]?.VotingFactory,
    );

    if (+allowanceRPVTokenValue < +priceForCreate) {
      toastInfo(ToastMessages.TRANSACTION_REQUEST);
      yield call(approveRPVToken, contracts[chainId]?.VotingFactory);
      toastClose();
      toastSuccess(ToastMessages.TRANSACTION_SUCCESS);
    }

    const expiresAt = new Date(dateSec * 1000).toISOString();
    const proposalAddress:Unwrap<typeof getCreatingContractAddress> = yield call(
      // @ts-ignore
      getCreatingContractAddress,
      salt,
      duration,
      qtyVotersPayload,
      minPercentageVotersPayload,
    );

    const {
      hash,
      qtyVoters,
      minPercentageVoters,
      // @ts-ignore
    }: Unwrap<typeof createProposal> = yield call(createProposal, {
      ...payload,
      duration,
      land,
      organizationEmployees,
      address: proposalAddress,
      expiresAt,
      qtyVoters: qtyVotersPayload,
      minPercentageVoters: minPercentageVotersPayload,
    });
    console.log(hash, 'hash');
    yield call(
      createVoting,
      hash,
      duration,
      qtyVoters,
      minPercentageVoters,
      salt,
    );
    toastClose();

    yield put(
      proposalItemSetState({
        isSuccessCreate: true,
        isCreatingProposal: false,
      }),
    );
  } catch (error) {
    yield put(proposalItemSetState({ isCreatingProposal: false }));
    sagaErrorHandler(error as string);
  }
}

export function* proposalItemCreateSaga() {
  yield takeLeading(
    ProposalItemActionTypes.CREATE_PROPOSAL,
    createProposalSaga,
  );
}
