import React, { useState, useMemo, useCallback } from 'react';
import { Modal } from '@components';
import { useHistory } from 'react-router-dom';
import { ButtonTheme } from '@types';

export const useCancelModal = () => {
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const history = useHistory();

  const closeModal = useCallback(() => {
    setIsCancelModalOpened(false);
  }, []);

  const closeModalAndStopCreation = useCallback(() => {
    setIsCancelModalOpened(false);
    history.push('/profile');
  }, [history]);

  const openCancelModal = useCallback(() => {
    setIsCancelModalOpened(true);
  }, []);

  const cancelModalButtons = useMemo(
    () => [
      {
        children: 'Yes',
        onClick: closeModalAndStopCreation,
        key: 1,
      },
      {
        children: 'No',
        onClick: closeModal,
        theme: ButtonTheme.outline,
        key: 2,
      },
    ],
    [closeModal, closeModalAndStopCreation],
  );

  const ModalCancel = useCallback(() => (
    <Modal
      title="Cancel?"
      description="Do you really want to cancel the proposal creation?"
      isOpen={isCancelModalOpened}
      onClose={closeModal}
      buttons={cancelModalButtons}
    />
  ), [cancelModalButtons, closeModal, isCancelModalOpened]);

  return {
    openCancelModal,
    ModalCancel,
  };
};
