import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import { ProfileActionTypes } from '@store/profile/actionTypes';
import { Unwrap } from '@types';
import {
  profileGetBalance,
  profileSetState,
} from '@store/profile/actionCreators';
import { getProfile } from '@api/back';
import { sagaErrorHandler } from '@utils';
import { profileDefaultState } from './constants';

function* getProfileSaga() {
  try {
    const profile: Unwrap<typeof getProfile> = yield call(getProfile);
    if (profile) {
      yield put(profileSetState({
        ...profileDefaultState,
        ...profile,
        location: {
          ...profile.location,
          region: { value: 'region', name: 'region', visibility: false },
        },
      }));

      yield put(profileGetBalance());
    }
  } catch (error) {
    // @ts-ignore
    if (error.message === 'Request failed with status code 404') {
      yield put(profileSetState(profileDefaultState));
    } else {
      // @ts-ignore
      sagaErrorHandler(error);
    }
  }
}

export function* profileGetProfileSaga() {
  yield takeLeading(ProfileActionTypes.GET_PROFILE, getProfileSaga);
}
