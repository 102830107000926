import React, {
  memo, useCallback,
} from 'react';
import cx from 'classnames';
import Loader from 'react-loader-spinner';
import {
  Card,
  // Pagination,
  Text,
} from '@components';
import { useCardsPagination } from '@hooks';
import {
  Colors, Proposal, ProposalType,
} from '@types';

import styles from './styles.module.scss';
import { EmptyTab } from './EmptyTab';

type ProposalsSingleTabContentProps = {
  cards: Proposal[],
  cardsPerPage: number,
  cardsTotal: number,
  classNameTab?: string,
  classNameGridWrapper: string,
  isAddressVerified?: boolean;
  isDocVerified?: boolean;
  isLoading?: boolean;
  isProposalsScreen?: boolean;
  tabKey?: ProposalType;
  submitNewPageNumber?: (page: number) => void;
  onOpenProposalClick?: (hash: string) => void;
  isSearch?: boolean;
};

const ProposalsSingleTabContent = memo<ProposalsSingleTabContentProps>(({
  cards,
  cardsPerPage,
  cardsTotal,
  classNameTab,
  classNameGridWrapper,
  isAddressVerified,
  isLoading,
  isProposalsScreen,
  tabKey,
  submitNewPageNumber,
  onOpenProposalClick,
  isSearch,
}) => {
  const {
    pagesText,
    // pageCount,
    // onPageChange,
  } = useCardsPagination({
    cardsTotal,
    cardsPerPage,
    submitNewPageNumber,
  });

  const renderLoader = useCallback(() => (
    <div className={styles.loaderContainer}>
      <Loader
        type="Rings"
        color={Colors.gold}
        height={100}
        width={100}
        timeout={0}
      />
    </div>
  ), []);

  const isNeedLandBaseVerification = tabKey !== ProposalType.CryptoVoting;

  const renderCards = useCallback(() => (
    cards.map((proposal) => (
      <Card
        proposal={proposal}
        className={styles.cardWrapper}
        key={proposal.hash}
        onOpenClick={onOpenProposalClick}
      />
    ))), [cards, onOpenProposalClick]);

  if (!isAddressVerified && isNeedLandBaseVerification
    && !isProposalsScreen
    && !isLoading
  ) {
    return (
      <EmptyTab
        isNeedVerify
      />
    );
  }

  if (cards.length === 0 && !isLoading) {
    return (
      <EmptyTab
        isProposalsScreen={isProposalsScreen}
        isSearch={isSearch}
        tabKey={tabKey}
      />
    );
  }
  return (
    <div className={cx(styles.tabContent, classNameTab)}>

      { isLoading ? renderLoader()
        : (
          <div className={classNameGridWrapper}>
            {renderCards()}
            {' '}
          </div>
        )}

      <div className={styles.pagesInfo}>
        <Text type="span" className={styles.pagesText}>
          {pagesText}
        </Text>
        {/* <Pagination
          pageCount={pageCount}
          onPageChange={onPageChange}
        /> */}
      </div>
    </div>
  );
});

export { ProposalsSingleTabContent };
