import React from 'react';
import cx from 'classnames';
import { Icon } from '@components';
import {
  Icons, Colors,
} from '@types';
import styles from './styles.module.scss';

type LinkIconProps = {
  className?: string;
  classNameIcon?: string;
  href?: string;
  icon: Icons;
  iconColor?: Colors;
};

const LinkIcon = ({
  className = '', href, icon, iconColor = Colors.lightGrey, classNameIcon = '',
}: LinkIconProps) => (
  <a
    href={href}
    className={cx(className)}
  >
    <Icon
      value={icon}
      style={{ color: iconColor }}
      className={cx(styles.icon, classNameIcon)}
    />
  </a>
);

export { LinkIcon };
