// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_buttonIcon__2iTip {\n  border: none;\n  background-color: transparent;\n  padding: 8px 5px 5px 5px;\n  cursor: pointer;\n}\n.styles_buttonIcon__2iTip:hover {\n  opacity: 0.2;\n}", "",{"version":3,"sources":["webpack://src/components/ButtonIcon/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,6BAAA;EACA,wBAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;AACJ","sourcesContent":[".buttonIcon {\n  border: none;\n  background-color: transparent;\n  padding: 8px 5px 5px 5px;\n  cursor: pointer;\n\n  &:hover {\n    opacity: 0.2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonIcon": "styles_buttonIcon__2iTip"
};
export default ___CSS_LOADER_EXPORT___;
