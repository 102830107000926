import React, { FC } from 'react';
import {
  Props as SelectProps,
  OptionTypeBase,
} from 'react-select';
import cx from 'classnames';
import styles from './styles.module.scss';

type Option = {
  value: string;
  label: string;
};

type IProps = SelectProps<OptionTypeBase> & {
  selectedValue: {
    value: string;
    label: string;
  };
  selectOptions: {
    value: string;
    label: string;
  }[];
  className?:string
};

const Tab:FC<Option &{
  isTwoTabs?:boolean
  , selectedValue:string,
  onChange:(args:{ value:string }
  )=>void }> = ({
  onChange, value, label, selectedValue, isTwoTabs,
}) => {
  const onClickHandler = () => {
    onChange({ value });
  };
  return (
    <button
      type="button"
      className={`${styles.tab} ${value === selectedValue ? styles.active : ''} ${isTwoTabs ? styles.isTwoTabs : ''}`}
      onClick={onClickHandler}
    >
      {label}
    </button>
  );
};

export const MobileTabs: FC<IProps> = ({
  selectedValue, selectOptions, onChange, className, isTwoTabs,
}) => (
  <div className={cx(styles.mobileTabs, className)}>
    {selectOptions.map((tab) => (
      <Tab
        key={tab.value}
        // @ts-ignore
        onChange={onChange}
        selectedValue={selectedValue.value}
        value={tab.value}
        label={tab.label}
        isTwoTabs={isTwoTabs}
      />
    ))}
  </div>
  // <Select
  //   value={selectedValue}
  //   options={selectOptions}
  //   onChange={onChange}
  //   isSearchable={false}
  //   components={{
  //     ValueContainer,
  //     Control,
  //     IndicatorSeparator: () => null,
  //   }}
  // />
);
