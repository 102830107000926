import { getWeb3 } from '@api/ethereum';
import { Chain } from '@types';

export async function switchChainId(chain: Chain) {
  const web3 = getWeb3();

  const chainIdHex = web3.utils.toHex(chain.chainId);

  if (window.ethereum.networkVersion !== chain.chainId) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }],
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if ((err as unknown as { code:number })?.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              ...chain,
              chainId: chainIdHex,
            },
          ],
        });
      }
    }
  }
}
