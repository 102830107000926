import styled from '@emotion/styled';
import { colors, mediaQueries } from '@constants';
import { ButtonProps } from './Button.types';

export const StyledButton = styled('button') <ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  gap: 10px;
  width: ${({ width }) => width || 'auto'};
  /* height: 38px; */
  padding: 9px 36px;
  background: ${({ variant }) => (variant === 'filled' || variant === 'dark' ? colors.gold : colors.goldRgba)};
  border-radius: 16px;
  color: ${({ variant }) => (variant === 'filled' || variant === 'dark' ? colors.black : colors.white)};
  transition: 0.15s ease-in-out;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid ${colors.gold};
  flex: none;
  box-shadow: ${colors.boxShadow};
  &:hover {
    /* color: ${({ variant }) => (variant === 'dark' ? colors.white : colors.black)}; */
    /* color: ${colors.white};
    background: ${({ variant }) => (variant === 'dark' ? colors.black : colors.gold2)};
    box-shadow: ${({ variant }) => (variant === 'dark' ? colors.boxShadow : colors.boxShadowHover)};
    filter: none;
    border: 2px solid ${({ variant }) => (variant === 'dark' ? colors.black : colors.gold)}; */
  }
  ${mediaQueries.lg} {
    font-size: 14px;
    font-weight: 400;
    /* &:hover {
      color: ${({ variant }) => (variant === 'dark' ? colors.black : colors.white)};
      box-shadow: ${({ variant }) => variant === 'filled' && colors.boxShadowHover};
      filter: ${({ variant }) => variant === 'outlined' && colors.filterDrop};
    } */
    &:hover {

        margin-top: -14px;

    }
  }
    /* &:hover {
      color: ${({ variant }) => (variant === 'dark' ? colors.black : colors.white)};
      box-shadow: ${({ variant }) => variant === 'filled' && colors.boxShadowHover};
      filter: ${({ variant }) => variant === 'outlined' && colors.filterDrop};
    } */

  /* &:disabled {
    opacity: 0.5;
    cursor: default;
  } */
`;
