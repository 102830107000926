import React, { memo, useCallback } from 'react';
import { FieldArray, Field } from 'formik';
import { ButtonIcon } from '@components';
import { Icons } from '@types';
import { Keyword } from '../Keyword';
import styles from './styles.module.scss';

type IProps = {
  name: string,
  keywords: string[],
  currentKeyword: string,
  setField: (name: string, value: [] | string) => void,
};
export const CustomFieldArray: React.FC<IProps> = memo(({
  name,
  keywords,
  currentKeyword,
  setField,
}) => {
  const clearClick = useCallback(() => {
    setField('keywords', []);
  }, [setField]);

  const renderField = useCallback((arrayHelpers) => (
    <div>
      <div className={styles.keywordsLabel}>Keywords</div>
      <div className={styles.keywordsOut}>
        <div className={styles.keywordsInputOut}>
          <Field
            name="currentKeyword"
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter' && currentKeyword.length > 0) {
                event.preventDefault();
                if (keywords.length < 20) {
                  if (!keywords.includes(currentKeyword.toLowerCase())) {
                    arrayHelpers.push(currentKeyword.toLowerCase());
                  }
                  setField('currentKeyword', '');
                }
              }
            }}
            autoComplete="off"
            className={styles.keywordsInput}
          />
          {keywords.length > 0 && (
            <ButtonIcon
              icon={Icons.close}
              className={`${styles.keywordsBtnClose} ${styles.buttonCloseInput}`}
              onClick={clearClick}
            />
          )}
        </div>
      </div>
      {keywords.length > 0 ? (
        <div className={styles.keywords}>
          {keywords.map((keyword, index) => (
            <Keyword
              key={keyword}
              text={keyword}
              onClose={() => arrayHelpers.remove(index)}
            />
          ))}
        </div>
      ) : null}
    </div>
  ), [keywords, clearClick, currentKeyword, setField]);

  return (
    <FieldArray
      name={name}
      render={renderField}
    />
  );
});
