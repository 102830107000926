import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import cx from 'classnames';
import { ContentTabs, ProposalType } from '@types';
import { useMobileTabs } from './hooks';
import { MobileTabs } from './components/MobileTabs';
import styles from './styles.module.scss';

type TabsProps = {
  content: ContentTabs;
  className?: string;
  classNameHeader?: string;
  classNameContainer?: string;
  handleShowModal?: (tabKey: ProposalType) => void;
  disabledTabKeys?: ProposalType[];
  tabKeys?: ProposalType[];
  submitTabKey?: (tabKey: ProposalType) => void;
  isCentred?:boolean
  isTwoTabs?:boolean
};

const Tabs = memo(({
  content,
  className,
  // classNameHeader,
  // classNameContainer,
  handleShowModal,
  disabledTabKeys = [],
  tabKeys,
  isCentred,
  isTwoTabs,
  submitTabKey,
}: TabsProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { headers } = content;

  const mobileTabContent = useMemo(
    () => content.tabContents[tabIndex],
    [content.tabContents, tabIndex],
  );

  const handleTab = useCallback(
    (index) => {
      if (disabledTabKeys !== undefined
        && tabKeys !== undefined
        && disabledTabKeys.includes(tabKeys[index])
        && handleShowModal !== undefined
      ) {
        handleShowModal(tabKeys[index]);
      } else {
        setTabIndex(index);
        if (tabKeys !== undefined && submitTabKey !== undefined) {
          submitTabKey(tabKeys[index]);
        }
      }
    },
    [disabledTabKeys, handleShowModal, submitTabKey, tabKeys],
  );

  const { selectOptions, selectedValue, onChange } = useMobileTabs({
    headers,
    tabIndex,
    handleTab,
  });

  return (
    <>
      {/* <RTabs
        selectedIndex={tabIndex}
        onSelect={handleTab}
        className={cx(styles.tabsWrapper, className)}
      >
        <TabList className={cx(styles.tabList, classNameHeader)}>
          {content.headers.map((h) => (
            <Tab key={h} selectedClassName={styles.selectedTab} className={cx(styles.tab)}>
              {h}
            </Tab>
          ))}
        </TabList>
        {content.tabContents.map((tabContent, index) => (
          <TabPanel
            className={cx(
              tabIndex !== index && styles.hidden,
              styles.tabPanel,
            )}
            // In Tabs headers length must be equal numbers of TabPanel or console err
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {tabContent}
          </TabPanel>
        ))}
      </RTabs> */}
      <div className={cx(styles.selectWrapper, className)}>
        <MobileTabs
          isCentred={isCentred}
          selectedValue={selectedValue}
          selectOptions={selectOptions}
          onChange={onChange}
          className={isCentred ? styles.centredWrapper : ''}
          isTwoTabs={isTwoTabs}
        />
        {mobileTabContent}
      </div>
    </>
  );
});

export { Tabs };
