import { ProfileState } from '@store/profile/types';
import { HandlerFn } from '@types';
import { profileSetState } from '@store/profile/actionCreators';
import { ProfileActionTypes } from '@store/profile/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProfileHandlerFn<F extends (...args: any) => any> = HandlerFn<
ProfileState,
ReturnType<F>
>;

const setState: ProfileHandlerFn<typeof profileSetState> = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const PROFILE_HANDLERS = {
  [ProfileActionTypes.SET_STATE]: setState,
};
