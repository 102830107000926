import React, { MouseEvent, ReactNode, useMemo } from 'react';
import cx from 'classnames';

import { ButtonTheme, Colors } from '@types';
import Loader from 'react-loader-spinner';
import styles from './styles.module.scss';

export type ButtonProps = {
  onClick?: (event: MouseEvent) => void,
  theme?: ButtonTheme;
  disabled?: boolean,
  isLoading?: boolean,
  children?: ReactNode,
  className?: string,
  htmlType?: 'button' | 'submit' | 'reset';
};

const Button = ({
  onClick,
  disabled = false,
  isLoading,
  theme = ButtonTheme.primary,
  children,
  className = '',
  htmlType = 'button',
}: ButtonProps) => {
  const loaderColor = useMemo(() => {
    let color = Colors.white;
    if (theme === ButtonTheme.outline || theme === ButtonTheme.white) {
      color = Colors.black;
    }
    return color;
  }, [theme]);
  return (
    <button
      disabled={disabled}
    // eslint-disable-next-line react/button-has-type
      type={htmlType}
      className={cx(
        styles.button,
        {
          [styles.outline]: theme === ButtonTheme.outline,
          [styles.black]: theme === ButtonTheme.black,
          [styles.white]: theme === ButtonTheme.white,
        },
        className,
      )}
      onClick={onClick}
    >
      <div className={styles.buttonContent}>
        {
      isLoading ? (
        <Loader
          type="TailSpin"
          color={loaderColor}
          height={20}
          width={20}
          // timeout={30000}
        />
      ) : children
    }
      </div>
    </button>
  );
};

export { Button };
