import { ProposalType } from '@types';

export { CARDS_PER_PAGE } from './cardsperpage';

export const headers = [
  'Non Geo Gated',
  'Geo Gated',
  // 'Organisational Votings',
];

export const tabKeys = [
  ProposalType.CryptoVoting,
  ProposalType.LandBasedVoting,
  // ProposalType.OrganizationalVoting,
];
