import React, { memo } from 'react';
import { formatToThousands } from '@utils';
import { Text } from '@components';

import styles from './styles.module.scss';

type StatisticsItemProps = {
  title: string;
  value: string;
};

const StatisticsItem = memo(({
  title,
  value,
}: StatisticsItemProps) => (
  <div className={styles.container}>
    <Text type="p" className={styles.title}>{title}</Text>
    <Text type="h2" className={styles.value}>{formatToThousands(value)}</Text>
  </div>
));
export { StatisticsItem };
