import { all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { adminEffects } from '@store/admin/sagas';
import { authEffects } from '@store/auth/sagas';
import { profileEffects } from '@store/profile/sagas';
import { proposalsEffects } from '@store/proposals/sagas';
import { proposalItemEffects } from '@store/proposalItem/sagas';

export default function* sagas(): SagaIterator {
  yield all([
    ...adminEffects,
    ...authEffects,
    ...profileEffects,
    ...proposalsEffects,
    ...proposalItemEffects,
  ]);
}
