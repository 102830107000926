import {
  call, takeLeading, put, all,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import { Unwrap } from '@types';
import {
  getBalanceBnB,
  getAddress,
} from '@api/ethereum';
import { ProfileActionTypes } from '@store/profile/actionTypes';
import { profileSetState } from '@store/profile/actionCreators';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

function* getBalanceAllSaga() {
  try {
    const address: Unwrap<typeof getAddress> = yield call(getAddress);
    const [
      balanceBNB,
    ] : [
      Unwrap<typeof getBalanceBnB>,
    ] = yield all([
      call(getBalanceBnB, address),
    ]);

    yield put(
      profileSetState({
        balanceBNB: new BigNumber(Web3.utils.fromWei(balanceBNB))
          .decimalPlaces(18).toString(),
        getBalanceBNBWei: balanceBNB,
      }),
    );
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* profileGetBalanceSaga() {
  yield takeLeading(ProfileActionTypes.GET_BALANCE, getBalanceAllSaga);
}
