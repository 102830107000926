import React, { FC, CSSProperties } from 'react';
import cx from 'classnames';
import type { Icons } from '@types';

type Props = {
  className?: string,
  value: Icons,
  style?: CSSProperties,
};

const Icon:FC<Props> = ({ className, value, style }) => (
  <i className={cx(`icon-${value}`, className)} style={style} />
);

export { Icon };
