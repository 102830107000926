import React, { RefObject } from 'react';
import { Text, Footer } from '@components';
import { FooterTheme } from '@types';
import atomImg from '@assets/images/home/atom.png';
import styles from './styles.module.scss';

type SectionThesisProps = {
  refSection: RefObject<HTMLDivElement>;
};

const SectionThesis = ({
  refSection,
}: SectionThesisProps) => (
  <section className={styles.containerThesis} ref={refSection}>
    <div className={styles.containerThesisSection}>

      <div className={styles.content}>

        <div className={styles.containerInfo}>
          <Text type="h2" className={styles.title}>
            Thesis
          </Text>
          <div className={styles.imgMobile} />
          <Text type="p" className={styles.subTitle}>
            REPRESENTVOTE is fundamentally changing the landscape of governance
            by empowering the people to become the government themselves.
            <br />
            <br />
            REPRESENT&apos;s innovative platform democratizes governance,
            making it a daily participatory practice,
            thus transforming the traditional dynamics between citizens and
            decision-makers and fostering a more inclusive,
            responsive, and efficient democratic environment.

          </Text>
          <div className={styles.atomImg}>
            <div className={styles.atomImgActive}>

              <img alt="" src={atomImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer theme={FooterTheme.white} className={styles.footer} />
  </section>
);

export { SectionThesis };
