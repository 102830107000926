import { call, takeLeading, put } from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import { getProposal } from '@api/back';
import { Unwrap } from '@types';
import {
  getStatsVoting,
  getBalanceVoting,
  getAllVoters,
  getAddress,
} from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import {
  proposalItemSetState,
  proposalIteGet,
} from '@store/proposalItem/actionCreators';
import { profileGetBalance } from '@store/profile/actionCreators';

function* getProposalSaga({
  payload: hashItem,
}: ReturnType<typeof proposalIteGet>) {
  try {
    yield put(
      proposalItemSetState({
        isFetching: true,
      }),
    );
    const proposal: Unwrap<typeof getProposal> = yield call(
      getProposal,
      hashItem,
    );

    const { address } = proposal;

    yield put(
      proposalItemSetState({
        ...proposal,
        isFetching: false,
      }),
    );

    if (address !== '') {
      const statsVoting: Unwrap<typeof getStatsVoting> = yield call(
        getStatsVoting,
        address,
      );
      const { _count: countAllVoting, _for: countYesVoting } = statsVoting;

      const balanceVoting: Unwrap<typeof getBalanceVoting> = yield call(
        getBalanceVoting,
        address,
      );
      const allVotingAddress: Unwrap<typeof getAllVoters> = yield call(
        getAllVoters,
        address,
      );

      const addressWallet: Unwrap<typeof getAddress> = yield call(getAddress);

      const isVoting = allVotingAddress.includes(addressWallet);

      yield put(
        proposalItemSetState({
          countAllVoting: +countAllVoting,
          percentYes: +countAllVoting > 0
            ? +Number((+countYesVoting / +countAllVoting) * 100).toFixed(2)
            : 0,
          balanceVoting: +balanceVoting,
          isVoting,
        }),
      );

      yield put(profileGetBalance());
    }
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* proposalItemGetProposalSaga() {
  yield takeLeading(ProposalItemActionTypes.GET_PROPOSAL, getProposalSaga);
}
