// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal__1dN5q {\n  width: 406px;\n}", "",{"version":3,"sources":["webpack://src/containers/ProposalItem/components/SuccessModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".modal {\n  width: 406px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles_modal__1dN5q"
};
export default ___CSS_LOADER_EXPORT___;
