import { ReduxState } from '@store/types';
import { AuthState } from './types';

export const authSelectors = {
  getProp: <PropKey extends keyof AuthState>(propKey: PropKey) => (
    state: ReduxState,
  ) => state.auth[propKey],
  isAdmin: () => (
    state: ReduxState,
  ) => state.profile.role.name.includes('admin'),
};
