// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_link__wLXEj {\n  font-size: 11px;\n  text-decoration: none;\n  text-align: left;\n  margin-bottom: 2px;\n}\n\n.styles_darkColor__Wp7Fs {\n  color: #828282;\n}\n\n.styles_whiteColor__1oauz {\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://src/components/Link/styles.module.scss","webpack://src/constants/styles.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,cCCS;ADHX;;AAKA;EACE,WCeM;ADjBR","sourcesContent":["@import \"@constants/styles.scss\";\n@import \"@constants/mixins.scss\";\n\n.link {\n  font-size: 11px;\n  text-decoration: none;\n  text-align: left;\n  margin-bottom: 2px;\n}\n\n.darkColor {\n  color: $darkGrey;\n}\n\n.whiteColor {\n  color: $white;\n}\n","$fontFamily: helvetica neue;\n$fontello: fontello;\n\n$futura: futura;\n$openSans: 'Open Sans', sans-serif;\n\n$fontIBM: ibm plex sans;\n\n$pageWidth: 1070px;\n\n$gray: #f2f2f2;\n\n$darkGrey: #828282;\n$darkGrey1: #797979;\n$lightGrey: #bdbdbd;\n$lightGrey1: #e0e0e0;\n$lightGrey2: #fafafa;\n$lightGrey3: #dcdcdc;\n\n$black: #333;\n$black1: #1d1d1d;\n$blackHalfTransparent: rgba(0, 0, 0, 0.5);\n\n$yellow: #e3bd67;\n\n$primaryBlue: #3e66fb;\n\n$blue: #2f80ed;\n\n$black: #1d1d1d;\n$white: #fff;\n\n$gold: #e3bd67;\n\n$red: #eb5757;\n\n$cardHeight: 120px;\n$cardWidth: 330px;\n\n$animationTimeout: 5s;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "styles_link__wLXEj",
	"darkColor": "styles_darkColor__Wp7Fs",
	"whiteColor": "styles_whiteColor__1oauz"
};
export default ___CSS_LOADER_EXPORT___;
