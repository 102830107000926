import React, { useCallback, useState, useEffect } from 'react';
import cx from 'classnames';
import { ButtonIcon } from '@components';
import { Icons } from '@types';
import { sanitize } from 'dompurify';
import styles from './styles.module.scss';

type TextAreaProps = {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  classNameInner?: string;
};

const TextArea = ({
  className = '',
  label,
  placeholder = '',
  value = '',
  onChange,
  classNameInner,
}: TextAreaProps) => {
  const [valueInput, setValueInput] = useState(value);
  const [sanitizedHTML, setSanitizedHTML] = useState<{ __html: string } | undefined>(undefined);

  const handleChange = useCallback((event:React.ChangeEvent<HTMLTextAreaElement>) => {
    const sanitizedValue = sanitize(event.currentTarget.innerHTML);
    setValueInput(sanitizedValue);
  }, []);

  const handleBlur = useCallback(() => {
    onChange(valueInput);
  }, [onChange, valueInput]);

  const clearClick = useCallback(() => {
    onChange('');
  }, [onChange]);

  useEffect(() => {
    const sanitizedValue = sanitize(value);
    setSanitizedHTML({ __html: sanitizedValue });
  }, [value]);

  return (
    <div
      className={cx(
        styles.wrapper,
        className,
      )}
    >
      {label && <div className={cx(styles.label)}>{label}</div>}
      {/* eslint-disable-next-line */}
      <span
        onInput={handleChange}
        onBlur={handleBlur}
        className={cx(styles.textarea, classNameInner)}
        placeholder={placeholder}
        contentEditable
        role="textbox"
        suppressContentEditableWarning
        // dangerouslySetInnerHTML is needed here for save line breaks
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={sanitizedHTML}
      />
      {valueInput.length > 0 && (
      <ButtonIcon
        icon={Icons.close}
        className={styles.clearButton}
        onClick={clearClick}
      />
      )}
    </div>
  );
};

export { TextArea };
