import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { toastError, toastClose } from '@components';
import { chainIds } from '@constants';
import { authGetAccessToken, authLogout } from '@store/auth/actionCreators';
import { injected } from '../connectors';

export function useEagerConnect() {
  // specifically using useWeb3ReactCore because of what this hook does
  const { activate, active } = useWeb3ReactCore();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else if (isMobile && window.ethereum) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  // specifically using useWeb3React because of what this hook does
  const {
    active, error, activate, deactivate, chainId,
  } = useWeb3ReactCore();
  const dispatch = useDispatch();

  useEffect(() => {
    if (chainId !== undefined && !chainIds.includes(chainId)) {
      toastError('We don\'t support this current network, please choose Arbitrum or Base');
    }
  }, [chainId]);

  useEffect(() => {
    const { ethereum } = window;

    if (active && suppress) {
      dispatch(authGetAccessToken());
    }

    if (ethereum && ethereum.on && !error && !suppress) {
      const handleConnect = () => {
        activate(injected).then(() => {
          dispatch(authGetAccessToken());
        });
      };

      const handleChainChanged = () => {
        // eat errors
        toastClose();
        activate(injected, undefined, true)
          .then(() => {
            dispatch(authGetAccessToken(true));
          })
          .catch((errorMsg) => {
            console.error('Failed to activate after chain changed', errorMsg);
          });
      };

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true)
            .then(() => {
              dispatch(authLogout());
              dispatch(authGetAccessToken(true));
            })
            .catch((errorMsg) => {
              console.error(
                'Failed to activate after accounts changed',
                errorMsg,
              );
            });
        }
      };

      const handleNetworkChanged = () => {
        activate(injected).then(() => {
          dispatch(authGetAccessToken(true));
        });
      };

      const handleDisconnect = () => {
        deactivate();
        dispatch(authLogout());
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('disconnect', handleDisconnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('disconnect', handleDisconnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate, dispatch, deactivate]);
}
