import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from '@store/profile/selectors';

import { getKycLink } from '@api/back';

export function useKyc() {
  const isDocVerified = useSelector(profileSelectors.isDocVerified());
  const isAddressVerified = useSelector(profileSelectors.isAddressVerified());
  const isVerified = useMemo(
    () => isAddressVerified || isDocVerified,
    [isAddressVerified, isDocVerified],
  );

  const handleVerifyClick = useCallback(async () => {
    const linkKyc = await getKycLink();
    window.open(linkKyc, '_blank');
  }, []);

  return {
    isVerified,
    handleVerifyClick,
    isAddressVerified,
  };
}
