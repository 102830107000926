import { createReducer } from '@utils';
import { AuthState } from '@store/auth/types';
import { AUTH_HANDLERS } from '@store/auth/handlers';

const AUTH_INITIAL_STATE: Readonly<AuthState> = {
  addressHash: '',
  token: '',
  expiresIn: 0,
  hasPassport: false,
};

export default createReducer(AUTH_INITIAL_STATE, AUTH_HANDLERS);
