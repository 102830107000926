import React, { ReactNode } from 'react';
import { useAuth } from '@hooks';
import { Header, Footer, Spinner } from '@components';
import { useLocation, Switch } from 'react-router-dom';
import styles from './styles.module.scss';

type LayoutProps = { children: ReactNode };

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const {
    account,
    isLoading,
    statusHeader,
    isAdmin,
  } = useAuth();

  if (location.pathname === '/') {
    return (
      <>
        {children}
      </>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.containerLoading}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.layout}>
      <Header
        status={statusHeader}
        address={account}
        isAdmin={isAdmin}
      />
      <div className={styles.body}>
        <Switch>
          {children}
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export { Layout };
