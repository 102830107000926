import React from 'react';
import classNames from 'classnames/bind';
import { Spinner } from '@components';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type LoaderProps = {
  isShow?: boolean;
  isShowConnectButton?: boolean;
};

const Loader = ({ isShow = false, isShowConnectButton = true }: LoaderProps) => (
  <div className={cx(styles.loader, { hideClass: !isShow })}>
    <Spinner />
    {isShowConnectButton && <div className={styles.connectWallet} />}
  </div>
);

export { Loader };
