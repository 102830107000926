import { useAuth } from '@hooks';
import { useWeb3React } from '@web3-react/core';
import React, { FC, ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authGetAccessToken } from '@store/auth/actionCreators';
import { injected } from '../../connectors';
import { Button } from '../button copy';
import { toastError } from '../Toast';

export const ConnectButton: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const {
    activate,
  } = useAuth();
  const {
    active,
  } = useWeb3React();

  const handleConnectWallet = useCallback(async () => {
    if (window.ethereum) {
      if (active) {
        dispatch(authGetAccessToken());

        return;
      }
      activate(injected);

      dispatch(authGetAccessToken());
    } else {
      toastError('Please, install Metamask extension');
    }
  }, [activate, active, dispatch]);

  return (
    <Button variant="filled" onClick={handleConnectWallet}>
      connect wallet
    </Button>
  );
};
