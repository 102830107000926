/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '@assets/images/upload.svg';
import styles from './styles.module.scss';

type ImageUploadProps = {
  onChange: (img: Partial<HTMLInputElement>) => void;
  className?: string;
};
export const ImageUpload: React.FC<ImageUploadProps> = ({
  onChange,
  className,
}) => {
  const [url, setUrl] = useState('');
  const onDrop = useCallback(
    (acceptedFiles) => {
      const curFile = acceptedFiles[0];
      if (!curFile) return;
      setUrl(URL.createObjectURL(curFile));
      onChange(curFile);
    },
    [onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className={cx(styles.out, className, url ? styles.uploadedOut : '')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getRootProps()}
      onClick={(e) => e.stopPropagation()}
    >
      {url && (
      <img
        src={url}
        alt="logo"
        className={styles.imageBackground}
      />
      )}
      {url && (
      <div className={styles.absoluteContainer}>
        <label className={cx(styles.inputLabel, styles.mouseOverDisplayed, styles.uploadedLabel)}>
          <span className={styles.bigDevice}>Change image</span>
          <span className={styles.smallDevice}>Change image</span>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="file" name="image" id="image" value="" {...getInputProps()} autoComplete="off" />
        </label>
      </div>

      )}
      {!url && (
        <div className={styles.absoluteContainer}>
          <label className={styles.inputLabel}>
            <img src={uploadIcon} alt="" />
            {/* {isDragActive
              ? <span>del</span>
              : (
                <>
                  <span className={styles.bigDevice}>Choose file or Drag and Drop</span>
                  <span className={styles.smallDevice}>Choose file</span>
                </>
              )} */}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input type="file" name="image" id="image" value="" {...getInputProps()} autoComplete="off" />
          </label>
        </div>
      )}

    </div>
  );
};
