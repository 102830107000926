import React, { useMemo } from 'react';
import { Modal } from '@components';
import { ButtonTheme } from '@types';
import { StatusVote } from '../../types';
import styles from './styles.module.scss';

type PropsVoteModal = {
  isOpen: boolean;
  name: string;
  status: StatusVote;
  onClose: () => void;
  onVoteYes: () => void;
  onVoteNot: () => void;
};

const VoteModal = ({
  isOpen, onClose, status, name, onVoteYes, onVoteNot,
}: PropsVoteModal) => {
  const title = useMemo(() => {
    if (status === StatusVote.finished) {
      return 'The proposal is finished';
    }
    return `Vote ${name}`;
  }, [status, name]);

  const description = useMemo(() => {
    if (status === StatusVote.voted) {
      return 'You have already voted in this proposal. You can’t vote twice!';
    }

    if (status === StatusVote.finished) {
      return "Unfortunately you can't vote. The proposal is finished";
    }

    if (status === StatusVote.notBalance) {
      return `You dont have enough tokens to vote in this proposal.
      Buy one and go back!`;
    }

    return `You are going to vote for the proposal ${name}. How do you want to vote?`;
  }, [status, name]);

  const buttons = useMemo(() => {
    if (status === StatusVote.voted || status === StatusVote.notBalance) {
      return [
        {
          children: 'Close',
          theme: ButtonTheme.outline,
          onClick: onClose,
          key: 'close',
        },
      ];
    }
    return [
      {
        children: 'Cancel',
        theme: ButtonTheme.outline,
        onClick: onClose,
        key: 'cancel',
      },
      {
        children: 'No',
        theme: ButtonTheme.outline,
        key: 'no',
        onClick: onVoteNot,
      },
      {
        children: 'Yes',
        key: 'yes',
        onClick: onVoteYes,
      },
    ];
  }, [status, onClose, onVoteYes, onVoteNot]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      description={description}
      buttons={buttons}
      className={styles.modal}
      descriptionName={styles.description}
    />
  );
};

export { VoteModal };
