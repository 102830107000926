import { AdminState, UpdateSettingsPayload } from '@store/admin/types';
import { AdminActionTypes } from '@store/admin/actionTypes';

export const adminSetState = (payload: Partial<AdminState>) => ({
  type: AdminActionTypes.SET_STATE,
  payload,
});

export const adminGetStatistics = () => ({
  type: AdminActionTypes.GET_STATISTICS,
});

export const adminGetSettings = () => ({
  type: AdminActionTypes.GET_SETTINGS,
});

export const adminUpdateSettings = (payload: UpdateSettingsPayload) => ({
  type: AdminActionTypes.UPDATE_SETTINGS,
  payload,
});
