import React, { useCallback } from 'react';
import { Text, Button } from '@components';
import { RouteName } from '@types';
import { history } from '@utils';
import mainLeft from '@assets/images/main-left.png';
import mainRight from '@assets/images/main-right.png';
import goldCube from '@assets/images/gold-cube.png';
import silverCubes from '@assets/images/silver-cube.png';

import error404 from '@assets/images/error404.svg';
import styles from './styles.module.scss';

const ErrorPage = () => {
  const onGoToMainPageClick = useCallback(() => {
    history.push(RouteName.home);
  }, []);

  return (
    <div className={styles.content}>
      <Text type="p" className={styles.pageNotFoundText}>
        Page not found
      </Text>
      <Text type="h3" className={styles.mainText}>
        it&#39;s not the end of world.
        <br />
        Check back again shortly.
      </Text>
      <div className={styles.buttonsContainer}>

        <Button
          className={styles.btn}
          onClick={onGoToMainPageClick}
        >
          home page
        </Button>
      </div>
      <img src={mainLeft} alt="" className={styles.mainLeft} />
      <img src={goldCube} alt="" className={styles.goldCube} />
      <img src={silverCubes} alt="" className={styles.silverCube} />
      <img src={mainRight} alt="" className={styles.mainRight} />
      <img src={error404} alt="" className={styles.error404} />
    </div>
  );
};

export { ErrorPage };
