import {
  call, takeLeading, put, select,
} from 'redux-saga/effects';
import { ToastMessages } from '@types';
import { sagaErrorHandler } from '@utils';
// import { voteYes, voteNot } from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalItemSetState, proposalIteGet, proposalItemVote } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import {
  toastInfo, toastClose,
} from '@components';
import { makeVote } from '@api/ethereum/votingFactory';

function* voteSaga({ payload }: ReturnType<typeof proposalItemVote>) {
  try {
    yield put(proposalItemSetState({ isSuccess: false }));
    const {
      proposalId,
      choice,
      value,
    } = payload;

    const hash = yield select(proposalItemSelectors.getProp('hash'));
    // const proposalType = yield select(proposalItemSelectors.getProp('proposalType'));
    // const addressUser = yield call(getAddress);
    // console.log(addressUser, addressVoting);
    // let isUserInAllowList = yield call(checkUserInAllowList, addressVoting, addressUser);
    // console.log(isUserInAllowList, 'isUserInAllowList');
    // if (proposalType === ProposalType.LandBasedVoting) {
    //   toastInfo(ToastMessages.WHITE_LIST_REQUEST);
    //   try {
    //     yield call(addToAllowList, addressVoting);
    //   } catch (e) {
    //     toastClose();
    //     toastError(ToastMessages.WHITE_LIST_ERROR);
    //     throw e;
    //   }
    // }

    // after call addToAllowList add to allow list really happens with delay cause blockchain
    // let retryCount = 0;
    // while (!isUserInAllowList) {
    //   yield delay(5000);
    //   isUserInAllowList = yield call(checkUserInAllowList, addressVoting, addressUser);

    //   retryCount++;
    //   if (retryCount > 30) {
    //     toastClose();
    //     toastError(ToastMessages.WHITE_LIST_ERROR);
    //     throw new Error(ToastMessages.WHITE_LIST_ERROR);
    //   }
    // }
    toastClose();
    // toastSuccess(ToastMessages.WHITE_LIST_SUCCESS);

    toastInfo(ToastMessages.TRANSACTION_REQUEST);

    yield call(
      makeVote,
      proposalId,
      choice,
      value,
    );

    toastClose();

    yield put(proposalItemSetState({ isSuccess: true }));

    yield put(proposalIteGet(hash));
  } catch (error) {
    sagaErrorHandler(error);
  }
}

export function* proposalItemVoteSaga() {
  yield takeLeading(ProposalItemActionTypes.VOTE, voteSaga);
}
