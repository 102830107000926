import React from 'react';
import cx from 'classnames';

import {
  Text, Button, CurrencyItem,
} from '@components';
import {
  ButtonTheme,
} from '@types';
import { CurrencyItemProps } from 'components/CurrencyItem';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/auth/selectors';
import styles from './styles.module.scss';

type WalletCardProps = {
  address?: string | null;
  onVerifyClick?: () => void;
  isVerified?: boolean;
  currencyItemsList: CurrencyItemProps[];
};

const WalletCard = ({
  address,
  onVerifyClick,
  isVerified = false,
  currencyItemsList = [],
}: WalletCardProps) => {
  // const onBuyTokensClick = useCallback(() => {
  //   history.push(RouteName.proposalStore);
  // }, []);
  const hasPassport = useSelector(authSelectors.getProp('hasPassport'));

  return (
    <section className={styles.container}>
      <div className={styles.walletTitle}>my wallet</div>
      {currencyItemsList.map(({ value, name }) => (
        <CurrencyItem
          value={value}
          name={name}
          key={name}
        />
      ))}

      <div className={styles.addressLabel}>
        Wallet Address
      </div>
      <div className={styles.copyContainer}>
        {/* <div className={styles.addressContainer}>
          <Text type="span" className={styles.address}>
            {address}
          </Text>
        </div> */}
        <div className={styles.addressText}>{address}</div>
        {/* <CopyToClipboard text={address || ''}>
          <ButtonIcon
            icon={Icons.copy}
            className={styles.copyButton}
            iconColor={Colors.white}
            classNameIcon={styles.copyButtonIcon}
          />
        </CopyToClipboard> */}
      </div>
      {!hasPassport && (
      <>
        <Text type="span" className={cx(styles.verifyText, { [styles.isVerified]: hasPassport })}>
          You have not minted Represent ID NFT passport
          {/* {isVerified ? 'Your account is verified' : 'You have not verifyed your account'} */}

        </Text>
        <Button
          theme={isVerified ? ButtonTheme.outline : ButtonTheme.black}
          className={styles.verifyButton}
          onClick={onVerifyClick}
        >
          create your own NFT passport
          {/* {isVerified ? 'Correct your KYC data' : 'Verify account with KYC'} */}

        </Button>
      </>
      )}

    </section>
  );
};

export { WalletCard };
