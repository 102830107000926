import React from 'react';
import ReactSelect, {
  OptionTypeBase,
  Styles as StylesProp,
  Props as SelectProps,
  GroupTypeBase,
} from 'react-select';
import classNames from 'classnames/bind';
import indicator from '@assets/images/indicator.svg';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const customStylesDefault = {
  container: (provided: any) => ({
    ...provided,
    marginBottom: '0',
    width: 'fit-content',
    padding: '0px !important',
  }),
  menu: (menu:any) => ({
    ...menu,
    background: '#0B0B0B',
    top: '18px',
    padding: '7px 0 7px 2px',
    margin: 0,
    minWidth: 150,
    '@media only screen and (max-width: 1439px)': {
      top: '15px',
    },
  }),
  control: (provided:any) => ({
    ...provided,
    height: '21px',
    minHeight: '21px',
    background: '#0B0B0B',
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontSize: 16,
    lineHeight: '1',
    padding: '0 px',
    fontWeight: 300,
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    '&::placeholder': {
      color: '#fff',
      padding: '0px',
    },
    '&:hover': {
      border: 'none',
      lineHeight: '1',
    },
    '@media only screen and (max-width: 1439px)': {
      fontSize: 14,
    },
  }),
  singleValue: () => ({
    color: '#fff',
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),
  valueContainer: () => ({
    height: '21px',
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#fff',
    padding: '0px',
    width: '100%',
    textAlign: 'right',
    position: 'relative',
    top: 0,
    transform: 'none',
  }),
  indicatorsContainer: () => ({
    width: '10px',
    height: '21px',
    padding: '0px !important',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
  }),
  input: () => ({
    width: '10px',
    height: '10px',
    padding: '0px',
  }),
  // menuList: (menu:any) => ({ ...menu, padding: '10px ', background: 'red' }),
  option: (provided:any) => ({
    ...provided,
    border: 'none',
    boxShadow: null,
    color: '#DCDCDC',
    outline: 0,
    background: '#0B0B0B',
    padding: '4px 0',
    textAlign: 'left',
    '&:hover': {
      background: '#0B0B0B',
    },
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1,
    '@media only screen and (max-width: 1439px)': {
      fontSize: 10,
    },
  }),
};

const componentsDefaults = {
  IndicatorSeparator: () => null,
};

type Item = {
  value: string;
  label: string;
};

type IProps = SelectProps<OptionTypeBase> & {
  name? : string,
  placeholder?: string,
  label?: string,
  classNameContainer?: string,
  classNameLabel? : string,
  items: Item[] | [],
  customStyles?:
  Partial<StylesProp<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>>
  | undefined,
};

export const SelectProposalType:React.FC<IProps> = ({
  onChange,
  placeholder,
  name,
  items,
  components = componentsDefaults,
  classNameContainer,
  customStyles = customStylesDefault,
}) => (
  <div className={cx(styles.containerSelect, classNameContainer)}>
    <div className={styles.wrapperSelect}>
      <ReactSelect
        onChange={onChange}
        options={items}
        name={name}
        isSearchable={false}
        components={{
          ...components,
          DropdownIndicator: (state) => (
            <div className={`${styles.indicator} ${state?.isFocused ? styles.indicatorOpen : ''}`}>
              <img src={indicator} alt="" />
            </div>
          ),
        }}
        placeholder={placeholder}
        styles={customStyles}
      />
    </div>
  </div>
);
