import React, { RefObject } from 'react';
import { Text } from '@components';
import coinsDesk from '@assets/images/home/coins.png';
import coin1 from '@assets/images/home/coin1.png';
import coin2 from '@assets/images/home/coin2.png';
import coin3 from '@assets/images/home/coin3.png';
import styles from './styles.module.scss';

type SectionTokenProps = {
  refSection: RefObject<HTMLDivElement>;
};

const SectionToken = ({
  refSection,
}: SectionTokenProps) => (
  <section className={styles.containerToken} ref={refSection}>
    <div className={styles.content}>
      <div className={styles.containerInfo}>
        <Text type="h2" className={styles.title}>
          REPRESENT
          <br />
          token
        </Text>
        <Text type="p" className={styles.subTitle}>
          REPRESENT token (RPV) is a native platform token used for
          all platform interactions including Passport Minting,
          Voting and Proposal Creation.
          Incentives holding through reduced platform fees and token rewards.
        </Text>
        <div className={styles.coins}>
          <img className={styles.coinsDesk} alt="" src={coinsDesk} />
          <img className={styles.coin1} alt="" src={coin1} />
          <img className={styles.coin2} alt="" src={coin2} />
          <img className={styles.coin3} alt="" src={coin3} />
        </div>
      </div>
    </div>
  </section>
);

export { SectionToken };
