import { AuthState } from '@store/auth/types';
import { HandlerFn } from '@types';
import { authSetState } from '@store/auth/actionCreators';
import { AuthActionTypes } from '@store/auth/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AuthHandlerFn<F extends (...args: any) => any> = HandlerFn<
AuthState,
ReturnType<F>
>;

const setState: AuthHandlerFn<typeof authSetState> = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const AUTH_HANDLERS = {
  [AuthActionTypes.SET_STATE]: setState,
};
