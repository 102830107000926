import { ProposalsState } from '@store/proposals/types';
import { HandlerFn } from '@types';
import { proposalsSetState } from '@store/proposals/actionCreators';
import { ProposalsActionTypes } from '@store/proposals/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProposlasHandlerFn<F extends (...args: any) => any> = HandlerFn<
ProposalsState,
ReturnType<F>
>;

const setState: ProposlasHandlerFn<typeof proposalsSetState> = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const PROPOSALS_HANDLERS = {
  [ProposalsActionTypes.SET_STATE]: setState,
};
