import React, { useRef, useCallback } from 'react';
import classNames from 'classnames/bind';
import useScrollSpy from 'react-use-scrollspy';
import { Header, toastError } from '@components';
import { HeaderTheme } from '@types';
import { useAuth } from '@hooks';
import {
  SectionMain, SectionKey, SectionToken, SectionTeam, SectionProduct, SectionThesis,
} from './components';
import { useLoader } from './hooks';
import { injected } from '../../connectors';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const HomePage = () => {
  const isLoader = useLoader();

  const {
    activate, active, account, statusHeader, isAdmin,
  } = useAuth();

  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -80,
  });

  const handleScrollDown = () => {
    if (sectionRefs[1] && sectionRefs[1].current) {
      // @ts-ignore: Object is possibly 'null'.
      sectionRefs[1].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleConnectWallet = useCallback(async () => {
    if (window.ethereum) {
      activate(injected);
    } else {
      toastError('Please, install Metamask extension');
    }
  }, [activate]);

  const isShow = isLoader && !active;

  return (
    <div className={styles.home}>
      <Header
        className={cx(styles.header, {
          hideClass: isShow,
          backgroundBlur: [1, 2, 3, 4].includes(activeSection),
        })}
        theme={
          [0, 2, 4].includes(activeSection)
            ? HeaderTheme.dark
            : HeaderTheme.default
        }
        status={statusHeader}
        address={account}
        isAdmin={isAdmin}
      />
      <SectionMain
        refSection={sectionRefs[0]}
        onScrollDown={handleScrollDown}
        onConnectWallet={handleConnectWallet}
        isLoader={isLoader}
      />

      <SectionKey refSection={sectionRefs[1]} />

      <SectionToken refSection={sectionRefs[2]} />

      <SectionTeam refSection={sectionRefs[3]} />

      <SectionProduct refSection={sectionRefs[4]} />

      <SectionThesis refSection={sectionRefs[5]} />
    </div>
  );
};

export { HomePage };
