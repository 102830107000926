import {
  put, takeLeading,
} from 'redux-saga/effects';
import { AuthActionTypes } from '@store/auth/actionTypes';
import { authSetState } from '@store/auth/actionCreators';
import { sagaErrorHandler } from '@utils';

function* refreshAccessTokenSaga() {
  try {
    // const isRefreshToken = yield select(authSelectors.getProp('isRefreshToken'));
    // if (!isRefreshToken) {
    //   yield put(
    //     authSetState({
    //       isRefreshToken: true,
    //     }),
    //   );
    //   const refreshTokenForRecover = yield select(authSelectors.getProp('refreshToken'));

    //   const res: Unwrap<typeof refresh> = yield call(
    //     refresh,
    //     refreshTokenForRecover,
    //   );

    //   if (res) {
    //     const {
    //       accessToken, refreshToken, exp, expRefresh, roles,
    //     } = res;

    //     yield put(
    //       authSetState({
    //         accessToken,
    //         refreshToken,
    //         exp,
    //         expRefresh,
    //         roles,
    //         isRefreshToken: false,
    //       }),
    //     );
    //   }
    // }
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
    yield put(
      authSetState({
        expiresIn: 0,
      }),
    );
  }
}

export function* authRefreshAccessTokenSaga() {
  yield takeLeading(AuthActionTypes.REFRESH_ACCESS_TOKEN, refreshAccessTokenSaga);
}
