export function formatTimeDifference(timestamp:number) {
  if (timestamp <= 0 || !Number.isInteger(timestamp)) {
    return 'Expired';
  }
  const secondsInADay = 24 * 3600;
  const secondsInAnHour = 3600;
  const secondsInAMinute = 60;

  const t = timestamp / 1000;

  const days = Math.floor(t / secondsInADay);
  const hours = Math.floor((t % secondsInADay) / secondsInAnHour);
  const minutes = Math.floor((t % secondsInAnHour) / secondsInAMinute);
  const seconds = Math.floor(t % secondsInAMinute);

  return `Ends in ${days}d ${hours}h ${minutes}m ${seconds}s`;
}
