import React, { RefObject, useEffect, useRef } from 'react';
import { Text } from '@components';
import networkImg from '@assets/images/home/network.png';
import { List } from '../List';
import { listProducts } from '../../constants';

import styles from './styles.module.scss';

type SectionProductProps = {
  refSection: RefObject<HTMLDivElement>;
};

const SectionProduct = ({
  refSection,
}: SectionProductProps) => {
  const section = useRef(null);

  useEffect(() => {
    if (!section.current) {
      return;
    }

    // @ts-ignore
    const lines = section.current.querySelectorAll('.line'); // Класс каждой строки
    const handleScroll = () => {
      // @ts-ignore
      const rect = section.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const sectionTop = rect.top + scrollTop;
      // @ts-ignore
      if (window.scrollY + (section.current.offsetHeight) > sectionTop) {
      // @ts-ignore
        lines.forEach((line, index) => {
          setTimeout(() => {
            line.classList.add(styles.animateIn);
          }, index * 100);
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className={styles.containerProduct} ref={refSection}>
      <div className={styles.networkImg}>
        <img alt="" src={networkImg} />
      </div>
      <div className={styles.content} ref={section}>
        <div className={styles.containerImage} />
        <div className={styles.containerInfo}>
          <Text type="h2" className={`${styles.title} ${styles.line} line`}>
            Use Cases
          </Text>
          <List
            items={listProducts}
            classNameItem={styles.subTitle}
            className={styles.list}
            classNameEl={styles.line}
          />
        </div>
      </div>
    </section>
  );
};

export { SectionProduct };
