import { AdminState } from '@store/admin/types';
import { HandlerFn } from '@types';
import { adminSetState } from '@store/admin/actionCreators';
import { AdminActionTypes } from '@store/admin/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AdminHandlerFn<F extends (...args: any) => any> = HandlerFn<
AdminState,
ReturnType<F>
>;

const setState: AdminHandlerFn<typeof adminSetState> = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const ADMIN_HANDLERS = {
  [AdminActionTypes.SET_STATE]: setState,
};
