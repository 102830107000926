import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type SpinnerProps = { className?: string };

const Spinner = ({ className = '' }: SpinnerProps) => (
  <div className={cx(styles.ldsRing, className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export { Spinner };
