import { Chain } from '@types';
import arbitrumIcon from '@assets/images/arbitrum.svg';
import bscIcon from '@assets/images/bsc.png';

export const urlApi = process.env.REACT_APP_API_URL;
export const portApi = 8080;

export const passwordSign = 'test password!';

export const timeGap = 8640000;

const bscChainId = 56;
const arbitrumChainId = 42161;

export const bscChain: Chain = {
  chainName: 'BNB Chain',
  chainId: bscChainId,
  nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
  rpcUrls: ['https://rpc.ankr.com/bsc/'],
  icon: bscIcon,
};

export const arbitrumChain: Chain = {
  chainName: 'Arbitrum',
  chainId: arbitrumChainId,
  nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
  rpcUrls: ['https://arb1.arbitrum.io/rpc/'],
  icon: arbitrumIcon,
};

export const chainIds = [arbitrumChainId, bscChainId];

export const chains = [arbitrumChain, bscChain];

export const contracts: Record<number, {
  RPTToken: string
  RPVtoken: string
  RPVsale: string
  VotingFactory: string
  VotingAllowList: string
  Passport: string
}> = {
  [bscChainId]: {
    RPTToken: '0x9f88629Ef6B192182E362c9902198da17CA6E1EF',
    RPVtoken: '0xcf7aDf930c4f7c48395A308fE66e01E137bbA5b6',
    RPVsale: '0xd2c93f9bEb845ff59E18e9D09e8978a9DDca9142',
    VotingFactory: '0xcc839e0e85E436630E7341F2f6F320946210092d',
    VotingAllowList: '0xb3B2AC29eD54A5b8fEf3C7d71380814551565012',
    Passport: '0xb34d37921ebe6c41a22b55c8364e29b30ad2a722',
  },
  [arbitrumChainId]: {
    RPTToken: '0x9f88629Ef6B192182E362c9902198da17CA6E1EF',
    RPVtoken: '0xcf7aDf930c4f7c48395A308fE66e01E137bbA5b6',
    RPVsale: '0xd2c93f9bEb845ff59E18e9D09e8978a9DDca9142',
    VotingFactory: '0xcc839e0e85E436630E7341F2f6F320946210092d',
    VotingAllowList: '0xb3B2AC29eD54A5b8fEf3C7d71380814551565012',
    Passport: '0xb34d37921ebe6c41a22b55c8364e29b30ad2a722',
  },
};

export const decimalPlaces = 4;

export const bnbDecimals = 18;
export const rpvDecimals = 18;

export const gatewayIPFS = 'https://ipfs.io/ipfs/';

export const ACCESS_TOKEN_LS = 'access_token_represent';

export const breakpointMap: { [key: string]: number } = {
  sm: 360,
  md: 768,
  lg: 1440,
  xl: 1920,
};

export const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
};

export const colors = {
  black: '#000',
  white: '#ffffff',
  yellow: '#cfa345',
  gray: '#A3A2A2',
  gray2: '#a8a8a8',
  grayRgba: 'rgba(148, 148, 148, 0.15)',
  gold: '#E9BB58',
  gold2: '#AB8A43',
  goldRgba: 'rgba(31, 20, 3, 0.4)',
  goldRgba3: 'rgba(164, 105, 13, 0.4)',
  green: '#0c5c08',
  red: '#c72c3b',
  boxShadow: '7px 7px 20px rgba(0, 0, 0, 0.25)',
  gradientModal: 'linear-gradient(89.91deg, #000000 15.66%, #252525 99.94%)',
  textShadow: '14px 14px 50px rgba(0, 0, 0, 0.3)',
  filterDrop:
    'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5)) drop-shadow(0px 4px 4px #000000) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5)) drop-shadow(-8px -7px 35px #000000)',
  boxShadowHover:
    '0px 4px 4px rgba(0, 0, 0, 0.5), 0px 4px 4px #000000, 0px 4px 4px rgba(0, 0, 0, 0.5), -8px -7px 35px #000000',
};
