import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { proposalIteBuyVotingToken } from '@store/proposalItem/actionCreators';

export function useBuy(): {
  isShowBuyModal: boolean,
  showBuy: () => void,
  closeBuy: () => void,
  handleBuy: () => void,
} {
  const [isShowBuyModal, setIsShowBuyModal] = useState(false);
  const dispatch = useDispatch();

  const showBuy = useCallback(() => {
    setIsShowBuyModal(true);
  }, []);

  const closeBuy = useCallback(() => {
    setIsShowBuyModal(false);
  }, []);

  const handleBuy = useCallback(() => {
    dispatch(proposalIteBuyVotingToken());
    setIsShowBuyModal(false);
  }, [dispatch]);

  return {
    isShowBuyModal,
    showBuy,
    closeBuy,
    handleBuy,
  };
}
