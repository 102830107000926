import React from 'react';
import { FieldProps, Field } from 'formik';
import { Select, SelectProposalType } from '@components';
import styles from './styles.module.scss';

export type FormikSelectItem = {
  value: string;
  label: string;
};

type FormikSelectProps = {
  name: string,
  label: string,
  className?: string,
  items: FormikSelectItem[],
  isSelectType?:boolean
  placeholder?:string
};

export const FormikSelect: React.FC<FormikSelectProps> = ({
  name, label, items, isSelectType, placeholder = 'Choose',
}) => (
  <Field name={name}>
    {({ form }: FieldProps) => (
      <>
        {isSelectType ? (
          <SelectProposalType
            onChange={(value) => form.setFieldValue(name, value)}
            items={items}
            name={name}
            placeholder={placeholder}
            label={label}
            classNameLabel={styles.label}
            classNameContainer={styles.container}
          />
        ) : (
          <Select
            onChange={(value) => form.setFieldValue(name, value)}
            items={items}
            name={name}
            placeholder={placeholder}
            label={label}
            classNameLabel={styles.label}
            classNameContainer={styles.container}
          />
        )}
      </>
    )}
  </Field>
);
