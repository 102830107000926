import React from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import { Icons } from '@types';
import { Text } from '../Text';
import { Icon } from '../Icon';

import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

const ToastPortal = () => (
  <ToastContainer
    position="top-center"
    autoClose={false}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={false}
    limit={1}
  />
);

const Msg = ({ title }: { title: string }) => (
  <>
    <Text>{title}</Text>
    <Icon value={Icons.arrow} className={styles.iconMsg} />
  </>
);

const toastClose = () => {
  toast.dismiss();
  toast.clearWaitingQueue();
};

const toastSuccess = (title: string, options?: ToastOptions) => {
  toast.success(<Msg title={title} />, {
    ...options,
    autoClose: 3000,
  });
};

const toastError = (msg: string, options?: ToastOptions) => {
  toast.error(msg, options);
};

const toastInfo = (msg: string, options?: ToastOptions) => {
  toast.info(msg, options);
};

export {
  ToastPortal, toast, toastSuccess, toastClose, toastError, toastInfo,
};
