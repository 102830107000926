import {
  // call,
  delay,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { ProposalsActionTypes } from '@store/proposals/actionTypes';
// import { Unwrap } from '@types';
import {
  // proposalsGetProposalsSearch,
  proposalsSetState,
} from '@store/proposals/actionCreators';
// import { getProposalsSearch } from '@api/back';
import { sagaErrorHandler } from '@utils';
// import { Proposal } from '../types';
// import { formatRawProposal } from './formatRawProposal';

// @TODO return logic
// { payload }: ReturnType<typeof proposalsGetProposalsSearch>
function* getProposalsSearchSaga() {
  try {
    yield put(proposalsSetState({ isProposalsSearchLoading: true }));

    yield delay(300);

    // const {pageLimit, pageOffset, proposalType, query } = payload;

    // const queryFormatted = query.replace(/\s/g, '%20');

    // @TODO return search when backend is ready
    // const requestUrl = ` ?query=${queryFormatted}&page.limit=
    // ${pageLimit}&page.offset=${pageOffset}&proposal_type=${proposalType}`;

    // const {
    //   list: proposalsListRaw,
    //   total,
    // }: Unwrap<typeof getProposalsSearch> = yield call(
    //   getProposalsSearch, requestUrl,
    // );

    // const proposalsList: Proposal[] = proposalsListRaw.map(
    //   (rawProposal) => formatRawProposal(rawProposal),
    // );

    yield put(proposalsSetState({
      searchProposalsList: [], // proposalsList,
      searchProposalsTotal: 0, // total,
      isProposalsSearchLoading: false,
    }));
  } catch (error) {
    yield put(proposalsSetState({ isProposalsSearchLoading: false }));
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* proposalsGetProposalsSearchSaga() {
  yield takeLatest(ProposalsActionTypes.GET_PROPOSALS_SEARCH, getProposalsSearchSaga);
}
