import {
  put, takeLeading,
} from 'redux-saga/effects';
import { AuthActionTypes } from '@store/auth/actionTypes';
import { authSetState } from '@store/auth/actionCreators';
import { removeLocalStorage, sagaErrorHandler } from '@utils';
import { ACCESS_TOKEN_LS } from '@constants';
import { profileSetState } from '@store/profile/actionCreators';
import { profileDefaultState } from '@store/profile/sagas/constants';

export function* logoutSaga() {
  try {
    removeLocalStorage(ACCESS_TOKEN_LS);

    yield put(
      authSetState({
        token: '',
        expiresIn: 0,
        addressHash: '',
        hasPassport: false,
      }),
    );

    yield put(profileSetState(profileDefaultState));
    // }
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* authLogoutSaga() {
  yield takeLeading(AuthActionTypes.LOG_OUT, logoutSaga);
}
