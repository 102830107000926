import BigNumber from 'bignumber.js';

export const peelBigNumber = (
  value: string,
  decimals: number = 18,
) => new BigNumber(value)
  .dividedBy(new BigNumber(10).pow(decimals)).toFixed();

export const convertToBigNumber = (
  value: string,
  decimals: number = 18,
) => new BigNumber(value)
  .multipliedBy(new BigNumber(10).pow(decimals)).toFixed();
