export const listKeys = [
  {
    label: 'Transparent & Tamper-proof:', text: 'All votes are visible and cemented on the blockchain by all participants',
  },
  {
    label: 'Built-in Digital Passport:',
    text: 'Users maintain control of their identity information through a Sovereign NFT passport',
  },
  {
    label: 'User Anonymity:',
    text: 'All verified users are linked to randomized wallet ID, protecting privacy and user safety',
  },
  {
    label: 'Geo-Fencing & Audience Segmentation:',
    text: 'View audience statistics filtered by geographic boundaries, residences, age, organization, political affiliation, and gender',
  },

  {
    label: 'Real-Time Data:',
    text: 'Votes are requested, collected, and automatically reported in seconds',
  },

  {
    label: 'Incentivized Sovereignty:',
    text: 'Users are encouraged to participate through tradable token-rewards',
  },
];

export const listProducts = [
  {
    label: 'Government Decision-Making and Governance:',
    text: 'Enabling transparent and efficient public voting on policies and referendums.',
  },
  {
    label: 'Corporate Governance:',
    text: 'Streamlining shareholder voting and stakeholder engagement in corporate decision-making processes.',
  },
  {
    label: 'Community Engagement:',
    text: 'Facilitating local community decisions on development projects and urban planning.',
  },
  {
    label: 'Healthcare Policy Feedback:',
    text: 'Gathering patient and public input on healthcare initiatives and policies.',
  },
  {
    label: 'Educational Policy and Governance:',
    text: 'Enabling stakeholder participation in educational institutions\' decision-making.',
  }, {
    label: 'Web3 DAOs:',
    text: 'Providing white-label solutions for decentralized autonomous organizations (DAOs) in the blockchain and Web3 space, enhancing democratic governance in tech projects.',
  },

];
