import axios, { AxiosRequestConfig } from 'axios';
import {
  urlApi,
} from '@constants';
import { RouteName } from '@types';
import { history } from '@utils';

export const getUrlApi = () => `${urlApi}`;

const api = axios.create({
  baseURL: getUrlApi(),
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    // Origin: '*',
  },
});

const sleepRequest = (
  milliseconds: number,
  originalRequest: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => new Promise((
  resolve,
) => {
  setTimeout(() => resolve(api(originalRequest)), milliseconds);
});

let myInterceptor: number;

export const addAuthInterceptor = (token: string) => {
  myInterceptor = api.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });
};

export const removeAuthInterceptor = () => {
  if (myInterceptor !== undefined) {
    api.interceptors.request.eject(myInterceptor);
  }
};

// let isRefreshToken = false;

// api.interceptors.request.use(async (config) => {
//   const state = await store.getState();
//   const { token, expiresIn } = state.auth;
//   const timeNow = (new Date()).getTime() / 1000;
//   if ((state.auth.expiresIn - timeGap) > 0) {
//     isRefreshToken = true;
//     store.dispatch(authSetState({
//       token,
//       refreshToken,
//       exp,
//       expRefresh,
//       roles,
//       isRefreshToken: false,
//     }));
//     isRefreshToken = false;

//     return ({
//       ...config,
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//   }
//   if (state.auth.expRefresh && timeNow > (state.auth.expRefresh - timeGap)) {
//     store.dispatch(authLogout());
//   }
//   if (isRefreshToken) {
//     return sleepRequest(1000, config);
//   }
//   return ({
//     ...config,
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });
// }, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, (error) => {
  const { config } = error;
  const originalRequest = config;

  const isGetProfileCurrent = config?.url === '/kyc/profile/current';

  if (error.response && error.response.status === 401 && !originalRequest.isRepeated) {
    originalRequest.isRepeated = true;
    return sleepRequest(1000, originalRequest);
  }
  if (error.response && error.response.status === 404 && !isGetProfileCurrent) {
    history.push(RouteName.error);
  }
  return Promise.reject(error);
});

export default api;
