import { ProposalRaw } from '@types';

export const formatRawProposal = ({
  hash,
  address,
  status,
  imageHash,
  name,
  proposalType,
  description,
  keywords,
  stats,
  ...rest
}: ProposalRaw) => ({
  hash,
  address,
  name,
  status,
  proposalType,
  imageHash,
  description,
  keywords,
  stats,
  ...rest,
});
