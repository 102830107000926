// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_uploadIcon__2h5y2 {\n  margin-left: 11.5px;\n}", "",{"version":3,"sources":["webpack://src/containers/ProposalCreation/components/FileUpload/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".uploadIcon {\n  margin-left: 11.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadIcon": "styles_uploadIcon__2h5y2"
};
export default ___CSS_LOADER_EXPORT___;
