import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { LinkTheme } from '@types';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type LinkProps = {
  className?: string;
  href?: string;
  theme?: LinkTheme;
  children: ReactNode;
};

const Link = ({
  className = '', href = '/#', theme = LinkTheme.default, children,
}: LinkProps) => (
  <a
    href={href}
    className={cx(styles.link, className, {
      darkColor: theme === LinkTheme.default,
      whiteColor: theme === LinkTheme.white,
    })}
  >
    {children}
  </a>
);

export { Link };
