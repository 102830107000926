import { useMemo, useCallback } from 'react';

type Props = {
  headers: string[];
  filter?: (index: number) => void;
  handleTab: (index: number) => void;
  tabIndex: number;
};

export const useMobileTabs = ({
  headers, tabIndex, handleTab,
}: Props) => {
  const selectOptions = useMemo(
    () => headers.map((header) => ({
      value: header,
      label: header,
    })),
    [headers],
  );

  const onChange = useCallback(
    ({ value: nextValue }) => {
      const nextIndex = selectOptions.findIndex(({ value }) => value === nextValue);
      handleTab(nextIndex);
    },
    [selectOptions, handleTab],
  );

  const selectedValue = useMemo(() => {
    const currentHeader = headers[tabIndex];
    return {
      value: currentHeader,
      label: currentHeader,
    };
  }, [headers, tabIndex]);

  return {
    selectOptions,
    onChange,
    selectedValue,
  };
};
