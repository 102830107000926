import { RouteName } from '@types';
import { HeaderConfig } from './Nav.types';

export const navConfig: HeaderConfig = [
  {
    label: 'Main',
    url: RouteName.home,
  },
  {
    label: 'Proposals',
    url: RouteName.proposals,
  },
  {
    label: 'Profile',
    url: RouteName.profile,
  },
];
