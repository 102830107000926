/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';

import * as T from './types';
import * as S from './units';

const Loader: FC<T.ILoader> = ({ size = 24, ...props }) => (
  <S.Loader {...{ size }} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto', background: 'transparent', display: 'block', shapeRendering: 'auto',
      }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="50" cy="50" fill="none" stroke="#235ee7" strokeWidth="10" r="44" strokeDasharray="200 50">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.7s" values="0 50 50;360 50 50" keyTimes="0;1" />
      </circle>
    </svg>
  </S.Loader>
);

export { Loader };
