import {
  call, takeLeading, put,
} from 'redux-saga/effects';
import { Unwrap } from '@types';
import { peelBigNumber, sagaErrorHandler } from '@utils';
import { getPriceCreateProposal } from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalItemSetState } from '@store/proposalItem/actionCreators';

function* getPriceForVotingSaga() {
  try {
    const priceForCreateInWei: Unwrap<typeof getPriceCreateProposal> = yield call(
      getPriceCreateProposal,
    );

    const priceForCreateInEther = peelBigNumber(priceForCreateInWei, 18);

    yield put(
      proposalItemSetState({
        priceForCreate: priceForCreateInEther,
        priceForCreateWei: priceForCreateInWei,
      }),
    );
  } catch (error) {
    sagaErrorHandler(error);
  }
}

export function* proposalItemGetPricesForVotingSaga() {
  yield takeLeading(
    ProposalItemActionTypes.GET_PRICES_FOR_VOTING,
    getPriceForVotingSaga,
  );
}
