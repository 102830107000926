import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Icons } from '@types';
import { Icon, Text } from '@components';
import { ButtonIcon } from '../ButtonIcon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type InputProps = {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  classNameInner?: string;
  name?: string;
};

const SearchInput = ({
  className = '',
  placeholder = 'search',
  value = '',
  onChange,
  classNameInner,
  name = '',
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    }, [onChange],
  );

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  const onPlaceholderClick = useCallback(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
      setIsFocused(true);
    }
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <div className={cx(styles.containerInput, className)}>
      <Icon
        value={Icons.search}
        className={styles.searchIconInput}
      />
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        type="text"
        className={cx(styles.input, classNameInner)}
        name={name}
        onBlur={onBlur}
        autoComplete="off"
      />
      {value.length > 0 && (
        <ButtonIcon
          icon={Icons.close}
          className={styles.buttonClose}
          onClick={handleClear}
        />
      )}
      {!isFocused && value.length === 0 && (
      <button
        type="button"
        className={styles.placeholderContainer}
        onClick={onPlaceholderClick}
      >
        {/* <Icon
          value={Icons.search}
          className={styles.placeholderSearchIcon}
        /> */}
        <Text type="p" className={styles.placeholderText}>
          {placeholder}
        </Text>
      </button>
      )}
    </div>
  );
};
export { SearchInput };
