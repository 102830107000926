import React, { memo } from 'react';
import cx from 'classnames';
import {
  Text,
} from '@components';
import { ProposalType } from '@types';
import styles from './styles.module.scss';

type EmptyTabProps = {
  isNeedVerify?: boolean;
  isProposalsScreen?: boolean;
  isSearch?: boolean;
  tabKey?: ProposalType;
};

const EmptyTab = memo<EmptyTabProps>(({
  isNeedVerify,
  isProposalsScreen,
  isSearch,
  tabKey,
}) => (
  <div className={cx(styles.emptyTab, { [styles.proposalsScreenEmptyTab]: isProposalsScreen })}>
    {isNeedVerify && (
    <>
      <Text type="span" className={styles.emptyTabText}>
        No available proposals.
      </Text>
      <Text type="span" className={styles.emptyTabText}>
        You have to verify your account with KYC
      </Text>
    </>
    )}
    {!isNeedVerify && (
      <>
        {/* // <Link href={RouteName.proposalCreation}> */}
        <Text type="span" className={styles.emptyTabText}>
          {isSearch
            ? 'There is no such proposal'
            : 'You don\'t have any proposals yet.'}
        </Text>
        { !isProposalsScreen && (
        <Text type="span" className={styles.emptyTabText}>
          {'Go to the '}
          {/* <Link href="/proposals" className={cx(styles.emptyTabText, styles.proposalsLink)}> */}
          Proposals
          {/* </Link> */}
          {` page to ${tabKey !== undefined && tabKey === ProposalType.Created ? 'create' : 'find'} a new Proposal`}
        </Text>
        )}
      </>

    )}
  </div>
));

export { EmptyTab };
