import { ProfileState } from '@store/profile/types';
import { ProfileActionTypes } from '@store/profile/actionTypes';

export const profileSetState = (payload: Partial<ProfileState>) => ({
  type: ProfileActionTypes.SET_STATE,
  payload,
});

export const profileGetCurrent = () => ({
  type: ProfileActionTypes.GET_PROFILE,
});

export const profileGetKycLink = () => ({
  type: ProfileActionTypes.GET_KYC_LINK,
});

export const profileGetBalance = () => ({
  type: ProfileActionTypes.GET_BALANCE,
});

export const getRateBnB = () => ({
  type: ProfileActionTypes.GET_RATE,
});
