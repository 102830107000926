import { mediaQueries } from '@constants';
import styled from '@emotion/styled';

export const Main = styled('main')`
  min-height: calc(100vh - 150px);
`;
export const Container = styled('div')`
  margin: 0 auto;
  padding: 0 16px;
  ${mediaQueries.lg} {
    max-width: 1070px;
    padding: 0;
  }
  ${mediaQueries.xl} {
    max-width: 1090px;
  }
`;
