import { styled } from 'styled-components';
import * as T from './types';

export const Loader = styled('div')<Pick<T.ILoader, 'size' | 'color'> & { isCenterLoader?:boolean }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  margin-right: ${({ isCenterLoader }) => (isCenterLoader ? 'auto' : '0 !important')};

  & > circle {
  }
`;
