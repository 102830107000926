import React, { RefObject, useEffect, useRef } from 'react';
import { Text } from '@components';
import { List } from '../List';
import { listKeys } from '../../constants';

import styles from './styles.module.scss';

type SectionKeyProps = {
  refSection: RefObject<HTMLDivElement>;
};

const SectionKey = ({
  refSection,
}: SectionKeyProps) => {
  const section = useRef(null);

  useEffect(() => {
    if (!section.current) {
      return;
    }

    // @ts-ignore
    const lines = section.current.querySelectorAll('.line'); // Класс каждой строки
    const handleScroll = () => {
      // @ts-ignore
      const rect = section.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const sectionTop = rect.top + scrollTop;
      // @ts-ignore
      if (window.scrollY + (section.current.offsetHeight / 2) > sectionTop) {
      // @ts-ignore
        lines.forEach((line, index) => {
          setTimeout(() => {
            line.classList.add(styles.animateIn);
          }, index * 100);
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={styles.containerKey} ref={section}>
      <div ref={refSection} className={styles.keyAnchor} />
      <div className={styles.content}>
        <div className={styles.containerListKeys}>
          <Text type="h2" className={`${styles.title} ${styles.line} line`}>
            Key
            <br />
            FEATURES
          </Text>
          {/* <div className={styles.containerImageForMobile} /> */}
          <List items={listKeys} classNameEl={styles.line} />
        </div>

        {/* <div className={styles.containerImage} /> */}
      </div>
    </section>
  );
};
export { SectionKey };
