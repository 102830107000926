import React, { useCallback } from 'react';
import { Text, Button } from '@components';
import { history } from '@utils';
import styles from './styles.module.scss';

type Props = {
  title?: string;
  subTitle?: string;
  onBackClick?: () => void;
};

export const TitleWithBack: React.FC<Props> = ({ title, subTitle, onBackClick }) => {
  const onClick = useCallback(() => {
    if (onBackClick !== undefined) {
      onBackClick();
    } else (history.goBack());
  }, [onBackClick]);
  return (
    <div className={styles.out}>
      <Button className={styles.back} onClick={onClick} />
      <div className={styles.containerTitle}>
        {title && (
        <Text type="h2" className={styles.mainTitle}>
          {title}
        </Text>
        )}
        {subTitle && (
        <Text className={styles.subTitle}>{subTitle}</Text>
        )}
      </div>
    </div>
  );
};
