/* eslint-disable no-bitwise */

function unpack(str: string) {
  const bytes = [];
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    bytes.push(char >>> 8);
    bytes.push(char & 0xFF);
  }
  return bytes;
}

function bytesToHex(bytes: number[]) {
  const hex = [];
  for (let i = 0; i < bytes.length; i += 1) {
    const current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
    hex.push((current >>> 4).toString(16));
    hex.push((current & 0xF).toString(16));
  }
  return hex.join('');
}

export function convertStringToByteHex(str: string) {
  return bytesToHex(unpack(str));
}
