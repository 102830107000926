import React from 'react';
import {
  Text,
} from '@components';
import styles from './styles.module.scss';

type PropsVotesResult = {
  percentYes: number;
};

const VotesResult = ({ percentYes }: PropsVotesResult) => (
  <div className={styles.containerVotesResult}>
    {percentYes !== 0 && (
      <div className={styles.containerVotesYes} style={{ width: `${percentYes}%` }}>
        <Text type="p" className={styles.textVote}>
          {`Yes ${percentYes}%`}
        </Text>
      </div>
    )}
    {percentYes !== 100 && (
      <div className={styles.containerVotesNo} style={{ width: `${100 - percentYes}%` }}>
        <Text type="p" className={styles.textVote}>
          {`No ${100 - percentYes}%`}
        </Text>
      </div>
    )}
  </div>
);

export { VotesResult };
