import { ReduxState } from '@store/types';
import { ProfileState } from './types';

export const profileSelectors = {
  getProp: <PropKey extends keyof ProfileState>(propKey: PropKey) => (
    state: ReduxState,
  ) => state.profile[propKey],
  isDocVerified: () => (
    state: ReduxState,
  ) => state.profile.mintStatus,
  isAddressVerified: () => (
    state: ReduxState,
  ) => state.profile.mintStatus,
};
