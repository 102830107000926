import { AccessTokenResponse } from '@types';

export const getDecodeAccessToken = async (
  accessToken: string,
  expiresIn: number,
):Promise<AccessTokenResponse> => ({
  token: accessToken,
  expiresIn,
  // exp: decodeAccessJwt.exp,
  // expRefresh: decodeRefreshJwt.exp,
  // roles: decodeAccessJwt.roles,
});
// const decodeAccessJwt:DecodeToken = await jwtDecode(accessToken);
// const decodeRefreshJwt:DecodeToken = await jwtDecode(refreshToken);
