import { useState, useMemo, useCallback } from 'react';
import { ButtonTheme, RouteName } from '@types';
import { history } from '@utils';

export const useProposalsModal = (
  isAccountVerified: boolean,
  onVerifyClick: () => void,
) => {
  const [isVerifyModalOpened, setIsVerifyModalOpened] = useState(false);

  const handleCreateProposal = useCallback(() => {
    // if (!isAccountVerified) {
    //   setIsVerifyModalOpened(true);
    // } else {
    //   history.push(RouteName.proposalCreation);
    // }
    history.push(RouteName.proposalCreation);
  }, []);

  const closeModal = useCallback(() => {
    setIsVerifyModalOpened(false);
  }, []);

  const onVerifyAccountClick = useCallback(() => {
    setIsVerifyModalOpened(false);
    onVerifyClick();
  }, [onVerifyClick]);

  const onGoBackToCryptoVotingsClick = useCallback(() => {
    setIsVerifyModalOpened(false);
  }, []);

  const handleShowModal = useCallback(() => {
    setIsVerifyModalOpened(true);
  }, []);

  const verifyAccountButtons = useMemo(
    () => [
      {
        children: 'mint NFT passport',
        onClick: onVerifyAccountClick,
        key: 1,
      },
      {
        children: 'i’ll do it later',
        onClick: onGoBackToCryptoVotingsClick,
        theme: ButtonTheme.outline,
        key: 2,
      },
    ],
    [
      onGoBackToCryptoVotingsClick,
      onVerifyAccountClick,
    ],
  );

  return {
    isVerifyModalOpened,
    verifyAccountButtons,
    closeModal,
    handleCreateProposal,
    handleShowModal,
  };
};
