export const theme = {
  borderRadiusLarge: '20px',
  borderRadiusMedium: '12px',
  borderRadiusSmall: '8px',

  maxContentWidth: '644px', // change

  zIndex: {
    upperElement: 10,
    menu: 20,
    dropdown: 30,
    modal: 40,
    tooltip: 50,
    notification: 60,
  },

  colors: {
    red: '#FF979F',
    green: '#62D490',
    redBgGradient: 'linear-gradient(0deg, rgba(255, 151, 159, 0.16), rgba(255, 151, 159, 0.16))',
    // -----------------------------------------
    black: '#092636',
    white: '#ffffff',
    gray: '#748392',
    box7: 'rgba(115, 143, 172, 0.07)',
    box10: 'rgba(115, 143, 172, 0.1)',
    box13: 'rgba(115, 143, 172, 0.13)',
    box24: 'rgba(115, 143, 172, 0.24)',
    lightContainers: '#1E2934',
    darkContainers: '#121920',
    backgroundN: '#070B0F',
    orange: '#F7931A',
    orange15: 'rgba(247, 147, 26, 0.15)',
    green15: 'rgba(101, 198, 55, 0.15)',
    red15: 'rgba(255, 0, 0, 0.15)',
    red25: 'rgba(255, 0, 0, 0.25)',
    primary: '#235EE7',
    primaryHover: '#0540C9',
    primaryDisabled: '#18367B',
    primary15: 'rgba(35, 94, 231, 0.15)',
    gradient: 'linear-gradient(90deg, #FFB195 0%, #E15C99 29.17%, #AE76D4 60.42%, #0654EC 91.67%)',
  },
};
