import {
  getAddress,
  getContractVotingCore,
} from '@api/ethereum';
import BigNumber from 'bignumber.js';

export const getCreatingContractAddress = async (
  salt: string,
  duration: string,
  qtyVoters: number,
  minQtyVoters: number,
):Promise<string> => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.createProposal(
    0,
    salt,
    duration, qtyVoters, minQtyVoters, salt,
  ).call({ from: address });
};

export const createVoting = async (
  duration:number,
  totalVoters:number,
  minConsensusVoters:number,
  description:string,
  value: string,
) => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.createProposal(
    [
      1,
      duration,
      totalVoters,
      minConsensusVoters,
      description,
      [],
    ],
  ).send({ from: address, value });
};

export const getPriceCreateProposal = async (): Promise<string> => {
  const contract = await getContractVotingCore();
  const fee = await contract.methods.createProposalFee().call();

  return fee;
};

export const getPriceBuyVotingToken = async (): Promise<string> => {
  const contract = await getContractVotingCore();
  const result = await contract.methods.buyVotingTokenRate().call();
  // it is not an error, we should get a price from a rate, it is smart contract logic
  return new BigNumber(10).pow(18)
    .multipliedBy(new BigNumber(10).pow(18))
    .div(result)
    .toFixed();
};

export const getMakeVoteFee = async ():Promise<string> => {
  const contract = await getContractVotingCore();
  const result = await contract.methods.makeVoteFee().call();
  // it is not an error, we should get a price from a rate, it is smart contract logic
  return result.toString();
};

export const makeVote = async (
  proposalId:number,
  choice:number,
  value: string,
) => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.makeVote(
    proposalId,
    choice,
  ).send({ from: address, value });
};

export const createBridgeVoting = async (
  destinationDomain:number,
  proposalInfo:{
    proposalType: number
    duration: number
    qtyVoters: number
    minQtyVoters:number
    description:string
    whitelist: string[]
  },
  nonce:number,
  deadline:string,
  signature:string,
  value:string,
) => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.bridgeCreateProposal(
    destinationDomain,
    [
      proposalInfo.proposalType,
      proposalInfo.duration,
      proposalInfo.qtyVoters,
      proposalInfo.minQtyVoters,
      proposalInfo.description,
      proposalInfo.whitelist,
    ],
    nonce,
    deadline,
    signature,
  ).send({ from: address, value });
};
