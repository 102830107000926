/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import ReactDom from 'react-dom';

import { usePopper } from 'react-popper';
import { useDeviceType, useOutsideClick } from '@hooks';
import { SearchInputDropdown } from '../SearchInputDropdown';
import { theme } from '../theme';
import * as S from './units';
import * as T from './types';

export const DropdownSelect: FC<T.IDropdownSelect> = (props) => {
  const [isGridStyle, setGridStyle] = useState(false);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [dropdownWidth, setDropdownWidth] = useState(250);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const { width } = useDeviceType();

  useOutsideClick(dropdownRef, isSelectOpen, setSelectOpen, dropdownContentRef);

  const [buttonElement, setButtonElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(buttonElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const appendTo = document.querySelector('#dropdown-root') as HTMLDivElement;

  useEffect(() => {
    if (isSelectOpen && !isMounted) {
      setIsMounted(true);
    }

    if (!isSelectOpen) {
      setIsMounted(false);
    }
  }, [isSelectOpen]);

  useEffect(() => {
    if (dropdownRef.current) {
      const newDropdownViewport = dropdownRef.current.getBoundingClientRect().width;

      setDropdownWidth(newDropdownViewport);
    }
  }, [dropdownRef.current, width, props?.selectedItem?.title || props?.title]);

  const filtredItems = useMemo(
    () => props.items.filter((item) => item?.title?.toLowerCase()?.includes(searchValue)),
    [props.items, searchValue],
  );

  const clickHandler = (id: number | string) => {
    props.setSelectedItem(id);

    setSelectOpen(false);
  };

  return (
    <S.SelectWrapper {...props} ref={dropdownRef} tabIndex={-1}>
      {props?.customButton ? (
        <S.CustomButtonWrapper
          isOpen={isSelectOpen}
          ref={setButtonElement}
          onClick={() => !props.isLoading && !props.isDisabled && setSelectOpen(!isSelectOpen)}
        >
          {props?.customButton}
        </S.CustomButtonWrapper>
      ) : (
        <S.ValueWrapper
          ref={setButtonElement}
          onClick={() => !props.isLoading && !props.isDisabled && setSelectOpen(!isSelectOpen)}
          isDisabled={props.isDisabled}
          isOpen={isSelectOpen}
          isSmall={props.isSmall}
        >
          {props.selectedItem?.icon && <S.Icon src={props.selectedItem.icon} size={24} />}

          <S.Value
            isActive={!!props.selectedItem?.title && !props.isDisabled}
            isSmall={props.isSmall}
          >
            {props.selectedItem?.title || props.title || 'Select'}
          </S.Value>

          {!props.isDisabled
            && (props.isLoading ? (
              <S.LoaderStyled size={18} />
            ) : (
              <S.ArrowIcon isOpen={isSelectOpen} isSmall={props.isSmall}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.6004 7.4585L11.1671 12.8918C10.5254 13.5335 9.47539 13.5335 8.83372 12.8918L3.40039 7.4585" stroke="#F8F7F7" strokeWidth="1.28125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </S.ArrowIcon>
            ))}
        </S.ValueWrapper>
      )}

      {isMounted
        && ReactDom.createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: theme.zIndex.dropdown }}
            {...attributes.popper}
          >
            <S.DropdownWrapper
              ref={dropdownContentRef}
              isSelectOpen={isSelectOpen}
              width={dropdownWidth}
              isNoBorder={props.isNoBorder}
            >
              {!props.isNoControls && (
                <S.ControlsContainer>
                  <SearchInputDropdown searchValue={searchValue} setSearchValue={setSearchValue} />

                  {props.isWithGrid && (
                    <S.TabsStyled
                      leftIcon={(
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.5 6.125C7.5 5.71079 7.83579 5.375 8.25 5.375H20.25C20.6642 5.375 21 5.71079 21 6.125C21 6.53921 20.6642 6.875 20.25 6.875H8.25C7.83579 6.875 7.5 6.53921 7.5 6.125Z" fill="#101010" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.50098 12.125C7.50098 11.7108 7.83676 11.375 8.25098 11.375H20.2504C20.6646 11.375 21.0004 11.7108 21.0004 12.125C21.0004 12.5392 20.6646 12.875 20.2504 12.875H8.25098C7.83676 12.875 7.50098 12.5392 7.50098 12.125Z" fill="#101010" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.50098 18.125C7.50098 17.7108 7.83676 17.375 8.25098 17.375H20.2504C20.6646 17.375 21.0004 17.7108 21.0004 18.125C21.0004 18.5392 20.6646 18.875 20.2504 18.875H8.25098C7.83676 18.875 7.50098 18.5392 7.50098 18.125Z" fill="#101010" />
                          <path d="M4.125 7.25C4.74632 7.25 5.25 6.74632 5.25 6.125C5.25 5.50368 4.74632 5 4.125 5C3.50368 5 3 5.50368 3 6.125C3 6.74632 3.50368 7.25 4.125 7.25Z" fill="#101010" />
                          <path d="M4.125 13.25C4.74632 13.25 5.25 12.7463 5.25 12.125C5.25 11.5037 4.74632 11 4.125 11C3.50368 11 3 11.5037 3 12.125C3 12.7463 3.50368 13.25 4.125 13.25Z" fill="#101010" />
                          <path d="M4.125 19.25C4.74632 19.25 5.25 18.7463 5.25 18.125C5.25 17.5037 4.74632 17 4.125 17C3.50368 17 3 17.5037 3 18.125C3 18.7463 3.50368 19.25 4.125 19.25Z" fill="#101010" />
                        </svg>
)}
                      rightIcon={(
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M13.1841 3.49021C13.6915 3.03941 14.3873 2.9165 15.1083 2.9165H18.475C19.196 2.9165 19.8918 3.03941 20.3992 3.49021C20.9292 3.96112 21.0833 4.63248 21.0833 5.3165V9.09984C21.0833 9.78331 20.9294 10.4558 20.3981 10.9261C19.8897 11.376 19.1933 11.4958 18.4727 11.4915H15.1083C14.39 11.4915 13.6935 11.3717 13.1852 10.9219C12.6534 10.4512 12.5 9.77814 12.5 9.0915V5.3165C12.5 4.63248 12.6541 3.96112 13.1841 3.49021ZM14.1804 4.61154C14.1125 4.67189 14 4.82553 14 5.3165V9.0915C14 9.5882 14.1132 9.74012 14.1793 9.79862C14.269 9.878 14.5017 9.9915 15.1083 9.9915H18.4797C19.0833 9.9953 19.3151 9.88147 19.404 9.80278C19.4706 9.74386 19.5833 9.59137 19.5833 9.09984V5.3165C19.5833 4.82553 19.4708 4.67189 19.4029 4.61154C19.3124 4.5311 19.079 4.4165 18.475 4.4165H15.1083C14.5044 4.4165 14.271 4.5311 14.1804 4.61154Z" fill="#101010" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M13.152 13.152C13.6599 12.644 14.3707 12.5 15.1083 12.5H18.475C19.2126 12.5 19.9234 12.644 20.4314 13.152C20.9393 13.6599 21.0833 14.3707 21.0833 15.1083V18.475C21.0833 19.2126 20.9393 19.9234 20.4314 20.4314C19.9234 20.9393 19.2126 21.0833 18.475 21.0833H15.1083C14.3707 21.0833 13.6599 20.9393 13.152 20.4314C12.644 19.9234 12.5 19.2126 12.5 18.475V15.1083C12.5 14.3707 12.644 13.6599 13.152 13.152ZM14.2126 14.2126C14.1226 14.3026 14 14.521 14 15.1083V18.475C14 19.0624 14.1226 19.2807 14.2126 19.3707C14.3026 19.4607 14.521 19.5833 15.1083 19.5833H18.475C19.0624 19.5833 19.2807 19.4607 19.3707 19.3707C19.4607 19.2807 19.5833 19.0624 19.5833 18.475V15.1083C19.5833 14.521 19.4607 14.3026 19.3707 14.2126C19.2807 14.1226 19.0624 14 18.475 14H15.1083C14.521 14 14.3026 14.1226 14.2126 14.2126Z" fill="#101010" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M3.60016 3.49021C4.10753 3.03941 4.8033 2.9165 5.52435 2.9165H8.89102C9.61207 2.9165 10.3078 3.03941 10.8152 3.49021C11.3452 3.96112 11.4993 4.63248 11.4993 5.3165V9.09984C11.4993 9.78331 11.3454 10.4558 10.8141 10.9261C10.3057 11.376 9.60933 11.4958 8.88872 11.4915H5.52435C4.80597 11.4915 4.10948 11.3717 3.60125 10.9219C3.06944 10.4512 2.91602 9.77814 2.91602 9.0915V5.3165C2.91602 4.63248 3.07016 3.96112 3.60016 3.49021ZM4.59646 4.61154C4.52854 4.67189 4.41602 4.82553 4.41602 5.3165V9.0915C4.41602 9.5882 4.52926 9.74012 4.59536 9.79862C4.68506 9.878 4.91773 9.9915 5.52435 9.9915H8.89573C9.49927 9.9953 9.7311 9.88147 9.82 9.80278C9.88658 9.74386 9.99935 9.59137 9.99935 9.09984V5.3165C9.99935 4.82553 9.88683 4.67189 9.81891 4.61154C9.72837 4.5311 9.49497 4.4165 8.89102 4.4165H5.52435C4.9204 4.4165 4.687 4.5311 4.59646 4.61154Z" fill="#101010" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M3.56798 13.152C4.07592 12.644 4.78673 12.5 5.52435 12.5H8.89102C9.62863 12.5 10.3394 12.644 10.8474 13.152C11.3553 13.6599 11.4993 14.3707 11.4993 15.1083V18.475C11.4993 19.2126 11.3553 19.9234 10.8474 20.4314C10.3394 20.9393 9.62863 21.0833 8.89102 21.0833H5.52435C4.78673 21.0833 4.07592 20.9393 3.56798 20.4314C3.06004 19.9234 2.91602 19.2126 2.91602 18.475V15.1083C2.91602 14.3707 3.06004 13.6599 3.56798 13.152ZM4.62864 14.2126C4.53866 14.3026 4.41602 14.521 4.41602 15.1083V18.475C4.41602 19.0624 4.53866 19.2807 4.62864 19.3707C4.71861 19.4607 4.93697 19.5833 5.52435 19.5833H8.89102C9.4784 19.5833 9.69675 19.4607 9.78673 19.3707C9.8767 19.2807 9.99935 19.0624 9.99935 18.475V15.1083C9.99935 14.521 9.8767 14.3026 9.78673 14.2126C9.69675 14.1226 9.4784 14 8.89102 14H5.52435C4.93697 14 4.71861 14.1226 4.62864 14.2126Z" fill="#101010" />
                        </svg>
)}
                      isRightPosition={isGridStyle}
                      togglePosition={setGridStyle}
                    />
                  )}
                </S.ControlsContainer>
              )}
              <S.ScrollBlock>
                <S.Connect {...{ isGridStyle }}>
                  {filtredItems.map((item: any, index: number) => {
                    const isActive = item.id === props.selectedItem?.id;

                    return (
                      <S.Item
                        key={index}
                        onClick={() => clickHandler(item.id)}
                        isActive={isActive}
                        isGridStyle={isGridStyle}
                        isNoBorder={!!props.isNoBorder}
                      >
                        {item.icon && (
                          <S.Icon src={item.icon} size={props.iconSize} isGridStyle={isGridStyle} />
                        )}

                        {!isGridStyle && item.title}
                      </S.Item>
                    );
                  })}
                </S.Connect>
              </S.ScrollBlock>
            </S.DropdownWrapper>
          </div>,
          appendTo,
        )}
    </S.SelectWrapper>
  );
};
