import React, { FC, ReactElement, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { authSelectors } from '@store/auth/selectors';
import { useSelector } from 'react-redux';
import { RouteName } from '@types';
import { MenuItem, Wrapper } from './Nav.styled';
import { NavProps } from './Nav.types';
import { navConfig } from './Nav.constants';

export const Nav: FC<NavProps> = ({ open }): ReactElement => {
  const history = useHistory();
  const isAdmin = useSelector(authSelectors.isAdmin());

  const config = useMemo(() => (isAdmin ? [...navConfig, {
    label: 'Admin',
    url: RouteName.admin,
  }] : navConfig), [isAdmin]);

  return (
    <Wrapper open={open}>
      {config.map((item) => (
        <MenuItem
          isActive={history?.location?.pathname === item.url
            || (item.url === RouteName?.proposals
            && history?.location?.pathname.includes(item.url))
            || (item.url === RouteName?.profile
              && history?.location?.pathname === RouteName?.proposalStore)}
          key={item.label}
        >
          <Link to={item.url}>{item.label}</Link>
        </MenuItem>
      ))}
    </Wrapper>
  );
};
