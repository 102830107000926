import React from 'react';
import { FieldProps, Field } from 'formik';
import { Input, TextArea } from '@components';
import cx from 'classnames';
import styles from './styles.module.scss';

type IProps = {
  name: string,
  label?: string,
  className?: string,
  inputType?: 'number' | 'text',
  max?: number,
  isTextArea?: boolean,
  isPositive?: boolean,
  isInteger?: boolean,
  isNotZero?: boolean,
  isWithoutClear?: boolean,
  classNameInner?: string,
};

export const FormikInput: React.FC<IProps> = ({
  isTextArea,
  name,
  label,
  className,
  inputType = 'text',
  classNameInner,
  isPositive,
  isInteger,
  isWithoutClear,
  max,
  isNotZero,
}) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (isTextArea ? (
      <TextArea
        label={label}
        onChange={(value) => form.setFieldValue(name, value)}
        value={field.value}
        className={cx(styles.input, className)}
        classNameInner={classNameInner}
      />
    ) : (
      <Input
        type={inputType}
        label={label}
        onChange={field.onChange}
        value={field.value}
        setValue={form.setFieldValue}
        name={name}
        className={cx(styles.input, className)}
        field={field}
        isPositive={isPositive}
        isInteger={isInteger}
        classNameInner={classNameInner}
        max={max}
        isWithoutClear={isWithoutClear}
        isNotZero={isNotZero}
      />
    ))}
  </Field>
);
