import { ProposalItem, ProposalItemCreateNew, ProposalItemGetNew } from '@types';

export const formatProposalItemResponse = (proposal: ProposalItemCreateNew): ProposalItem => {
  const {
    hash,
    startedAt,
    address,
    status,
    saved: {
      name,
      description,
      duration,
      proposalType,
      imageHash,
      imageMIMEType,
      mpv,
      qtyVoters,
      organizationEmployeesHash,
      keywords,
      creator,
      creatorName,
      expiresAt,
      land: {
        city,
        country,
        administrativeArea,
        region,
      },
    },

  } = proposal;

  return {
    hash,
    address,
    status,
    name,
    description,
    imageHash,
    imageMimeType: imageMIMEType,
    proposalType,
    land: {
      city,
      country,
      administrativeArea,
      region,
    },
    orgEmployeesHash: organizationEmployeesHash,
    keywords,
    creator,
    creatorName,
    duration: +duration,
    startedAt: +startedAt,
    qtyVoters: +qtyVoters,
    minQtyVoters: +mpv,
    expiresAt,
  };
};

export const formatGetProposalItemResponse = (proposal: ProposalItemGetNew): ProposalItem => {
  const {
    name,
    hash,
    description,
    // walletAddress,
    imageHash,
    imageMimeType,
    creator,
    proposalType,
    employeesHash,
    landCountry,
    landRegion,
    landCity,
    landAdministrativeArea,
    duration,
    qtyVoters,
    minQtyVoters,
    keywords,
    status,
    stats,
    startedAt,
    blockchainAddress,
    expiresAt,
  } = proposal;

  return {
    hash,
    address: blockchainAddress,
    status,
    name,
    description,
    imageHash,
    imageMimeType,
    proposalType,
    land: {
      city: landCity,
      country: landCountry,
      administrativeArea: landAdministrativeArea,
      region: landRegion,
    },
    orgEmployeesHash: employeesHash,
    keywords,
    creator,
    stats,
    // @TODO check backend files `creatorName`
    creatorName: '',
    duration: +duration,
    startedAt: new Date(startedAt).getTime(),
    qtyVoters: +qtyVoters,
    minQtyVoters: +minQtyVoters,
    expiresAt,
  };
};
