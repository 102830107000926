export * from '@store/auth/types';
export * from '@store/proposals/types';

export * from './api';
export * from './components';
export * from './global';
export * from './utils';
export * from './web3';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ethereum?: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Unwrap<T> = T extends (...args: any) => Promise<infer U> ? U : T;
