import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import { Icons } from '@types';
import { FieldProps } from 'formik';
import { ButtonIcon } from '../ButtonIcon';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type InputProps = {
  className?: string;
  type?: 'number' | 'text';
  label?: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  classNameInner?: string;
  field?: Partial<FieldProps['field']>;
  setValue?: (name: string, value: string) => void;
  name?: string;
  isPositive?: boolean;
  isInteger?: boolean;
  isWithoutClear?: boolean;
  isNotZero?: boolean;
  max?: number;
  error?:string
};

export const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
export const blockIntegerInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

const Input = ({
  className = '',
  label,
  type = 'text',
  placeholder = '',
  value = '',
  onChange,
  field,
  classNameInner,
  setValue,
  name = '',
  isPositive,
  isInteger,
  isWithoutClear,
  max,
  error,
  isNotZero,
}: InputProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'number' && isNotZero && e.target.value.length > 0 && +e.target.value === 0) {
        return;
      }
      if (type === 'number' && max && max < +e.target.value) {
        return;
      }
      if (field && field.onChange) {
        field.onChange(e);
      } else {
        onChange(e.target.value);
      }
    }, [field, isNotZero, max, onChange, type],
  );

  const handleClear = useCallback(() => {
    if (setValue) {
      setValue(name, '');
    } else {
      onChange('');
    }
  }, [onChange, setValue, name]);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isPositive && type === 'number') {
      blockInvalidChar(event);
    }
    if (isInteger && type === 'number') {
      blockIntegerInvalidChar(event);
    }
  }, [isInteger, isPositive, type]);

  const shouldRenderClearButton = useMemo(() => {
    if (isWithoutClear) return false;
    if (value.length === 0 && typeof value === 'string') return false;
    return true;
  }, [isWithoutClear, value]);

  return (
    <div className={cx(styles.containerInput, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <input
        value={value}
        onChange={handleChange}
        type={type}
        className={cx(styles.input, classNameInner)}
        placeholder={placeholder}
        name={name}
        onKeyDown={onKeyDown}
        min={isInteger ? '0' : ''}
        max={max}
        step={isInteger ? '1' : ''}
        autoComplete="off"
      />
      {shouldRenderClearButton && (
        <ButtonIcon
          icon={Icons.close}
          className={`${styles.buttonClose} ${styles.buttonCloseInput}`}
          onClick={handleClear}
        />
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
export { Input };
