import React from 'react';
import { ButtonIcon } from '@components';
import { Icons } from '@types';
import styles from './styles.module.scss';

type IProps = {
  text: string,
  onClose?: () => void,
};

export const Keyword:React.FC<IProps> = ({ text, onClose }) => (
  <div className={styles.out}>
    <div className={styles.text}>{`@${text}`}</div>
    <ButtonIcon onClick={onClose} icon={Icons.close} className={styles.btnClose} />
  </div>
);
