import { useEffect, useState } from 'react';

export function useLoader() {
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoader(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return isLoader;
}
