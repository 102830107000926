import { profileSelectors } from '@store/profile/selectors';
import { useEffect, useMemo } from 'react';
import { sha256 } from 'js-sha256';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { authSelectors } from '@store/auth/selectors';
import { useEagerConnect, useInactiveListener } from '@hooks';
import { StatusHeader } from '@types';
import { authGetAccessToken, getPassport } from '@store/auth/actionCreators';
import { chainIds } from '@constants';

const useAuth = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    active, account, activate, chainId,
  } = useWeb3React();
  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager);

  const accessToken = useSelector(authSelectors.getProp('token'));
  const role = useSelector(profileSelectors.getProp('role'));
  const addressHash = useSelector(authSelectors.getProp('addressHash'));
  // const roles = useSelector(authSelectors.getProp('roles'));

  const isConnected = useMemo(
    () => accessToken !== ''
      && active
      && addressHash === sha256(account || '')
      && chainId
      && chainIds.includes(chainId),
    [accessToken, active, addressHash, account, chainId],
  );

  useEffect(() => {
    if (triedEager && !isConnected) {
      dispatch(getPassport());
      dispatch(authGetAccessToken(true));

      // history.push('/');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [dispatch, history, isConnected, triedEager]);

  const statusHeader = useMemo(() => {
    if (isConnected) {
      return StatusHeader.connected;
    }
    if (active && chainId && chainIds.includes(chainId)) {
      return StatusHeader.waitConnect;
    }
    return StatusHeader.notConnect;
  }, [active, chainId, isConnected]);

  const isAdmin = role.name === 'admin';// roles[0] === 'admin';

  return {
    isLoading: !triedEager,
    isConnected,
    account,
    activate,
    active,
    statusHeader,
    isAdmin,
  };
};

export { useAuth };
