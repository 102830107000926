import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import { sha256 } from 'js-sha256';
import { chainIds, ACCESS_TOKEN_LS } from '@constants';
import { toastInfo, toastClose } from '@components';
import { AuthActionTypes } from '@store/auth/actionTypes';
import { AccessTokenLs, Unwrap } from '@types';
import {
  authSetState,
} from '@store/auth/actionCreators';
import {
  // profileGetBalance,
  profileGetCurrent,
} from '@store/profile/actionCreators';
import { confirm } from '@api/back';
import { getAddress, signPersonal, getChainId } from '@api/ethereum';
import { sagaErrorHandler, getLocalStorage, setLocalStorage } from '@utils';
import { addAuthInterceptor, removeAuthInterceptor } from '@api';
import { getAccountPassport } from '@api/ethereum/passport';
import { logoutSaga } from './logout';

function* getAccessTokenSaga() {
  try {
    removeAuthInterceptor();
    const chainId: Unwrap<typeof getChainId> = yield call(getChainId);

    if (!chainIds.includes(chainId)) {
      return;
    }

    const address: Unwrap<typeof getAddress> = yield call(getAddress);
    const currentTime = Date.now();
    const accessToken = getLocalStorage(ACCESS_TOKEN_LS) as AccessTokenLs;

    const { expiresIn: expiresInLs, addressHash } = accessToken || {};
    if (!accessToken || sha256(address) !== addressHash || currentTime >= +expiresInLs) {
      const message = process.env.REACT_APP_SIGN_MESSAGE || 'Please sign this message to connect to nft.';

      toastInfo('Please go to Metamask and sign up the message');

      const signature: Unwrap<typeof signPersonal> = yield call(
        signPersonal,
        message,
      );

      const res: Unwrap<typeof confirm> = yield call(
        confirm,
        signature,
        address,
      );

      toastClose();

      if (res) {
        const expiresIn = +res.expiresIn + currentTime;
        const accessTokenLs: AccessTokenLs = { ...res, expiresIn, addressHash: sha256(address) };
        setLocalStorage(ACCESS_TOKEN_LS, accessTokenLs);
      }
    }

    if (accessToken && sha256(address) === addressHash) {
      if (sha256(address) !== addressHash) {
        yield call(logoutSaga);
        return;
      }
    }

    const freshAccessToken = getLocalStorage(ACCESS_TOKEN_LS) as AccessTokenLs;

    const { expiresIn, token } = freshAccessToken;
    const passport: Unwrap<typeof getAccountPassport> = yield call(getAccountPassport);
    const hasPassport = passport !== '0' && passport !== 0;
    addAuthInterceptor(token);

    yield put(
      authSetState({
        addressHash: sha256(address),
        expiresIn,
        token,
        hasPassport,
      }),
    );

    yield put(profileGetCurrent());
    // yield put(profileGetBalance());
  } catch (error) {
    toastClose();
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* authGetAccessTokenSaga() {
  yield takeLeading(AuthActionTypes.GET_ACCESS_TOKEN, getAccessTokenSaga);
}
