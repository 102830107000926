/* eslint-disable react/jsx-props-no-spreading */
import React, {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import classNames from 'classnames/bind';
import RModal, { Props as RModalProps } from 'react-modal';
import { Colors, Icons } from '@types';
import { ButtonIcon } from '../ButtonIcon';
import { Button, ButtonProps } from '../Button';
import { Text } from '../Text';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ROOT = document.getElementById('root');

type ModalProps = {
  onClose: () => void;
  title?: string;
  titleName?: string;
  image?: string;
  imageName?: string;
  description?: string | string[];
  descriptionName?: string;
  buttons?: ButtonProps[];
  isMobileButtonsReverse?: boolean;
  buttonsName?: string;
} & RModalProps;

const Modal: FC<ModalProps> = ({
  onClose,
  title,
  titleName,
  image,
  imageName,
  description,
  descriptionName,
  buttons,
  isMobileButtonsReverse,
  buttonsName,
  isOpen,
  style,
  portalClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  className,
  appElement,
  onAfterOpen = () => {},
  onAfterClose = () => {},
  closeTimeoutMS,
  aria,
  data,
  role,
  contentLabel,
  contentRef,
  overlayRef,
  testId,
  id,
  children,
  overlayClassName,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  const renderDescription = useCallback(() => {
    if (description !== undefined && typeof description === 'string') {
      return (
        <Text
          className={cx(styles.description, styles.singleDescription, descriptionName)}
        >
          {description}
        </Text>
      );
    } if (description !== undefined && Array.isArray(description)) {
      return (
        <div className={styles.descriptionsWrapper}>
          {
          description.map(
            (line) => (
              <Text
                key={line}
                className={cx(styles.description, descriptionName)}
              >
                {line}
              </Text>
            ),
          )
        }
        </div>
      );
    }

    return null;
  }, [description, descriptionName]);

  const renderButtons = useCallback(() => {
    if (buttons !== undefined && buttons.length > 0) {
      return (
        <div
          className={
            cx(styles.buttonsContainer,
              { [styles.mobileButtonsReverseContainer]: isMobileButtonsReverse },
              buttonsName)
              }
        >
          {buttons.map(
            (button) => (
              <Button
                {...button}
                className={
                  cx(styles.button,
                    { [styles.mobileButtonReverse]: isMobileButtonsReverse },
                    button.className)
                  }
              >
                {button.children}
              </Button>
            ),
          )}
        </div>
      );
    } return null;
  }, [buttons, buttonsName, isMobileButtonsReverse]);

  const isHeightContentMorePage = useMemo(
    () => document.body.clientHeight - window.innerHeight > 10,
    // Before open modal calc height body (it is hak)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  return (
    <RModal
      isOpen={isOpen}
      style={style}
      portalClassName={portalClassName}
      bodyOpenClassName={cx(styles.body, bodyOpenClassName)}
      htmlOpenClassName={htmlOpenClassName}
      className={cx(styles.modal, className)}
      overlayClassName={cx(
        styles.overlay,
        { heightPage: isHeightContentMorePage },
        overlayClassName,
      )}
      appElement={appElement}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onClose}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      parentSelector={() => ROOT!}
      aria={aria}
      data={data}
      role={role}
      contentLabel={contentLabel}
      contentRef={contentRef}
      overlayRef={overlayRef}
      testId={testId}
      id={id}
    >
      {title !== undefined && (
        <Text className={cx(styles.title, titleName)}>{title}</Text>
      )}
      {image !== undefined && (
        <img className={cx(styles.image, imageName)} src={image} alt="modalImage" />
      )}
      <ButtonIcon
        onClick={onClose}
        icon={Icons.close}
        iconColor={Colors.gold}
        className={styles.closeBtn}
      />
      {renderDescription()}
      {children}
      {renderButtons()}
    </RModal>
  );
};

export { Modal };
