// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../../assets/fonts/Futura/stylesheet.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-6-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-6-3!../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-6-4!../../assets/fonts/HelveticaNeue/stylesheet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  font-family: helvetica neue;\n}\n\ninput[type=number] {\n  -moz-appearance: textfield;\n}\n\ninput[type=number]::-webkit-outer-spin-button,\ninput[type=number]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://src/containers/App/styles.scss","webpack://src/constants/styles.scss"],"names":[],"mappings":"AAIA;EACE,SAAA;EACA,UAAA;EACA,2BCPW;ADMb;;AAIA;EACE,0BAAA;AADF;;AAIA;;EAEE,wBAAA;EACA,SAAA;AADF","sourcesContent":["@import \"@constants/styles.scss\";\n@import \"@assets/fonts/Futura/stylesheet.css\";\n@import \"@assets/fonts/HelveticaNeue/stylesheet.css\";\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: $fontFamily;\n}\n\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n\ninput[type=\"number\"]::-webkit-outer-spin-button,\ninput[type=\"number\"]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n","$fontFamily: helvetica neue;\n$fontello: fontello;\n\n$futura: futura;\n$openSans: 'Open Sans', sans-serif;\n\n$fontIBM: ibm plex sans;\n\n$pageWidth: 1070px;\n\n$gray: #f2f2f2;\n\n$darkGrey: #828282;\n$darkGrey1: #797979;\n$lightGrey: #bdbdbd;\n$lightGrey1: #e0e0e0;\n$lightGrey2: #fafafa;\n$lightGrey3: #dcdcdc;\n\n$black: #333;\n$black1: #1d1d1d;\n$blackHalfTransparent: rgba(0, 0, 0, 0.5);\n\n$yellow: #e3bd67;\n\n$primaryBlue: #3e66fb;\n\n$blue: #2f80ed;\n\n$black: #1d1d1d;\n$white: #fff;\n\n$gold: #e3bd67;\n\n$red: #eb5757;\n\n$cardHeight: 120px;\n$cardWidth: 330px;\n\n$animationTimeout: 5s;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
