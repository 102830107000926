import React from 'react';
import cx from 'classnames';

import { Text } from '../Text';
import styles from './styles.module.scss';

type ProgressBarProps = {
  wrapperName?: string;
  labelName?: string;
  label?: string;
  progress: number;
};

const ProgressBar = ({
  wrapperName = '',
  labelName = '',
  label = '',
  progress,
}: ProgressBarProps) => (
  <div
    className={cx(
      styles.wrapper,
      wrapperName,
    )}
  >
    <div className={styles.progress} style={{ width: `${progress}%` }} />
    <Text type="span" className={cx(styles.label, labelName)}>
      {label}
    </Text>
  </div>
);
export { ProgressBar };
