export enum ProposalsActionTypes {
  SET_STATE = 'PROPOSALS.SET_STATE',

  GET_PROPOSALS_LIST = 'PROPOSALS.GET_PROPOSALS_LIST',
  GET_PROPOSALS_SEARCH = 'PROPOSALS.GET_PROPOSALS_SEARCH',

  GET_PROFILE_PROPOSALS = 'PROPOSALS.GET_PROFILE_PROPOSALS',

  GET_PROPOSAL_FEE = 'PROPOSALS.GET_PROPOSAL_FEE',
}
