import {
  call,
  takeLeading,
  put,
  all,
} from 'redux-saga/effects';
import { peelBigNumber, sagaErrorHandler } from '@utils';
import { Unwrap } from '@types';
import { AdminActionTypes } from '@store/admin/actionTypes';
import { adminSetState } from '@store/admin/actionCreators';
import {
  getCreationPrice,
  getGasLimit,
  getRpvTokenPrice,
  getVotingTokenPrice,
} from '@api/back/admin';

function* getSettingsSaga() {
  try {
    yield put(adminSetState({ isSettingsLoading: true }));

    const [
      creationPriceRaw,
      votingTokenPriceRaw,
      rpvTokenPriceRaw,
      gasLimitRaw,
    ]: [
      Unwrap<typeof getCreationPrice>,
      Unwrap<typeof getVotingTokenPrice>,
      Unwrap<typeof getRpvTokenPrice>,
      Unwrap<typeof getGasLimit>,
    ] = yield all([
      call(getCreationPrice),
      call(getVotingTokenPrice),
      call(getRpvTokenPrice),
      call(getGasLimit),
    ]);

    const creationPrice = peelBigNumber(creationPriceRaw);
    const votingTokenPrice = peelBigNumber(votingTokenPriceRaw);
    const rpvTokenPrice = peelBigNumber(rpvTokenPriceRaw);
    const gasLimit = peelBigNumber(gasLimitRaw);

    yield put(
      adminSetState({
        isSettingsLoading: false,
        creationPrice,
        votingTokenPrice,
        rpvTokenPrice,
        gasLimit,
      }),
    );
  } catch (error) {
    yield put(adminSetState({ isSettingsLoading: false }));
    sagaErrorHandler(error);
  }
}

export function* adminGetSettingsSaga() {
  yield takeLeading(AdminActionTypes.GET_STATISTICS, getSettingsSaga);
}
