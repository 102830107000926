import { useCallback, useMemo, useState } from 'react';

type Props = {
  cardsTotal: number;
  cardsPerPage: number;
  submitNewPageNumber?: (page: number) => void;
};

export const useCardsPagination = ({
  cardsTotal,
  cardsPerPage,
  submitNewPageNumber,
}: Props) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const firstCardIndex = useMemo(
    () => currentPageIndex * cardsPerPage,
    [cardsPerPage, currentPageIndex],
  );

  const lastCardIndex = useMemo(
    () => Math.min(firstCardIndex + cardsPerPage - 1, cardsTotal - 1),
    [cardsTotal, cardsPerPage, firstCardIndex],
  );

  const pagesText = useMemo(
    () => {
      if (firstCardIndex !== lastCardIndex) {
        return `Showing ${firstCardIndex + 1}-${lastCardIndex + 1} of ${cardsTotal} items`;
      } return `Showing ${firstCardIndex + 1}th item of ${cardsTotal} items`;
    },
    [cardsTotal, firstCardIndex, lastCardIndex],
  );

  const pageCount = useMemo(
    () => Math.ceil(cardsTotal / cardsPerPage),
    [cardsTotal, cardsPerPage],
  );

  const onPageChange = useCallback(({ selected }: { selected: number }) => {
    setCurrentPageIndex(selected);
    if (submitNewPageNumber !== undefined) {
      submitNewPageNumber(selected + 1);
    }
  }, [setCurrentPageIndex, submitNewPageNumber]);

  return {
    pagesText,
    pageCount,
    onPageChange,
  };
};
