import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Input, Text } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { adminGetSettings, adminGetStatistics, adminUpdateSettings } from '@store/admin/actionCreators';
import { adminSelectors } from '@store/admin/selectors';
import { UpdateSettingsPayload } from '@store/admin/types';
import { getWeb3 } from '@api/ethereum';
import { StatisticsItem } from './components/StatisticsItem';
import styles from './styles.module.scss';

const Admin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminGetStatistics());
    dispatch(adminGetSettings());
  }, [dispatch]);

  const {
    isSettingsUpdating,
    income,
    gasPayments,
    uniqueVoters,
    creationPrice: creationPriceInitial,
    votingTokenPrice: votingTokenPriceInitial,
    rpvTokenPrice: rpvTokenPriceInitial,
    gasLimit: gasLimitInitial,
    role: adminInitial,
  } = useSelector(adminSelectors.getState());

  const [creationPrice, setCreationPrice] = useState('');
  const [votingTokenPrice, setVotingTokenPrice] = useState('');
  const [rpvTokenPrice, setRpvTokenPrice] = useState('');
  const [gasLimit, setGasLimit] = useState('');
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');

  useEffect(() => {
    setCreationPrice(creationPriceInitial);
    setVotingTokenPrice(votingTokenPriceInitial);
    setRpvTokenPrice(rpvTokenPriceInitial);
    setGasLimit(gasLimitInitial);
    setRole(adminInitial);
  }, [
    creationPriceInitial,
    gasLimitInitial,
    rpvTokenPriceInitial,
    votingTokenPriceInitial,
    adminInitial,
  ]);

  const inputs = useMemo(() => [
    {
      label: 'Proposal creation price (RPV)',
      value: creationPrice,
      setValue: setCreationPrice,
    },
    {
      label: 'Voting token price (RPV)',
      value: votingTokenPrice,
      setValue: setVotingTokenPrice,
    },
    {
      label: 'RPV token price (BNB)',
      value: rpvTokenPrice,
      setValue: setRpvTokenPrice,
    },
    {
      label: 'Platform inner transactions fee (BNB)',
      value: gasLimit,
      setValue: setGasLimit,
    },
    {
      label: 'Platform inner transactions fee (BNB)',
      value: gasLimit,
      setValue: setGasLimit,
    },
  ], [creationPrice, gasLimit, rpvTokenPrice, votingTokenPrice]);

  const updateSettingsPayload: UpdateSettingsPayload = useMemo(() => ({
    creationPrice,
    votingTokenPrice,
    rpvTokenPrice,
    gasLimit,
    role,
    address,
  }), [creationPrice, role, gasLimit, rpvTokenPrice, votingTokenPrice, address]);

  const onAcceptClick = useCallback(() => {
    if (address) {
      const web = getWeb3();
      const isValidAddress = web.utils.isAddress(address);

      if (!isValidAddress) {
        setAddressError('Invalid address');
        return;
      }
    }
    if (address && !role) {
      setRoleError('Role can be only "admin" or "user"');
      return;
    }

    if (!address && role) {
      setAddressError('Invalid address');
      return;
    }

    if (role !== '' && !(role === 'admin' || role === 'user')) {
      setRoleError('Role can be only "admin" or "user"');
      return;
    }
    dispatch(adminUpdateSettings(updateSettingsPayload));
  }, [dispatch, updateSettingsPayload, role, address]);

  const onChangeAddressHandler = (val:string) => {
    setAddressError('');
    setAddress(val);
  };

  const onChangeRoleHandler = (val:string) => {
    setRoleError('');
    setRole(val);
  };

  return (
    <section className={styles.container}>
      <Text type="h2" className={styles.mainTitle}>ADMIN PANEL</Text>

      <div className={styles.partsWrapper}>
        <section className={styles.leftPart}>
          <Text type="h2" className={styles.title}>Statistics</Text>
          <StatisticsItem
            key="Unique Users"
            title="Unique Users"
            value={uniqueVoters}
          />
          <StatisticsItem
            title="Platforms income (ETH)"
            value={income}
          />
          <StatisticsItem
            title="Gas Payments"
            value={gasPayments}
          />
        </section>
        <section className={styles.rightPart}>
          <Text type="h2" className={styles.title}>Settings</Text>
          {inputs.map(({ label, value, setValue }) => (
            <Input
              key={label}
              type="number"
              value={value}
              onChange={setValue}
              label={label}
              isPositive
              className={styles.input}
            />
          ))}
          <>
            <Input
              type="text"
              value={address}
              onChange={onChangeAddressHandler}
              label="Set address"
              isPositive
              className={styles.input}
              error={addressError}
            />
            <Input
              type="text"
              value={role}
              onChange={onChangeRoleHandler}
              label="Set address role"
              isPositive
              className={styles.input}
              error={roleError}
            />
          </>
          <Button
            htmlType="submit"
            onClick={onAcceptClick}
            className={styles.acceptButton}
            isLoading={isSettingsUpdating}
          >
            Accept the changes
          </Button>
        </section>
      </div>
    </section>
  );
};

export { Admin };
