/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Text } from '../Text';
import styles from './styles.module.scss';

type FileWithPreview = File & {
  preview: string;
};

type UploadImageProps = {
  onUpload: (files: FileWithPreview[]) => void;
};

const UploadImage = ({ onUpload }: UploadImageProps) => {
  const [selectFiles, setFiles] = useState<FileWithPreview[]>([]);
  const {
    getRootProps, getInputProps, open,
  } = useDropzone({
    noClick: selectFiles.length === 0,
    noKeyboard: true,
    maxFiles: 1,
    accept: 'image/*',
    onDrop: (files) => {
      const filesWithPreview = files.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));
      setFiles(filesWithPreview);
      onUpload(filesWithPreview);
    },
  });

  const thumbs = selectFiles.map((file) => (
    <div className={styles.thumb} key={file.name}>
      <div className={styles.thumbInner}>
        <img
          src={file.preview}
          className={styles.img}
          alt="UploadImage"
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    selectFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [selectFiles]);

  return (
    <div className={styles.uploadImage}>
      <div {...getRootProps({ className: 'dropzone' })} className={styles.dropZone}>
        <input {...getInputProps()} autoComplete="off" />
        {!selectFiles.length && (
          <>
            <Text type="p" className={styles.title}>Upload Image</Text>
            <button type="button" className={styles.button} onClick={open}>
              Choose file or  Drag and Drop
            </button>
          </>
        )}
        {thumbs}
      </div>
    </div>
  );
};

export { UploadImage };
