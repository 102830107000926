import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { BigNumber } from 'bignumber.js';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  proposalIteGet,
  proposalItemVote,
} from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { profileSelectors } from '@store/profile/selectors';
import { ProposalType, ProposalStatus } from '@types';
import { proposalsSelectors } from '@store/proposals/selectors';
import { utils } from 'ethers';
import { useGetProposalsList } from '@hooks';
import { StatusVote } from '../types';

export function useVote(): {
  isShowVoteModal: boolean;
  status: StatusVote;
  showVote: () => void;
  closeVote: () => void;
  totalVotersNeed: number;
  countAllVoting: number;
  percentProgressVoting: number;
  percentYes: number;
  minCountVoters: string;
  name: string;
  subTitle: string;
  description: string;
  imageHash: string;
  creator: string;
  isVotingLegitimate: boolean;
  isVoting: boolean;
  keywords: string[];
  proposalType: ProposalType;
  handleVoteYes: () => void;
  handleVoteNot: () => void;
  expirationDateFormatted: string;
  expiresAtFormatted: string
  isExpired: boolean
  votedFor?: string
  votedTotal?: string
  votedAgainst?: string
  isFetching:boolean

} {
  const {
    commonProposalsList,
  } = useGetProposalsList({
    pageLimit: 10,
    proposalType: ProposalType.CryptoVoting,
    page: 1,
    isSearchInputEmpty: true,
  });
  const { id }: { id: string } = useParams();
  const p = commonProposalsList.find((item) => item.id === id);

  const [isShowVoteModal, setIsShowVoteModal] = useState(false);
  const [status, setStatus] = useState(StatusVote.default);
  const dispatch = useDispatch();
  const totalVotersNeed = useSelector(
    proposalItemSelectors.getProp('qtyVoters'),
  );
  const countAllVoting = useSelector(
    proposalItemSelectors.getProp('countAllVoting'),
  );
  const expiresAt = useSelector(proposalItemSelectors.getProp('expiresAt'));
  const percentYes = useSelector(proposalItemSelectors.getProp('percentYes'));
  const minQtyVoters = useSelector(
    proposalItemSelectors.getProp('minQtyVoters'),
  );
  const duration = useSelector(proposalItemSelectors.getProp('duration'));
  const startedAt = useSelector(proposalItemSelectors.getProp('startedAt'));
  const proposalType = useSelector(
    proposalItemSelectors.getProp('proposalType'),
  );
  const name = useSelector(proposalItemSelectors.getProp('name'));
  const description = p?.description; // useSelector(proposalItemSelectors.getProp('description'));
  const imageHash = useSelector(proposalItemSelectors.getProp('imageHash'));
  const keywords = useSelector(proposalItemSelectors.getProp('keywords'));
  const creator = useSelector(proposalItemSelectors.getProp('creator'));
  const isVoting = useSelector(proposalItemSelectors.getProp('isVoting'));
  // const balanceVoting = useSelector(
  //   proposalItemSelectors.getProp('balanceVoting'),
  // );
  // const balanceRPV = useSelector(profileSelectors.getProp('balanceRPV'));
  const balanceEth = useSelector(profileSelectors.getProp('balanceBNB'));
  const statusProposal = useSelector(proposalItemSelectors.getProp('status'));
  // @ts-ignore
  const stats = useSelector(proposalItemSelectors.getProp('stats'));
  const voteFee = useSelector(proposalsSelectors.getProp('voteFee'));
  const voteFeeInEth = utils.formatUnits(voteFee);
  const isFetching = useSelector(proposalItemSelectors.getProp('isFetching'));
  const {
    for: votedFor,
    total: votedTotal,
    against: votedAgainst,
  } = stats || {};

  useEffect(() => {
    dispatch(proposalIteGet(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isVoting) {
      setStatus(StatusVote.voted);
    }
    if (!isVoting && +voteFeeInEth > +balanceEth) {
      setStatus(StatusVote.notBalance);
    }
    if (statusProposal === ProposalStatus.Succeed) {
      setStatus(StatusVote.finished);
    }
    if (!isVoting && +voteFeeInEth <= +balanceEth) {
      setStatus(StatusVote.default);
    }
  }, [isVoting, voteFeeInEth, balanceEth, statusProposal]);

  const showVote = useCallback(() => {
    setIsShowVoteModal(true);
  }, []);

  const closeVote = useCallback(() => {
    setIsShowVoteModal(false);
  }, []);

  const handleVoteYes = useCallback(() => {
    dispatch(proposalItemVote({
      // @ts-ignore
      proposalId: p?.proposalId,
      choice: 1,
      value: voteFee,
    }));
    setIsShowVoteModal(false);
  }, [dispatch, id, voteFee]);

  const handleVoteNot = useCallback(() => {
    dispatch(proposalItemVote({
      // @ts-ignore
      proposalId: p?.proposalId,
      choice: 2,
      value: voteFee,
    }));
    setIsShowVoteModal(false);
  }, [dispatch, id, voteFee]);

  const subTitle = useMemo(() => {
    if (proposalType === ProposalType.LandBasedVoting) {
      return 'Geo Gated';
    }
    return 'Geo Gated';
  }, [proposalType]);

  const isVotingLegitimate = useMemo(
    () => totalVotersNeed * (minQtyVoters / 100) <= countAllVoting,
    [countAllVoting, minQtyVoters, totalVotersNeed],
  );

  const minCountVoters = useMemo(
    () => new BigNumber(totalVotersNeed)
      .times(new BigNumber(minQtyVoters))
      .div(100)
      .integerValue(BigNumber.ROUND_CEIL)
      .toString(),
    [minQtyVoters, totalVotersNeed],
  );

  const expirationDateFormatted = useMemo(() => {
    const expirationTimeStampJs = (duration + startedAt || 0);

    return format(expirationTimeStampJs, 'MM/dd/yyyy kk:mm');
  }, [duration, startedAt]);

  const expiresAtFormatted = useMemo(() => {
    if (!expiresAt) {
      return '';
    }
    return format(new Date(expiresAt).getTime(), 'MM/dd/yyyy kk:mm');
  }, [expiresAt]);

  let isExpired = false;
  if (expiresAt) {
    const deadlineDate = new Date(expiresAt);

    const currentDate = new Date();

    if (currentDate > deadlineDate) {
      isExpired = true;
    }
  }

  return {
    isShowVoteModal,
    showVote,
    closeVote,
    status,
    totalVotersNeed,
    countAllVoting,
    percentProgressVoting: (countAllVoting / totalVotersNeed) * 100,
    percentYes,
    minCountVoters,
    name,
    subTitle,
    isVotingLegitimate,
    description,
    imageHash,
    keywords,
    creator,
    handleVoteYes,
    handleVoteNot,
    proposalType,
    isVoting,
    expirationDateFormatted,
    expiresAtFormatted,
    isExpired,
    votedFor,
    votedTotal,
    votedAgainst,
    isFetching,
  };
}
