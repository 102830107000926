import React from 'react';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';

type PaginationProps = {
  paginationName?: string;
  pageCount: number;
  onPageChange: (selectedItem: { selected: number })=> void
};

const Pagination = ({ paginationName, pageCount, onPageChange }: PaginationProps) => (
  <ReactPaginate
    previousLabel={null}
    nextLabel={null}
    pageCount={pageCount}
    marginPagesDisplayed={2}
    pageRangeDisplayed={2}
    onPageChange={onPageChange}
    containerClassName={cx(styles.pagination, paginationName)}
    pageClassName={styles.paginationItem}
    pageLinkClassName={styles.paginationLink}
    activeLinkClassName={styles.paginationLinkActive}
  />
);

export { Pagination };
