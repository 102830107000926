import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { ImageIpfs } from '@components';

import { Proposal, ProposalType } from '@types';
import { formatTimeDifference } from '@utils';
import arbitrumIcon from '@assets/images/arbitrum.svg';
import { Text } from '../Text';
import styles from './styles.module.scss';

type P = Proposal;

type CardProps = {
  className?: string;
  proposal: Proposal;
  onOpenClick?: (hash: string) => void;
};

const Card = ({
  className = '',
  proposal,
  onOpenClick,
}: CardProps) => {
  const {
    id, imageHash, description, name,
    deadline, proposalType,
    // totalVoters, minConsensusVoters,
  } = proposal as P;
  const [timeRemain, setTimeRemain] = useState('');
  const onClick = useCallback(() => {
    if (onOpenClick !== undefined) {
      onOpenClick(id);
    }
  }, [id, onOpenClick]);

  useEffect(() => {
    const t = setInterval(() => {
      const timeLeft = new Date(deadline).getTime() - Date.now();

      setTimeRemain(formatTimeDifference(timeLeft));
    }, 1000);

    return () => clearInterval(t);
  }, [deadline]);

  return (
    <button
      type="button"
      className={cx(styles.card, className)}
      onClick={onClick}
    >
      <div className={styles.wrapperImg}>
        <ImageIpfs imageHash={imageHash} className={styles.image} />
      </div>
      <div className={styles.containerInfo}>
        <div style={{ width: '100%' }}>
          <div className={styles.titleLine}>

            <Text type="h3" className={styles.title}>
              {/* {getShortAddress(id)} */}
              {name}
            </Text>
            <div className={styles.type}>
              <div className={styles.chain}>
                <img src={arbitrumIcon} alt="" />
                Arbitrum
              </div>
              {proposalType === ProposalType.LandBasedVoting ? 'Land' : 'Crypto'}
            </div>
          </div>

          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />

          <div className={styles.tags}>
            {/* {keywords?.map((el) =>
            <div className={styles.tag} key={Math.random()}>{`@${el}`}</div>)} */}
          </div>

          <div className={styles.progressWrapper}>
            {/* {+total
              ? (

                <div className={styles.progress}>
                  <div className={styles.stat}>
                    Yes
                    {`(${((+forStat / +total) * 100).toFixed(0)}%)`}
                  </div>
                  <div className={styles.stat}>
                    No
                    {`(${((+against / +total) * 100).toFixed(0)}%)`}
                  </div>
                  <div className={styles.progressBar}
                  style={{ width: `${(+forStat / +total) * 100}%` }} />
                </div>

              ) : (
                <div className={styles.progress}>
                  <div className={styles.stat}>
                    Yes (0%)
                  </div>
                  <div className={styles.stat}>
                    No (0%)
                  </div>
                  <div className={styles.progressBar} style={{ width: '50%' }} />
                </div>
              )}
          </div> */}

            {/* <div className={styles.votesLine}>
            {+total
              ? (
                <>
                  <div className={styles.votes}>
                    {forStat || 0}
                    {' '}
                    votes
                  </div>
                  <div className={styles.votes}>
                    {against || 0}
                    {' '}
                    votes
                  </div>
                </>
              ) : null} */}
          </div>
        </div>
        <div className={styles.timeRemain}>{timeRemain}</div>
      </div>
    </button>
  );
};

export { Card };
