import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  FormikInput,
  FormikSelect,
  DatePicker,
  toastError,
  TitleWithBack,
  // DropdownSelect,
} from '@components';
import { ProposalType, RouteName } from '@types';
import { useDispatch, useSelector } from 'react-redux';
import { bridgeProposalItemCreate, proposalItemCreate, proposalItemGetPricesForVoting } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { profileSelectors } from '@store/profile/selectors';
import { profileGetBalance } from '@store/profile/actionCreators';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { chains } from '@constants';
import { getChainConfig, history } from '@utils';
import {
  useCancelModal,
  useSuccessModal,
  useFinishModal,
  useLandAreaProposal,
} from './hooks';
import {
  initialValues,
  initialValuesWithImage,
  proposalSelectItems,
  validationSchema,
} from './constants';
import {
  ImageUpload,
  CustomFieldArray,
  // FileUpload,
} from './components';
import styles from './styles.module.scss';

// function parseCSV(csvText:unknown) {
//   // @ts-ignore
//   const lines = csvText.split('\n');
//   const headers = lines[0].split(',');
//   const data = [];

//   for (let i = 1; i < lines.length; i++) {
//     const line = lines[i].split(',');
//     const entry = {};

//     for (let j = 0; j < headers.length; j++) {
//       // @ts-ignore
//       entry[headers[j]] = line[j];
//     }

//     data.push(entry);
//   }

//   return data;
// }

const chainConfig = getChainConfig();

const ProposalCreation: React.FC = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValuesWithImage);
  const {
    chainId,
  } = useWeb3React();
  const [chain] = useState(chainConfig.find((c) => c.id === chainId));
  const isAdminWallet = useSelector(profileSelectors.getProp('isAdminWallet'));

  const { ModalSuccess } = useSuccessModal();

  const { openCancelModal, ModalCancel } = useCancelModal();

  const { areasOption, formatLand } = useLandAreaProposal();

  const priceForCreateWei = useSelector(
    proposalItemSelectors.getProp('priceForCreateWei'),
  );

  const balanceBnbWei = useSelector(
    profileSelectors.getProp('getBalanceBNBWei'),
  );

  const isCreatingProposal = useSelector(
    proposalItemSelectors.getProp('isCreatingProposal'),
  );

  useEffect(() => {
    dispatch(profileGetBalance());
    dispatch(proposalItemGetPricesForVoting());
  }, [dispatch]);

  const handleSendTransaction = useCallback(() => {
    if (new BigNumber(balanceBnbWei).lt(priceForCreateWei)) {
      toastError("You don't have enough ETH, please top up your wallet", { autoClose: 3000 });
    } else if (chain?.id !== chainId) {
      dispatch(bridgeProposalItemCreate({
        ...values,
        network: chain?.alias,
        proposalType: values.proposalType?.value
          ? ProposalType[values.proposalType?.value as ProposalType]
          : ProposalType.CryptoVoting,
        land: formatLand(values.land),
      }));
    } else {
      dispatch(proposalItemCreate({
        ...values,
        proposalType: values.proposalType?.value
          ? ProposalType[values.proposalType?.value as ProposalType]
          : ProposalType.CryptoVoting,
        land: formatLand(values.land),
      }));
    }
  }, [balanceBnbWei, dispatch, formatLand, priceForCreateWei, values]);

  const { openFinishModal, ModalFinish } = useFinishModal(handleSendTransaction);

  // const onUpload = useCallback((data) => {
  //   const reader = new FileReader();
  //   reader.onload = (theFile) => {
  //     const base64Text = String(theFile?.target?.result).split(',')[1];
  //     const decodedText = atob(base64Text);
  //     const organizationEmployees = parseCSV(decodedText).map((el) => JSON.stringify(el));
  //     // @ts-ignore
  //     setValues((value) => ({
  //       ...value,
  //       organizationEmployees,
  //     }));
  //   };
  //   reader.readAsDataURL(data[0]);
  // }, []);

  const onUploadImage = useCallback((data) => {
    const reader = new FileReader();
    reader.onload = (theFile) => {
      const result = String(theFile?.target?.result).split(',');
      setValues((value) => ({
        ...value,
        imageMimeType: result[0],
        image: result[1],
      }));
    };
    reader.readAsDataURL(data);
  }, []);

  // const setChainHandler = (id: number | string) => {
  //   const newChain = chainConfig.find((item) => item.id === id);

  //   if (!newChain) {
  //     return;
  //   }

  //   setChain(newChain);
  // };

  useEffect(() => {
    if (!isAdminWallet) {
      history.push(RouteName.home);
    }
  }, [isAdminWallet]);

  return (
    <div className={styles.container}>
      <TitleWithBack onBackClick={openCancelModal} />
      <ModalCancel />
      <ModalFinish />
      <ModalSuccess />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        isInitialValid
        onSubmit={(newValues) => {
          setValues((oldValues) => ({
            ...oldValues,
            ...newValues,
          }));
        }}
      >
        {(props) => {
          const proposalType = props.values.proposalType?.value;
          const isLandbased = proposalType === ProposalType.LandBasedVoting;
          // const isOrganizational = proposalType === ProposalType.OrganizationalVoting;
          const checkValues = () => {
            let result = props.isValid && values.image.length > 0;
            if (isLandbased) {
              result = result && props.values.land.value.length > 0;
            }
            // if (isOrganizational) {
            //   result = result && values.organizationEmployees.length > 0;
            // }
            return result;
          };

          const isValidAll = checkValues();

          return (
            <Form
              onSubmit={props.handleSubmit}
              className={styles.contentWrapper}
            >
              <div className={styles.selects}>
                <div style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6,
                }}
                >
                  {/* {chain && chains.find((c) => c.chainId === chainId) ? (
                    <div className={styles.chain}>
                      <img src={chains.find((c) => c.chainId === chainId)?.icon} alt="icon" />
                      <span>{chains.find((c) => c.chainId === chainId)?.chainName}</span>
                    </div>
                  ) : null} */}
                  {/* <DropdownSelect
                    items={chainConfig}
                    selectedItem={chain}
                    isWithGrid
                    isNotFullWidth
                    setSelectedItem={setChainHandler}
                    customButton={( */}
                  <div className={styles.chain}>
                    <img src={chains.find((c) => c.chainId === chain?.id)?.icon} alt="icon" />
                    <span>{chains.find((c) => c.chainId === chain?.id)?.chainName}</span>
                  </div>

                  {/* // )}
                //     {...{ setChainHandler }}
                //   /> */}
                  <FormikSelect
                    isSelectType
                    label="Type of the Proposal"
                    name="proposalType"
                    items={proposalSelectItems}
                    className={styles.formikInputStyle}
                  />
                  {isLandbased && (
                  <FormikSelect
                    isSelectType
                    placeholder="Select area"
                    label="Area for the Proposal"
                    name="land"
                    items={areasOption}
                    className={styles.formikInputStyle}
                  />
                  )}
                </div>
              </div>
              <ImageUpload
                onChange={onUploadImage}
                className={styles.imageUploader}
              />
              <div className={styles.leftPart}>
                <div className={styles.inputOut}>
                  <div className={styles.inputOutLeft}>
                    <div className={styles.nameLine}>

                      <FormikInput
                        label="Proposal Name"
                        name="name"
                        inputType="text"
                        className={`${styles.formikInputStyle} ${styles.inputName}`}
                      />
                      <div className={styles.selectsDesk}>
                        <div style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 6,
                        }}
                        >
                          {/* <DropdownSelect
                            items={chainConfig}
                            selectedItem={chain}
                            isWithGrid
                            isNotFullWidth
                            setSelectedItem={setChainHandler}
                            customButton={( */}
                          <div className={styles.chain}>
                            <img src={chains.find((c) => c.chainId === chain?.id)?.icon} alt="icon" />
                            <span>
                              {chains.find((c) => c.chainId === chain?.id)?.chainName}
                            </span>
                          </div>

                          {/* )}
                            {...{ setChainHandler }}
                          /> */}
                          <FormikSelect
                            isSelectType
                            label="Type of the Proposal"
                            name="proposalType"
                            items={proposalSelectItems}
                            className={styles.formikInputStyle}
                          />
                        </div>
                        {isLandbased && (
                        <FormikSelect
                          isSelectType
                          placeholder="Select area"
                          label="Area for the Proposal"
                          name="land"
                          items={areasOption}
                          className={styles.formikInputStyle}
                        />
                        )}
                      </div>
                    </div>
                    <div className={styles.containerDateTime}>
                      <DatePicker
                        label="Expiration Date"
                        placeholder="Date"
                        minDate={new Date()}
                        value={props.values.date}
                        onChange={(date) => props.setFieldValue('date', date)}
                        className={styles.datePickerStyle}
                      />
                      {/* <TimeSelect
                        value={props.values.time}
                        onChange={(time) => props.setFieldValue('time', time)}
                        className={styles.timeSelect}
                      /> */}
                    </div>
                    {/* <FormikInput
                      label="The expected amount of voteres"
                      name="qtyVoters"
                      inputType="number"
                      className={styles.formikInputStyle}
                      isPositive
                      isInteger
                    /> */}

                    {/* {isOrganizational && (
                      <FileUpload
                        className={styles.fileUpload}
                        onUpload={onUpload}
                      />
                    )} */}
                    {/* <FormikInput
                      label="The expected amount of voteres, needed to accept the proposal"
                      name="minQtyVoters"
                      inputType="number"
                      className={styles.formikInputStyle}
                      isPositive
                      isInteger
                      isNotZero
                      max={+props.values.qtyVoters || 0}
                    /> */}
                    <CustomFieldArray
                      name="keywords"
                      setField={props.setFieldValue}
                      keywords={props.values.keywords}
                      currentKeyword={props.values.currentKeyword}
                    />
                    <FormikInput
                      inputType="text"
                      name="description"
                      label="Describe your proposal"
                      isTextArea
                      className={styles.describe}
                    />
                  </div>
                </div>

                <div className={styles.btnGrpOut}>
                  <div className={styles.btnGrp}>
                    {/* <Button
                    theme={ButtonTheme.outline}
                    className={styles.btn}
                    onClick={openCancelModal}
                    >
                    Cancel
                  </Button> */}
                    <Button
                      className={styles.btn}
                      htmlType="submit"
                      disabled={!props.dirty || !isValidAll || isCreatingProposal}
                      isLoading={isCreatingProposal}
                      onClick={openFinishModal}
                    >
                      create proposal
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { ProposalCreation };
