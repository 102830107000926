import { colors, mediaQueries } from '@constants';
import styled from '@emotion/styled';
import { Content } from '../../Header.styled';
import { NavProps } from './Nav.types';

export const Wrapper = styled(Content)<NavProps>`
  width: 100%;
  left: 0;
  /* margin-top: 100px; */
  padding: ${({ open }) => (open ? '139px 20px 0 20px' : '0 20px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 26px;
  /* height: ${({ open }) => (open ? '100vh' : 0)}; */
  height: 100vh;
  top: ${({ open }) => (open ? '0' : '-100%')};
  overflow: hidden;
  transition: 0.3s;
  position: fixed;
  background: #0B0B0B;
  ${mediaQueries.lg} {
    width: auto;
    padding: 0;
    position: relative;
    height: auto;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    gap: 0;
    background: none;
  }
`;

export const MenuItem = styled('div')<{ isActive: boolean }>`
  & a {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    ${mediaQueries.lg} {
      font-size: 13px;
      color: ${({ isActive }) => (isActive ? colors.white : colors.gray)};
      font-weight:  ${({ isActive }) => (isActive ? 500 : 400)};;
    }
  }
  ${mediaQueries.lg} {
    margin-right: 18px;
    padding-right: 18px;
    border-right: 1px solid ${colors.gray};
    color: ${({ isActive }) => (isActive ? colors.white : colors.gray)};
    &:last-child {
      padding-right: 0;
      padding-left: 0;
      border-right: 0;
    }
  }
  ${mediaQueries.xl} {
    border-right: 1px solid ${colors.gray};
    color: ${({ isActive }) => (isActive ? colors.white : colors.gray)};
    &:last-child {
      padding-right: 0;
      padding-left: 0;
      border-right: 0;
    }
  }
`;
