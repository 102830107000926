import {
  call, takeLeading, put,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';

import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { bridgeProposalItemCreate, proposalItemSetState } from '@store/proposalItem/actionCreators';

import { toastClose } from '@components';
import { ProposalType, Unwrap } from '@types';
import { createProposal } from '@api/back';
import { getSignatureBridgeCreateProposal } from '@api/back/proposals';
import { createBridgeVoting, getPriceCreateProposal } from '@api/ethereum/votingFactory';
import { chains } from '@constants';

function* createProposalSaga({ payload }: ReturnType<typeof bridgeProposalItemCreate>) {
  try {
    yield put(proposalItemSetState({ isCreatingProposal: true }));
    const {
      time,
      date,
      description,
      name,
      image = '',
      keywords,
      imageMimeType = '',
      network = '',
    } = payload;
    date!.setHours(+time!.split(':')[0]);
    date!.setMinutes(+time!.split(':')[1]);
    date!.setSeconds(0);

    const qtyVoters = 1000000000;
    const minQtyVoters = 500000000;

    const now = +((Date.now() / 1000).toFixed(0));
    const dateSec = +(+date!.getTime() / 1000);
    let duration = +((dateSec - now).toFixed(0));
    duration = Math.round(duration / 60) * 60;

    const proposal: Unwrap<typeof createProposal> = yield call(
      {
        context: null,
        fn: createProposal,
      }, {
        name,
        address: '0x1111111111111111111111111111111111111111',
        image,
        imageMimeType,
        proposalType: ProposalType.CryptoVoting,
        description,
        keywords,
        duration,
        expiresAt: new Date(Date.now() + duration * 1000).toISOString(),
        qtyVoters,
        minPercentageVoters: 50,
      },
    );

    const res:Unwrap<typeof getSignatureBridgeCreateProposal> = yield call(
      {
        context: null,
        fn: getSignatureBridgeCreateProposal,
      },
      {
        proposalType: 1,
        description,
        duration,
        totalVoters: +qtyVoters,
        network,
        minConsensusVoters: +minQtyVoters,
        whitelist: [],
      },
    );

    const priceForCreateInWei: Unwrap<typeof getPriceCreateProposal> = yield call(
      getPriceCreateProposal,
    );

    yield call(
      {
        context: null,
        fn: createBridgeVoting,
      },
      chains.find((c) => c.alias === network)?.chainId as number,
      {
        proposalType: 1,
        duration: +duration,
        qtyVoters,
        minQtyVoters,
        description: JSON.stringify({ id: proposal.id, desc: description }),
        whitelist: [],
      },
      res.nonce,
      res.deadline,
      res.signature,
      priceForCreateInWei,
    );

    toastClose();

    yield put(
      proposalItemSetState({
        isSuccessCreate: true,
        isCreatingProposal: false,
      }),
    );
  } catch (error) {
    yield put(proposalItemSetState({ isCreatingProposal: false }));
    sagaErrorHandler(error as string);
  }
}

export function* bridgeProposalItemCreateSaga() {
  yield takeLeading(
    ProposalItemActionTypes.BRIDGE_CREATE_PROPOSAL,
    createProposalSaga,
  );
}
