// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_icon__2BsBb {\n  font-size: 20px;\n}\n.styles_icon__2BsBb::before {\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://src/components/LinkIcon/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,SAAA;AACJ","sourcesContent":[".icon {\n  font-size: 20px;\n\n  &::before {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "styles_icon__2BsBb"
};
export default ___CSS_LOADER_EXPORT___;
