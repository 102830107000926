import React from 'react';
import { Button, FormikInput, Text } from '@components';
import { ButtonTheme, CurrencyName } from '@types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

type Props = {
  onSubmit: (values: { count: string }) => void;
  rate: number;
  balanceBNB: string;
};

const validationSchema = yup.object().shape({
  count: yup.number().required().positive().integer(),
});

export const RpvStoreForm: React.FC<Props> = ({
  onSubmit,
  rate,
  balanceBNB,
}) => (
  <div className={styles.containerForm}>
    <Text className={styles.inputLabel}>How many tokens do you want to buy?</Text>
    <Formik
      initialValues={{ count: '0' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => {
        const cost = new BigNumber(rate).multipliedBy(new BigNumber(props.getFieldProps('count').value)).toFixed();
        const costDescription = `It will cost ${Number.isNaN(+cost) ? 0 : cost} ETH`;

        const disabled = +cost > Number(balanceBNB) || +cost === 0 || Number.isNaN(+cost);
        console.log('balanceBNB:', balanceBNB);
        console.log('cost:', cost);
        console.log('rate:', rate);

        return (
          <Form onSubmit={props.handleSubmit} className={styles.form}>
            <div className={styles.inputOut}>
              <FormikInput
                name="count"
                inputType="number"
                isPositive
                isInteger
                className={styles.input}
                classNameInner={styles.inputInner}
              />
              <Text type="span" className={styles.rpvText}>{CurrencyName.RPV}</Text>
            </div>
            <div className={styles.costLabelOut}>
              <Text type="span" className={styles.costLabel}>{costDescription}</Text>
            </div>

            <Button
              htmlType="submit"
              theme={ButtonTheme.primary}
              className={styles.btn}
              disabled={disabled}
            >
              {+cost > Number(balanceBNB) ? 'insufficient balance' : 'buy RPV tokens'}

            </Button>
          </Form>
        );
      }}
    </Formik>
  </div>
);
