import { ethers } from 'ethers';
import { getAddress, getContractVotingCore } from '@api/ethereum';

export const buyVoting = async (amount: number) => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.buy(amount).send({ from: address });
};

export const voteYes = async () => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.voteFor().send({ from: address });
};

export const voteNot = async () => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.voteAgainst().send({ from: address });
};

export const getStatsVoting = async (): Promise<{
  _count: string,
  _for: string,
}> => {
  const contract = await getContractVotingCore();

  return contract.methods.getStats().call();
};

export const getParamsVoting = async () => {
  const contract = await getContractVotingCore();

  return contract.methods.params().call();
};

export const getBalanceVoting = async (): Promise<string> => {
  const contract = await getContractVotingCore();
  const address = await getAddress();

  return contract.methods.balanceOf(address).call();
};

export const getAllVoters = async (): Promise<string[]> => {
  const contract = await getContractVotingCore();

  return contract.methods.getAllVoters().call();
};

export const approveVoting = async () => {
  const contract = await getContractVotingCore();
  const address = await getAddress();
  return contract.methods
    .approve('', ethers.constants.MaxUint256)
    .send({ from: address });
};
