import { colors, mediaQueries } from '@constants';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { styled as styledComp } from 'styled-components';
import { getIconSize, toEnd } from '../../styles';
import { Container } from '../layout';

export const Wrapper = styled('header')<{ isScrolled: boolean, isOpen: boolean }>`
  position: fixed;
  top: 0;
  padding: 50px 0 24px;
  width: 100%;
  background: ${({ isScrolled, isOpen }) => (isScrolled || isOpen ? '#0B0B0B' : 'none')};
  z-index: 5;
  transition: 0.3s;
  ${mediaQueries.lg} {
    padding: 37px 0; 
  }
  & a{
    text-decoration: none;
  }
`;

export const Content = styled(Container)`
  margin: 0 auto;
  max-width: 1090px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoDesk = styled(Link)`
  display: none;
  ${mediaQueries.lg} {
    display: flex;
    & img {
      width: 212px;
      height: 47px;
    }
  }
  ${mediaQueries.xl} {
    & svg {
      width: 212px;
      height: 47px;
    }
  }
`;

export const LogoMob = styled(Link)`
  display: flex;
  z-index: 2;
  position: relative;
  & svg {
      width: 40px;
      height: 40px;
    }
  ${mediaQueries.lg} {
    display: none;
  }
`;

export const Burger = styled('div')<{ open: boolean }>`
  width: 40px;
  height: 40px;
  border: 1.5px solid ${colors.gold};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  flex: none;
  ${mediaQueries.lg} {
    display: none;
  }
  & span {
    position: relative;
    width: ${({ open }) => (open ? '25px' : '15px')};
    height: 1.5px;
    background: ${colors.gold};
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'none')};
    transition: 0.3s;
    &::after,
    &::before {
      position: absolute;
      background: inherit;
      display: block;
      content: '';
      height: 100%;
      width: 100%;
      transition: 0.3s;
    }
    &::after {
      top: -7px;
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &::before {
      bottom: -7px;
      transform: ${({ open }) => (open ? 'rotate(90deg) translate(-7px, 0px)' : 'none')};
    }
  }
`;
export const ConnectButtonWrapper = styled('div')`
  display: none;
  ${mediaQueries.lg} {
    display: block;
    width: 195px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const AddressMob = styled('div')`
  ${mediaQueries.lg}{
    display: none;
  }
`;

export const NetworkButton = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 41px;
  border: 2px solid #e9bb58;
  padding: 6px 12px;
  box-shadow: -8px -7px 35px 0px #000000;

  box-shadow: 0px 4px 4px 0px #00000080;

  box-shadow: 0px 4px 4px 0px #000000;

  box-shadow: 0px 4px 4px 0px #00000080;

  border-radius: 12px;
  ${mediaQueries.md} {
    cursor: pointer;
  }
`;

export const ChainIcon = styledComp('img')<{ size?: number; isGridStyle?: boolean }>`
  ${({ size = 28 }) => getIconSize(size)}

  border-radius: 50%;

  ${toEnd('mobile')} {
    ${({ isGridStyle, size }) => getIconSize(isGridStyle ? 28 : size || 32)}
  }
`;
