export const setLocalStorage = (key: string, value: unknown) => {
  const jsonValue = JSON.stringify(value);
  return localStorage.setItem(key, jsonValue);
};

export const getLocalStorage = (key: string):unknown => {
  const value = localStorage.getItem(key);

  if (!value) {
    return null;
  }
  return JSON.parse(value);
};

export const removeLocalStorage = (key: string):unknown => localStorage.removeItem(key);
