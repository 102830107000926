import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';
import { sagaErrorHandler, convertToBigNumber } from '@utils';
import { AdminActionTypes } from '@store/admin/actionTypes';
import {
  adminSetState,
  adminUpdateSettings,
} from '@store/admin/actionCreators';
import {
  setAdminRole,
  setCreationPrice,
  setGasLimit,
  setRpvTokenPrice,
  setVotingTokenPrice,
} from '@api/back/admin';
import { toastSuccess } from '@components';
import { adminSelectors } from '../selectors';

function* updateSettingsSaga({
  payload,
}: ReturnType<typeof adminUpdateSettings>) {
  try {
    yield put(adminSetState({ isSettingsUpdating: true }));

    const creationPriceCurrent: string = yield select(
      adminSelectors.getProp('creationPrice'),
    );
    const votingTokenPriceCurrent: string = yield select(
      adminSelectors.getProp('votingTokenPrice'),
    );
    const rpvTokenPriceCurrent: string = yield select(
      adminSelectors.getProp('rpvTokenPrice'),
    );
    const gasLimitCurrent: string = yield select(
      adminSelectors.getProp('gasLimit'),
    );
    const roleCurrent: string = yield select(adminSelectors.getProp('role'));
    const addressCurrent: string = yield select(
      adminSelectors.getProp('address'),
    );

    const {
      creationPrice,
      votingTokenPrice,
      rpvTokenPrice,
      gasLimit,
      role,
      address,
    } = payload;

    const updates = [];
    if (creationPrice !== creationPriceCurrent) {
      updates.push(call(setCreationPrice, convertToBigNumber(creationPrice)));
    }
    if (votingTokenPrice !== votingTokenPriceCurrent) {
      updates.push(
        call(setVotingTokenPrice, convertToBigNumber(votingTokenPrice)),
      );
    }
    if (rpvTokenPrice !== rpvTokenPriceCurrent) {
      updates.push(call(setRpvTokenPrice, convertToBigNumber(rpvTokenPrice)));
    }
    if (gasLimit !== gasLimitCurrent) {
      updates.push(call(setGasLimit, convertToBigNumber(gasLimit)));
    }
    if (role !== roleCurrent && address !== addressCurrent) {
      updates.push(call(setAdminRole, { address, role }));
    }

    yield all(updates);

    yield put(
      adminSetState({
        isSettingsUpdating: false,
        creationPrice,
        votingTokenPrice,
        rpvTokenPrice,
        gasLimit,
      }),
    );

    toastSuccess('Changes are saved');
  } catch (error) {
    yield put(adminSetState({ isSettingsUpdating: false }));
    sagaErrorHandler(error);
  }
}

export function* adminUpdateSaga() {
  yield takeLatest(AdminActionTypes.UPDATE_SETTINGS, updateSettingsSaga);
}
