import React, { useMemo, useCallback } from 'react';
import { Modal } from '@components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CheckImage } from '@assets/images';
import { ButtonTheme, RouteName } from '@types';
import { proposalItemSetState } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';

export const useSuccessModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isSuccessModalOpened = useSelector(
    proposalItemSelectors.getProp('isSuccessCreate'),
  );

  const closeModalSuccess = useCallback(() => {
    dispatch(proposalItemSetState({
      isSuccessCreate: false,
    }));
    history.push(RouteName.proposals);
  }, [dispatch, history]);

  const closeModalAndGoProposal = useCallback(() => {
    dispatch(proposalItemSetState({
      isSuccessCreate: false,
    }));
    history.push(RouteName.proposals);
  }, [dispatch, history]);

  const closeModalAndGoProfile = useCallback(() => {
    dispatch(proposalItemSetState({
      isSuccessCreate: false,
    }));
    history.push(RouteName.profile);
  }, [dispatch, history]);

  const successModalButtons = useMemo(() => [
    {
      children: 'Go to the proposal page',
      onClick: closeModalAndGoProposal,
      theme: ButtonTheme.outline,
      key: 1,
    }, {
      children: 'Go to the profile page',
      onClick: closeModalAndGoProfile,
      theme: ButtonTheme.outline,
      key: 2,
    },
  ], [closeModalAndGoProfile, closeModalAndGoProposal]);

  const ModalSuccess = useCallback(() => (
    <Modal
      title="Proposal creation finished!"
      description="You’ve successfully created a new proposal!"
      isOpen={isSuccessModalOpened}
      onClose={closeModalSuccess}
      buttons={successModalButtons}
      image={CheckImage}
    />
  ), [closeModalSuccess, isSuccessModalOpened, successModalButtons]);

  return {
    ModalSuccess,
  };
};
