import React, { memo, useCallback, useRef } from 'react';
import cx from 'classnames';
import DatePickerRaw from 'react-datepicker';
import { Icons, Colors } from '@types';
import { Text } from '../Text';
import { ButtonIcon } from '../ButtonIcon';
import styles from './styles.module.scss';

type DatePickerProps = {
  className?: string;
  label?: string;
  value?: Date;
  placeholder?: string;
  onChange: (date: Date) => void;
  minDate?: Date;
};

const DatePicker = memo(({
  className = '',
  label,
  value,
  onChange,
  placeholder = 'Date',
  minDate,
}: DatePickerProps) => {
  const refDatePicker = useRef<DatePickerRaw>(null);

  const handleClose = useCallback(() => {
    if (refDatePicker.current) {
      refDatePicker.current.setOpen(false);
    }
  }, []);

  const handleDateChangeRaw = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
  }, []);

  return (
    <div className={cx(styles.datePicker, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <DatePickerRaw
        wrapperClassName={styles.wrapper}
        placeholderText={placeholder}
        minDate={minDate}
        customInput={(
          <input className={styles.input} placeholder={placeholder} autoComplete="off" />
        )}
        onChangeRaw={handleDateChangeRaw}
        ref={refDatePicker}
        renderCustomHeader={({
          date,
          decreaseYear,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          prevYearButtonDisabled,
        }) => (
          <div className={styles.containerHeader}>
            <div className={styles.containerYear}>
              <ButtonIcon
                icon={Icons.left}
                onClick={!prevYearButtonDisabled ? decreaseYear : () => {}}
                className={styles.buttonPrevYear}
                classNameIcon={styles.iconPreYear}
                iconColor={prevYearButtonDisabled ? Colors.gray5 : Colors.darkGrey}
              />
              <Text className={styles.titleDate}>{date.toLocaleString('en', { year: 'numeric' })}</Text>
              <ButtonIcon
                icon={Icons.right}
                onClick={increaseYear}
                className={styles.buttonNextYear}
                classNameIcon={styles.iconPreYear}
                iconColor={Colors.darkGrey}
              />
              <ButtonIcon
                icon={Icons.close}
                onClick={handleClose}
                className={styles.buttonClose}
                classNameIcon={styles.iconClose}
              />
            </div>
            <div className={styles.containerMonth}>
              <Text className={styles.titleDate}>
                {date.toLocaleString('en', { year: 'numeric', month: 'long' })}
              </Text>

              <ButtonIcon
                icon={Icons.left}
                onClick={decreaseMonth}
                className={styles.buttonLeft}
                classNameIcon={styles.iconNav}
                iconColor={Colors.semiLight}
              />
              <ButtonIcon
                icon={Icons.right}
                onClick={increaseMonth}
                className={styles.buttonRight}
                classNameIcon={styles.iconNav}
                iconColor={Colors.gold}
              />
            </div>
          </div>
        )}
        selected={value}
        onChange={onChange}
      />
    </div>
  );
});

export { DatePicker };
