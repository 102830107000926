import { ProposalItemState } from '@store/proposalItem/types';
import { HandlerFn } from '@types';
import { proposalItemSetState } from '@store/proposalItem/actionCreators';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProfileHandlerFn<F extends (...args: any) => any> = HandlerFn<
ProposalItemState,
ReturnType<F>
>;

const setState: ProfileHandlerFn<typeof proposalItemSetState> = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const PROPOSAL_ITEM_HANDLERS = {
  [ProposalItemActionTypes.SET_STATE]: setState,
};
