export enum ProposalType {
  CryptoVoting = 'CryptoVoting',
  LandBasedVoting = 'LandBasedVoting',
  OrganizationalVoting = 'OrganizationalVoting',

  Created = 'Created',
}

export enum ProposalStatus {
  Active = 'Active',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export type ProposalRaw = {
  hash: string,
  startedAt: string;
  address: string,
  createdAt: string,
  approvedAt: string,
  status: ProposalStatus,
  // saved: {
  name: string,
  description: string,
  imageHash: string,
  imageMimeType: string,
  creator: string,
  proposalType: ProposalType,
  landCountry: string
  landRegion:string
  landCity: string
  landAdministrativeArea: string
  orgEmployeesHash: string,
  keywords: string[],
  duration: string,
  qtyVoters: string,
  minQtyVoters: string,
  creatorName: string
  creatorWalletAddress: string
  stats:{ for:string, against:string, total: string }

  'id': 'string',
  'emitterAddress': 'string',
  'sender': 'string',
  'destinationDomain': 0,
  'nonce': 0,
  'deadline': 0,
  'signature': 'string',
  'proposalId': 0,
  'totalVoters': 0,
  'minConsensusVoters': 0,
  'whitelist': 'string'

  // }
};

export type ProposalListResponse = {
  list: ProposalRaw[],
  total: number
};
