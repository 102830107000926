import { fork } from 'redux-saga/effects';
import { adminGetStatisticsSaga } from '@store/admin/sagas/getStatistics';
import { adminGetSettingsSaga } from '@store/admin/sagas/getSettings';
import { adminUpdateSaga } from '@store/admin/sagas/updateSettings';

export const adminEffects = [
  fork(adminGetStatisticsSaga),
  fork(adminGetSettingsSaga),
  fork(adminUpdateSaga),
];
