/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';

import * as T from './types';
import * as S from './units';

const TabsDropdown: FC<T.ITabs> = (props) => (
  <S.TabsWrapper
    onClick={() => !!props.togglePosition && props.togglePosition(!props.isRightPosition)}
    {...props}
  >
    <S.Item isActive={!props.isRightPosition}>
      {!!props.leftIcon && (
        <S.IconWrapper isActive={!props.isRightPosition}>{props.leftIcon}</S.IconWrapper>
      )}

      {props.leftText}
    </S.Item>

    <S.Item isActive={!!props.isRightPosition}>
      {!!props.rightIcon && (
        <S.IconWrapper isActive={!!props.isRightPosition}>{props.rightIcon}</S.IconWrapper>
      )}

      {props.rightText}
    </S.Item>

    <S.TabCarriage isRightPosition={props.isRightPosition} />
  </S.TabsWrapper>
);

export { TabsDropdown };
