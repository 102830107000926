import { styled } from 'styled-components';
import { getIconSize, toEnd } from '../../styles';

export const InputSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  min-width: 144px;
  height: 40px;
  padding: 10px 12px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  background: #19191a;
  cursor: text;

  ${toEnd('mobile')} {
    height: 48px;
  }
`;

export const InputSearch = styled.input`
  width: 100%;
  height: 100%;
  padding: 12px 0;

  font-size: 14px;

  background-color: transparent;

  text-overflow: ellipsis;
  color: white;
  transition: background-color 0.2s ease-in-out;
  background: none;
  border: none;
  outline: none;
  &::placeholder {
    color: #a3a2a2;
  }
`;

export const SearchIcon = styled('div')<{ isFocused: boolean }>`
  ${getIconSize()}

  path {
    transition: all 0.2s ease-in-out;

    stroke: ${({ isFocused }) => (isFocused ? '#fff' : '#A3A2A2')};
  }
`;
