import React from 'react';
import ReactSelect, {
  OptionTypeBase,
  Styles as StylesProp,
  Props as SelectProps,
  GroupTypeBase,
} from 'react-select';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const customStylesDefault = {
  container: (provided: any) => ({
    ...provided,
    marginBottom: '32px',
    width: '100%',
  }),
  control: (provided:any, state:any) => ({
    ...provided,
    height: '44px',
    background: 'linear-gradient(0deg, rgba(242, 242, 242, 0.4), rgba(242, 242, 242, 0.4))',
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '20px',
    color: '$black',
    outline: 'none',
    border: state.isFocused ? '0.5px solid #bdbdbd' : '0.5px solid #bdbdbd',
    boxShadow: 'none',
    '&::placeholder': {
      color: '#BDBDBD',
    },
    '&:hover': {
      border: '0.5px solid #bdbdbd',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#BDBDBD',
  }),
  option: (provided:any, state: any) => ({
    ...provided,
    border: 'none',
    boxShadow: null,
    outline: 0,
    background: state.isFocused ? '#bdbdbd' : '#fff',
    color: '#1d1d1d',
    '&:hover': {
      background: state.isFocused ? '#bdbdbd' : '#fff',
    },
  }),
};

const componentsDefaults = {
  IndicatorSeparator: () => null,
};

type Item = {
  value: string;
  label: string;
};

type IProps = SelectProps<OptionTypeBase> & {
  name? : string,
  placeholder?: string,
  label?: string,
  classNameContainer?: string,
  classNameLabel? : string,
  items: Item[] | [],
  customStyles?:
  Partial<StylesProp<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>>
  | undefined,
};

export const Select:React.FC<IProps> = ({
  onChange,
  placeholder,
  name,
  items,
  components = componentsDefaults,
  classNameLabel,
  classNameContainer,
  label,
  customStyles = customStylesDefault,
}) => (
  <div className={cx(styles.containerSelect, classNameContainer)}>
    {label && <div className={cx(styles.label, classNameLabel)}>{label}</div>}
    <div className={styles.wrapperSelect}>
      <ReactSelect
        onChange={onChange}
        options={items}
        name={name}
        isSearchable={false}
        components={components}
        placeholder={placeholder}
        styles={customStyles}
      />
    </div>
  </div>
);
