import React, { RefObject, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useWeb3React } from '@web3-react/core';
import scrollDownImg from '@assets/images/home/scrollDown.svg';
import mainLeft from '@assets/images/main-left.png';
import mainRight from '@assets/images/main-right.png';
import goldCube from '@assets/images/gold-cube.png';
import silverCubes from '@assets/images/silver-cube.png';
// import {
//   Loader,
// } from '../Loader';
// import { ConnectButton } from '../../../../components/connect-button';
import { ConnectButton } from '@components';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type MainProps = {
  refSection: RefObject<HTMLDivElement>;
  onScrollDown: () => void;
  onConnectWallet: () => void;
  isLoader: boolean;
};

const SectionMain = ({
  refSection, onScrollDown,
}: MainProps) => {
  const {
    active,
  } = useWeb3React();

  const isShow = useMemo(() => active, [active]);

  return (
    <section className={styles.containerMain} ref={refSection}>
      <img src={mainLeft} alt="" className={styles.mainLeft} />
      <img src={mainRight} alt="" className={styles.mainRight} />

      <img src={goldCube} alt="" className={styles.goldCube} />
      <img src={silverCubes} alt="" className={styles.silverCube} />
      <div className={styles.bg}>

        {/* <video className={styles.video} autoPlay loop muted>
        <source src="/background.mp4" type="video/mp4" />
      </video> */}
        <p className={styles.title}>
          represent
          <span className={styles.titleInner}>
            vote
          </span>
        </p>
        <p className={styles.subtitle}>
          {/* eslint-disable-next-line max-len */}
          UNLEASH THE POWER OF YOUR VOICE
        </p>
        <p className={styles.textInfo}>
          {/* eslint-disable-next-line max-len */}
          Connect your wallet to
          {' '}
          <br />
          VOTE and create PROPOSALS
          {' '}
          <br />
          in your verified area
        </p>

        <div className={`${styles.connectButton} ${active ? styles.connectButtonActive : ''}`}>
          <ConnectButton />
        </div>
        <div className={styles.containerBottom}>
          <button
            type="button"
            className={cx(
              styles.buttonScrollDown,
              { hideClass: isShow, classButtonNoDelay: active },
            )}
            onClick={onScrollDown}
          >
            Scroll Down
            <img
              src={scrollDownImg}
              alt="scroll down"
              className={styles.scrollImg}
            />
          </button>
        </div>
      </div>
      {/* <Loader isShow={isShow} isShowConnectButton={isLoader && !active} /> */}

    </section>
  );
};

export { SectionMain };
