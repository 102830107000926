import React from 'react';
import cx from 'classnames';
import { Text } from '@components';
import styles from './styles.module.scss';

type ListProps = {
  items: { label:string, text:string }[];
  className?: string;
  classNameItem?: string;
  classNameEl?:string
};

const List = ({
  items, classNameItem, classNameEl, className,
}: ListProps) => (
  <ul className={cx(styles.list, className)}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li className={`${styles.item} ${classNameEl} line`} key={`item-${index}`}>
        <Text className={cx(styles.text, classNameItem, styles.label)}>
          {item.label}
        </Text>
        {' '}
        <Text className={cx(styles.text, classNameItem)}>
          {item.text}
        </Text>
      </li>
    ))}
  </ul>
);

export { List };
