import { styled, css, keyframes } from 'styled-components';
import { theme } from './const';
import { toEnd } from './media';

/* animations */

export const dropdownShowAnimation = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

export const dropdownHideAnimation = keyframes`
    0% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
    }
`;

export const getIconSize = (size = 24) => css`
  width: ${`${size}px`};
  min-width: ${`${size}px`};
  height: ${`${size}px`};
  min-height: ${`${size}px`};
`;

export const ColumnWrapper = styled.div<{
  gap?: number
  fullWidth?: boolean
  marginTop?: number
  isCenter?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isCenter }) => isCenter && 'center'};
  gap: ${({ gap = 8 }) => `${gap}px`};

  width: ${({ fullWidth }) => fullWidth && '100%'};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
`;

export const FlexAlignCenterWrapper = styled.div<{
  gap?: number
  fullWidth?: boolean
  isCenter?: boolean
  marginTop?: number
  direction?: string
}>`
  display: flex;
  align-items: ${({ isCenter = true }) => isCenter && 'center'};
  gap: ${({ gap = 8 }) => `${gap}px`};

  width: ${({ fullWidth }) => fullWidth && '100%'};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};

  flex-direction: ${({ direction = 'row' }) => direction};
`;

export const FlexBetweenWrapper = styled.div<{
  gap?: number
  fullWidth?: boolean
  isCenter?: boolean
  direction?: string
}>`
  display: flex;
  align-items: ${({ isCenter = true }) => isCenter && 'center'};
  justify-content: space-between;
  gap: ${({ gap = 8 }) => `${gap}px`};

  width: ${({ fullWidth }) => fullWidth && '100%'};
  flex-direction: ${({ direction = 'row' }) => direction};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  min-height: 100%;
  padding: 0 16px;

  ${toEnd('mobile')} {
    padding: 0 12px;
  }
`;

export const PageWrapper = styled.main<{ maxWidth?: number }>`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : theme.maxContentWidth)};
  margin-top: auto;

  overflow: hidden;

  @media screen and (max-height: 820px) {
    margin-top: 32px;
  }
`;
