import { fork } from 'redux-saga/effects';
import { profileGetProfileSaga } from '@store/profile/sagas/getProfile';
import { profileGetKycLinkSaga } from '@store/profile/sagas/verifyKyc';
import { profileGetBalanceSaga } from '@store/profile/sagas/getBalance';

export const profileEffects = [
  fork(profileGetProfileSaga),
  fork(profileGetKycLinkSaga),
  fork(profileGetBalanceSaga),
];
