/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useRef, useState } from 'react';

import * as T from './types';
import * as S from './units';

const SearchInputDropdown: FC<T.ISearch> = (props) => {
  const inputElementRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const focusHandler = () => {
    inputElementRef.current?.focus();
  };

  return (
    <S.InputSearchWrapper onClick={focusHandler} {...props}>
      <S.SearchIcon isFocused={isFocused}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#F8F7F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.4434 16.4438L20.9997 21.0002" stroke="#F8F7F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      </S.SearchIcon>

      <S.InputSearch
        value={props.searchValue}
        onChange={(e) => props.setSearchValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={props.placeholder || 'Search'}
        ref={inputElementRef}
      />
    </S.InputSearchWrapper>
  );
};

export { SearchInputDropdown };
