import { fork } from 'redux-saga/effects';
import { proposalsGetProposalsListSaga } from '@store/proposals/sagas/getProposalsList';
import { proposalsGetProposalsSearchSaga } from '@store/proposals/sagas/getProposalsSearch';
import { proposalsGetProfileProposalsSaga } from '@store/proposals/sagas/getProfileProposals';
import { proposalsGetFeeSaga } from './getFee';

export const proposalsEffects = [
  fork(proposalsGetProposalsListSaga),
  fork(proposalsGetProposalsSearchSaga),
  fork(proposalsGetProfileProposalsSaga),
  fork(proposalsGetFeeSaga),
];
