import React, { useCallback, memo } from 'react';
import { Button, Icon } from '@components';
import { ButtonTheme, Icons } from '@types';
import styles from './styles.module.scss';

type FileUploadProps = {
  className?: string;
  onUpload: (files: FileList | null) => void;
};
export const FileUpload = memo<FileUploadProps>(({ onUpload, className }) => {
  const handleAttachFile = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    onUpload(files);
  }, [onUpload]);

  return (
    <Button
      onClick={() => {}}
      className={className}
      theme={ButtonTheme.outline}
    >
      Upload the list of voters
      <Icon value={Icons.upload} className={styles.uploadIcon} />
      <input
        type="file"
        onChange={handleAttachFile}
        style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0,
        }}
        autoComplete="off"
      />

    </Button>
  );
});
