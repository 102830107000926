import {
  call, takeLeading, put,
  select,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import { createVoting } from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalItemCreate, proposalItemSetState } from '@store/proposalItem/actionCreators';

import { toastClose } from '@components';

// const qtyVotersPayload = 1;
// const minPercentageVotersPayload = 50;

function* createProposalSaga({ payload }: ReturnType<typeof proposalItemCreate>) {
  try {
    yield put(proposalItemSetState({ isCreatingProposal: true }));
    const {
      time, date, qtyVoters, minQtyVoters, description,
    } = payload;
    date!.setHours(+time!.split(':')[0]);
    date!.setMinutes(+time!.split(':')[1]);

    const now = +((Date.now() / 1000).toFixed(0));
    const dateSec = +(+date!.getTime() / 1000);
    const duration = +((dateSec - now).toFixed(0)) as unknown as string;
    // const land = { country: payload.land.country };
    // const organizationEmployees = payload.organizationEmployees
    //   ? payload.organizationEmployees : [''];

    // const expiresAt = new Date(dateSec * 1000).toISOString();

    // const proposalAddress:Unwrap<typeof getCreatingContractAddress> = yield call(
    //   // @ts-ignore
    //   getCreatingContractAddress,
    //   salt,
    //   duration,
    //   qtyVotersPayload,
    //   minPercentageVotersPayload,
    // );

    // const {
    //   // hash,
    //   qtyVoters,
    //   // minPercentageVoters,
    //   // @ts-ignore
    // }: Unwrap<typeof createProposal> = yield call(createProposal, {
    //   ...payload,
    //   duration,
    //   land,
    //   organizationEmployees,
    //   address: proposalAddress,
    //   expiresAt,
    //   qtyVoters: qtyVotersPayload,
    //   minPercentageVoters: minPercentageVotersPayload,
    // });

    const { priceForCreateWei } = yield select((state) => state.proposalItem);
    // yield call(getPriceCreateProposal);
    console.log(+duration,
      +qtyVoters,
      +minQtyVoters,
      duration,
      priceForCreateWei);
    const a = yield call(
      createVoting,
      +duration,
      +qtyVoters,
      +minQtyVoters,
      description,
      priceForCreateWei,
    );

    console.log(a);
    toastClose();

    yield put(
      proposalItemSetState({
        isSuccessCreate: true,
        isCreatingProposal: false,
      }),
    );
  } catch (error) {
    yield put(proposalItemSetState({ isCreatingProposal: false }));
    sagaErrorHandler(error as string);
  }
}

export function* proposalItemCreateSaga() {
  yield takeLeading(
    ProposalItemActionTypes.CREATE_PROPOSAL,
    createProposalSaga,
  );
}
