import React, { useCallback } from 'react';
import { Modal } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import {
  proposalItemSetState,
} from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { CheckImage } from '@assets/images';
import styles from './styles.module.scss';

const SuccessModal = () => {
  const dispatch = useDispatch();

  const isSuccess = useSelector(
    proposalItemSelectors.getProp('isSuccess'),
  );

  const handleClose = useCallback(() => {
    dispatch(proposalItemSetState({
      isSuccess: false,
    }));
  }, [dispatch]);

  return (
    <Modal
      isOpen={isSuccess}
      onClose={handleClose}
      title="Success!"
      className={styles.modal}
      image={CheckImage}
      description="Thank you for Voting"
    />
  );
};

export { SuccessModal };
