import { createReducer } from '@utils';
import { AdminState } from '@store/admin/types';
import { ADMIN_HANDLERS } from '@store/admin/handlers';

const ADMIN_INITIAL_STATE: Readonly<AdminState> = {
  isStatisticsLoading: false,
  isSettingsLoading: false,
  isSettingsUpdating: false,

  uniqueVoters: '',
  income: '',
  gasPayments: '',

  creationPrice: '',
  votingTokenPrice: '',
  rpvTokenPrice: '',
  gasLimit: '',

  role: '',
  address: '',
};

export default createReducer(ADMIN_INITIAL_STATE, ADMIN_HANDLERS);
