/* eslint-disable no-nested-ternary */
import { styled, css } from 'styled-components';
import { mediaQueries } from '@constants';
import {
  dropdownHideAnimation, dropdownShowAnimation, getIconSize, toEnd,
} from '../../styles';
import { TabsDropdown } from '../TabsDropdown';
import { Loader } from '../Loader';
import { theme } from '../theme';

export const SelectWrapper = styled.div`
  position: relative;

  width: 100%;

  border-radius: ${theme.borderRadiusSmall};
  background: #0b0b0b;

  border-radius: 10px;
  outline-style: none;
`;

export const ValueWrapper = styled.div<{
  isDisabled?: boolean
  isOpen?: boolean
  isSmall?: boolean
}>`
  display: flex;
  align-items: center;
  gap: 8px;

  height: ${({ isSmall }) => (isSmall ? '40px' : '48px')};
  padding: 12px;

  border-radius: ${theme.borderRadiusSmall};

  transition: all 0.2s ease-in-out;

  user-select: none;

  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  ${toEnd('mobile')} {
    height: 40px;
  }

  ${({ isSmall }) => isSmall
    && css`
      min-width: 150px;
    `}
`;

export const Value = styled.p<{ isActive: boolean; isSmall?: boolean }>`
  min-width: 70px;
  font-size: ${({ isSmall }) => (isSmall ? '12px' : '16px')};
  font-weight: 400;
  line-height: ${({ isSmall }) => (isSmall ? '15px' : '20.42px')};

  transition: all 0.2s ease-in-out;
  white-space: nowrap;

  ${toEnd('mobile')} {
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  margin-bottom: 12px;
  padding-right: 12px;

  ${toEnd('mobile')} {
    margin-bottom: 16px;
  }
`;

export const Connect = styled.div<{ isGridStyle: boolean }>`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-right: 12px;

  ${({ isGridStyle }) => isGridStyle
    && css`
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px 6px;
    `}
`;

export const DropdownWrapper = styled.div<{
  isSelectOpen: boolean
  width: number
  isNoBorder?: boolean
}>`
  width: ${({ width }) => `${width}px`};
  min-width: min-content;
  max-height: 364px;
  padding: 12px 12px 12px 12px;

  visibility: ${({ isSelectOpen }) => (isSelectOpen ? 'visible' : 'hidden')};

  animation-name: ${({ isSelectOpen }) => (isSelectOpen ? dropdownShowAnimation : dropdownHideAnimation)};
  animation-duration: ${({ isSelectOpen }) => (isSelectOpen ? '0.4s' : '0s')};
  animation-fill-mode: forwards;

  overflow: hidden;

  z-index: ${theme.zIndex.dropdown};

  background: ${({ isNoBorder }) => (isNoBorder ? '#19191A' : '#0b0b0b')};
  border: 1px solid ${({ isNoBorder }) => (isNoBorder ? 'transparent' : '#e9bb58')};
  border-radius: 10px;

  &.full-screen {
    border: none;
    width: 100vw;
    max-height: none;

    animation-duration: 0s;
    padding: 24px 20px 20px 20px;

    & ${ControlsContainer}, & ${Connect} {
      padding-right: 0;
    }

    &.full-height {
      height: calc(100dvh - 100px);
    }
  }
`;

export const ScrollBlock = styled.div`
  max-height: 288px;

  overflow: hidden auto;

  ${toEnd('mobile')} {
    max-height: 280px;
  }
`;

export const Item = styled.div<{ isActive: boolean; isGridStyle: boolean; isNoBorder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isGridStyle }) => isGridStyle && 'center'};

  gap: 8px;
  width: ${({ isGridStyle }) => (isGridStyle ? 'calc(20% - 6px)' : 'auto')};
  padding: ${({ isGridStyle }) => (isGridStyle ? '6px' : '10px 8px')};

  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;

  border: ${({ isGridStyle, isActive }) => (isGridStyle ? `1px solid ${isActive ? '#E9BB58' : 'transparent'}` : '0px solid transparent')};
  border-radius: ${theme.borderRadiusSmall};

  background: ${({ isGridStyle, isActive, isNoBorder }) => ((isActive || isGridStyle) && !isNoBorder
    ? '#19191A'
    : isNoBorder && isActive
      ? '#272726'
      : 'transparent')};

  transition: all 0.2s ease-in-out;

  user-select: none;

  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      border-color: ${({ isGridStyle }) => isGridStyle && '#E9BB58'};

      background: ${({ isGridStyle }) => (!isGridStyle ? '#19191A' : 'transparent')};
    }
  }

  ${toEnd('mobile')} {
    padding: ${({ isGridStyle }) => (isGridStyle ? '8px' : '12px 8px')};

    font-size: 20px;
    line-height: 28px;
  }
`;

export const LoaderStyled = styled(Loader)`
  margin-left: auto;
`;

export const ArrowIcon = styled('div')<{ isOpen: boolean; isSmall?: boolean }>`
  ${({ isSmall }) => (isSmall ? getIconSize(20) : getIconSize())}

  margin-left: auto;

  transform: ${({ isOpen }) => isOpen && 'rotateX(180deg)'};

  transition: all 0.2s ease-in-out;

  path {
    transition: all 0.2s ease-in-out;

    stroke: #101010;
  }
`;

export const Icon = styled.img<{ size?: number; isGridStyle?: boolean }>`
  ${({ size = 28 }) => getIconSize(size)}

  border-radius: 50%;

  ${toEnd('mobile')} {
    ${({ isGridStyle, size }) => getIconSize(isGridStyle ? 28 : size || 32)}
  }
`;

export const TabsStyled = styled(TabsDropdown)`
  max-width: 76px;
  min-width: 76px;

  ${toEnd('mobile')} {
    max-width: 92px;
    min-width: 92px;
  }
`;

export const CustomButtonWrapper = styled.div<{ isOpen: boolean }>`
  & svg {
    transition: all 0.2s ease-in-out;

    transform: ${({ isOpen }) => isOpen && 'rotateX(180deg)'};
  }
`;

export const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  background: #0b0b0b;
  padding-bottom: 20px;

  & svg path {
    fill: #e9bb58;
    fill-opacity: 1;
  }

  ${mediaQueries.lg} {
    display: none;
  }
`;
