import {
  call, takeLeading,
} from 'redux-saga/effects';
import { ProfileActionTypes } from '@store/profile/actionTypes';
import { Unwrap } from '@types';
import {
} from '@store/profile/actionCreators';
import { getKycLink } from '@api/back';
import { sagaErrorHandler } from '@utils';

function* getKycLinkSaga() {
  try {
    const linkKyc: Unwrap<typeof getKycLink> = yield call(getKycLink);
    console.log(linkKyc);
    // if (linkKyc) {
    //   yield put(profileSetState({
    //     linkKyc,
    //   }));
    // }
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* profileGetKycLinkSaga() {
  yield takeLeading(ProfileActionTypes.GET_KYC_LINK, getKycLinkSaga);
}
