import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string()
    .required('Required'),
  description: Yup.string()
    .required('Required'),
  qtyVoters: Yup.number()
    .integer('Integer')
    .moreThan(0)
    .required('Required'),
  minQtyVoters: Yup.number()
    .integer('Integer')
    .moreThan(0)
    .max(100)
    .required('Required'),
  proposalType: Yup.object()
    .required('Required'),
  keywords: Yup.array()
    .of(Yup.string())
    .min(1)
    .required('Required'),
  date: Yup.date()
    .min(new Date())
    .required('Required'),
  time: Yup.string()
    .required('Required'),
});
