import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Select } from '../Select';
import styles from './styles.module.scss';

const items = [
  { value: '00:00', label: '00:00' },
  { value: '03:00', label: '03:00' },
  { value: '06:00', label: '06:00' },
  { value: '09:00', label: '09:00' },
  { value: '12:00', label: '12:00' },
  { value: '15:00', label: '15:00' },
  { value: '18:00', label: '18:00' },
  { value: '21:00', label: '21:00' },
];

type TimeSelectProps = {
  className?: string,
  label?: string,
  value?: string;
  onChange: (value: string) => void;
};

const TimeSelect = ({
  className = '', onChange, value, label,
}: TimeSelectProps) => {
  const handleChange = useCallback((item) => {
    onChange(item.value);
  }, [onChange]);

  const currentValue = useMemo(() => items.find((item) => item.value === value), [value]);
  return (
    <div className={cx(styles.timeSelect, className)}>
      <Select
        label={label}
        placeholder="Time"
        items={items}
        onChange={handleChange}
        value={currentValue}
      />
    </div>
  );
};

export { TimeSelect };
