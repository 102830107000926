import { fork } from 'redux-saga/effects';
import { authLogoutSaga } from '@store/auth/sagas/logout';
import { authGetAccessTokenSaga } from '@store/auth/sagas/getAccessToken';
import { authRefreshAccessTokenSaga } from '@store/auth/sagas/refreshAccessToken';

export const authEffects = [
  fork(authLogoutSaga),
  fork(authGetAccessTokenSaga),
  fork(authRefreshAccessTokenSaga),
];
