// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal__2AMf1 {\n  width: 575px;\n  padding: 39px 23px 42px 23px;\n}\n\n@media screen and (max-width: 480px) {\n  .styles_description__14gV0 {\n    margin: 30px 0 0 0;\n  }\n}", "",{"version":3,"sources":["webpack://src/containers/ProposalItem/components/VoteModal/styles.module.scss","webpack://src/constants/mixins.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,4BAAA;AAFF;;AC2BE;EDtBF;IAEI,kBAAA;EAFF;AACF","sourcesContent":["@import \"@constants/styles.scss\";\n@import \"@constants/mixins.scss\";\n\n.modal {\n  width: 575px;\n  padding: 39px 23px 42px 23px;\n}\n\n.description {\n  @include forLargeMobile {\n    margin: 30px 0 0 0;\n  }\n}\n","@import \"../constants/styles.scss\";\n\n$defaultFlexBoxAlign: center;\n$defaultFlexBoxJustify: center;\n\n@mixin default-transition($property) {\n  transition: $property 0.08s ease-in-out;\n}\n\n@mixin flexbox($align: $defaultFlexBoxAlign, $justify: $defaultFlexBoxJustify) {\n  display: flex;\n  align-items: $align;\n  justify-content: $justify;\n}\n\n@mixin can_backdrop {\n  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {\n    @content;\n  }\n}\n\n// Media request mixins MobileFirst\n\n@mixin forMobile {\n  @media screen and (max-width: 380px) {\n    @content;\n  }\n}\n\n@mixin forLargeMobile {\n  @media screen and (max-width: 480px) {\n    @content;\n  }\n}\n\n@mixin forTablet {\n  @media screen and (max-width: 1280px) {\n    @content;\n  }\n}\n\n@mixin forBreakpointWidth($max-width) {\n  @media screen and (max-width: $max-width) {\n    @content;\n  }\n}\n\n@mixin mqSm($min-width) {\n  @media screen and (min-width: 360px) {\n    @content;\n  }\n}\n\n@mixin mqMd {\n  @media screen and (min-width: 768px) {\n    @content;\n  }\n}\n\n@mixin mqLg {\n  @media screen and (min-width: 1440px) {\n    @content;\n  }\n}\n\n@mixin mqXl {\n  @media screen and (min-width: 1920px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles_modal__2AMf1",
	"description": "styles_description__14gV0"
};
export default ___CSS_LOADER_EXPORT___;
