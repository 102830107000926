import { AuthState } from '@store/auth/types';
import { AuthActionTypes } from '@store/auth/actionTypes';

export const authSetState = (payload: Partial<AuthState>) => ({
  type: AuthActionTypes.SET_STATE,
  payload,
});

export const authGetAccessToken = (payload: boolean = false) => ({
  type: AuthActionTypes.GET_ACCESS_TOKEN,
  payload,
});

export const authRefreshAccessToken = () => ({
  type: AuthActionTypes.REFRESH_ACCESS_TOKEN,
});

export const authLogout = () => ({
  type: AuthActionTypes.LOG_OUT,
});

export const getPassport = (payload:boolean = false) => ({
  type: AuthActionTypes.GET_PASSPORT,
  payload,
});

export const hasPassport = (payload:boolean = false) => ({
  type: AuthActionTypes.GET_PASSPORT,
  payload,
});
