export const profileDefaultState = {
  id: '',
  walletAddresses: [],
  isAdminWallet: false,
  personalData: {
    firstName: {
      name: '',
      visibility: false,
      value: '',
    },
    lastName: {
      name: '',
      visibility: false,
      value: '',
    },
    birthDate: {
      name: '',
      visibility: false,
      value: '',
    },
    email: {
      name: '',
      visibility: false,
      value: '',
    },
    verificationDocumentType: {
      name: '',
      visibility: false,
      value: '',
    },
  },
  location: {
    country: {
      name: '',
      visibility: false,
      value: '',
    },
    city: {
      name: '',
      visibility: false,
      value: '',
    },
    region: {
      name: '',
      visibility: false,
      value: '',
    },
    structure: {
      name: '',
      visibility: false,
      value: '',
    },
    apartment: {
      name: '',
      visibility: false,
      value: '',
    },
  },
  verification: {
    verificationDate: {
      name: '',
      visibility: false,
      value: '',
    },
    verificationDocumentType: {
      name: '',
      visibility: false,
      value: '',
    },
  },
  nft: {
    address: '',
    tokenId: 0,
    owner: '',
    tokenUri: '',
    minterAddress: '',
  },
  sessionUrl: '',
  kyc: false,
  kycStatus: '',
  mintStatus: false,
  reason: '',
  role: {
    name: '',
  },
  balanceRPT: '0',
  balanceRPV: '0',
  balanceBNB: '0',
  currentRate: '0',
};
