import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileGetBalance, getRateBnB } from '@store/profile/actionCreators';
import { profileSelectors } from '@store/profile/selectors';

export function useRpvStore() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileGetBalance());
    dispatch(getRateBnB());
  }, [dispatch]);

  const balanceBNB = useSelector(profileSelectors.getProp('balanceBNB'));
  const balanceRPV = useSelector(profileSelectors.getProp('balanceRPV'));
  const rate = +useSelector(profileSelectors.getProp('currentRate'));

  return {
    balanceBNB,
    balanceRPV,
    rate,
  };
}
