import { call, put, takeLeading } from 'redux-saga/effects';
import { ProposalsActionTypes } from '@store/proposals/actionTypes';
import { proposalsGetProposalsList, proposalsSetState } from '@store/proposals/actionCreators';
import { sagaErrorHandler } from '@utils';
import { getProfileProposals } from '@api/back';
import { ProposalRaw, ProposalType, Unwrap } from '@types';
// import { getAddress } from '@api/ethereum';
import { Proposal } from '../types';
import { formatRawProposal } from './formatRawProposal';

// import { formatRawProposal } from './formatRawProposal';

function* getProfileProposalsSaga(
  { payload }: ReturnType<typeof proposalsGetProposalsList>,
) {
  try {
    yield put(proposalsSetState({
      isProfileProposalsLoading: true,
      profileProposals: [],
      profileProposalsTotal: 0,
    }));

    const { pageLimit, pageOffset, proposalType } = payload;

    // const address:string = yield call(getAddress);

    const requestUrl = proposalType === ProposalType.Created
      ? `/proposal/created?page.limit=${pageLimit}&offset=${pageOffset}`
      : `/proposal/voted?page.limit=${pageLimit}&offset=${pageOffset}&proposalType=${proposalType}`;

    // let profileProposalsRaw:ProposalRaw[] = [];
    // let profileProposalsTotal = profileProposalsRaw.length;

    const {
      list: profileProposalsRaw,
      total: profileProposalsTotal,
    }: Unwrap<typeof getProfileProposals> = yield call(
      getProfileProposals, requestUrl,
    );

    // eslint-disable-next-line max-len
    // const profileProposalsRaw:ProposalRaw[] = profileProposalsRawTemp.filter((proposal) => proposal?.creatorWalletAddress?.toLowerCase() === address?.toLowerCase());
    // const profileProposalsTotal = profileProposalsTotalTemp;

    const profileProposals: Proposal[] = profileProposalsRaw.map(
      (rawProposal: ProposalRaw) => formatRawProposal(rawProposal),
    );

    yield put(proposalsSetState({
      profileProposals,
      profileProposalsTotal,
      isProfileProposalsLoading: false,
    }));
  } catch (error) {
    yield put(proposalsSetState({ isProfileProposalsLoading: false }));
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* proposalsGetProfileProposalsSaga() {
  yield takeLeading(ProposalsActionTypes.GET_PROFILE_PROPOSALS, getProfileProposalsSaga);
}
