// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./FuturaMediumC.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "./FuturaMediumC.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "./FuturaMediumC.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Futura\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src: local(\"Futura Medium Cyrillic\"), local(\"FuturaMediumC\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n  font-weight: 500;\n  font-style: normal;\n}", "",{"version":3,"sources":["webpack://src/assets/fonts/Futura/stylesheet.css"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,4CAAA;EACA,qPAAA;EAIA,gBAAA;EACA,kBAAA;AAFD","sourcesContent":["@font-face {\n\tfont-family: 'Futura';\n\tsrc: url('FuturaMediumC.eot');\n\tsrc: local('Futura Medium Cyrillic'), local('FuturaMediumC'),\n\t\turl('FuturaMediumC.eot?#iefix') format('embedded-opentype'),\n\t\turl('FuturaMediumC.woff') format('woff'),\n\t\turl('FuturaMediumC.ttf') format('truetype');\n\tfont-weight: 500;\n\tfont-style: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
