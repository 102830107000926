export enum Icons {
  emoHappy = 'emo-happy',
  emoWink = 'emo-wink',
  close = 'close',
  arrow = 'arrow',
  copy = 'copy',
  menu = 'menu',
  facebookSquared = 'facebook-squared',
  twitter = 'twitter',
  plus = 'plus',
  back = 'back',
  upload = 'upload',
  logOut = 'logout',
  search = 'search',
  left = 'left',
  right = 'right',
}
