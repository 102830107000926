import { sagaErrorHandler } from '@utils';

export const formatToThousands = (value: string) => {
  try {
    if (Number(value) < 1000) {
      return value;
    }
    return Number(value).toLocaleString('ru-RU');
  } catch (error) {
    sagaErrorHandler(error);
    return '';
  }
};
