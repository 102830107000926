import React, { RefObject, useState } from 'react';
import { Text } from '@components';
import team1 from '@assets/images/home/team-1.png';
import team2 from '@assets/images/home/team-2.png';
import team3 from '@assets/images/home/team-3.png';
import team4 from '@assets/images/home/team-4.jpeg';
import team5 from '@assets/images/home/team-5.png';
import team6 from '@assets/images/home/team-6.png';
import team7 from '@assets/images/home/team-7.png';
import team8 from '@assets/images/home/team-8.png';
import styles from './styles.module.scss';

const team = [{
  img: team1,
  name: 'Dan Morrison',
  position: 'CEO and Founder',
}, {
  img: team2,
  name: 'Lance Morrison',
  position: 'CEO and Founder',
}, {
  img: team3,
  name: 'Karthi Ratham',
  position: 'Chief Marketing Officer',
}, {
  img: team4,
  name: 'Jessy Seida',
  position: 'Chief Growth Officer',
}, {
  img: team5,
  name: 'PAUL Ostanin',
  position: 'CEO Mercury team',
}, {
  img: team6,
  name: 'Konstantin Shestak',
  position: 'CTO Mercury team',
}, {
  img: team7,
  name: 'Victor Zelenkevich',
  position: 'COO Mercury team',
}, {
  img: team8,
  name: 'NIA OSTANINA',
  position: 'Director of Design & Strategy, Mercury team',
}];

type SectionTeamProps = {
  refSection: RefObject<HTMLDivElement>;
};

const TeamItem = ({ position, img, name }:{
  position: string
  img:string
  name:string
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
  // <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`${styles.teamItemImg} ${isHovered ? styles.teamItemImgHovered : ''}`}
      >

        <img src={img} alt="" />
      </div>

      <div className={`${styles.teamDescription}
        ${isHovered ? styles.teamDescriptionHovered : ''}`}
      >
        <div className={styles.teamName}>{name}</div>
        <div className={styles.teamRole}>{position}</div>
      </div>

    </div>
  // </SwiperSlide>
  );
};

const SectionTeam = ({
  refSection,
}: SectionTeamProps) => (
  <section className={styles.containerTeam} ref={refSection}>
    <div className={styles.content}>
      <div className={styles.containerInfo}>
        <Text type="h2" className={styles.title}>
          Team
        </Text>
        <Text type="p" className={styles.teamInfo}>
          Our team brings incalculable global metrics for introduction of
          mass adoption to decentralization. A unique blend of public,
          private, and professional experience in Technology, Governance,
          Change Leadership, Education, Finance, Policy, International
          Business, Entrepreneurship, and Investments, will ensure powerful
          outcomes for this innovative technology.
        </Text>
      </div>

    </div>
    <div className={styles.teamList}>
      {team.map((item, index) => {
        const { img, name, position } = item;
        // eslint-disable-next-line react/no-array-index-key
        return <TeamItem key={index} img={img} name={name} position={position} />;
      })}
    </div>

  </section>
);

export { SectionTeam };

// import React, {
//   RefObject, useEffect, useRef, useState,
// } from 'react';
// import { Text } from '@components';
// import team1 from '@assets/images/home/team-1.png';
// import team2 from '@assets/images/home/team-2.png';
// import team3 from '@assets/images/home/team-3.png';
// import team4 from '@assets/images/home/team-4.png';
// import team5 from '@assets/images/home/team-5.png';
// import team6 from '@assets/images/home/team-6.png';
// import team7 from '@assets/images/home/team-7.png';
// import team8 from '@assets/images/home/team-8.png';

// // Styles must use direct files imports
// // import Swiper from 'swiper';
// // @ts-ignore
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Virtual } from 'swiper';
// import styles from './styles.module.scss';
// import 'swiper/swiper.scss';

// // Import Swiper styles
// // import 'swiper/css';

// const team = [{
//   img: team1,
//   name: 'Dan Morrison',
//   position: 'CEO and Founder',
// }, {
//   img: team2,
//   name: 'Lance Morrison',
//   position: 'CEO and Founder',
// }, {
//   img: team3,
//   name: 'Karthi Ratham',
//   position: 'Chief Marketing Officer',
// }, {
//   img: team4,
//   name: 'Matt Haynes',
//   position: 'Chief Growth Officer',
// }, {
//   img: team5,
//   name: 'PAUL Ostanin',
//   position: 'CEO Mercury team',
// }, {
//   img: team6,
//   name: 'Konstantin Shestak',
//   position: 'CTO Mercury team',
// }, {
//   img: team7,
//   name: 'Victor Zelenkevich',
//   position: 'COO Mercury team',
// }, {
//   img: team8,
//   name: 'NIA OSTANINA',
//   position: 'Director of Design & Strategy, Mercury team',
// }];

// type SectionTeamProps = {
//   refSection: RefObject<HTMLDivElement>;
// };

// const TeamItem = ({ position, img, name }:{
//   position: string
//   img:string
//   name:string
// }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   return (
//     <SwiperSlide className={styles.bla}>

//       {/* <div className={styles.teamItem}> */}
//       <div
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//         className={`${styles.teamItemImg} ${isHovered ? styles.teamItemImgHovered : ''}`}
//       >

//         <img src={img} alt="" />
//       </div>

//       <div className={`${styles.teamDescription}
//         ${isHovered ? styles.teamDescriptionHovered : ''}`}
//       >
//         <div className={styles.teamName}>{name}</div>
//         <div className={styles.teamRole}>{position}</div>
//       </div>

//       {/* </div> */}
//     </SwiperSlide>
//   );
// };

// const SliderItem = ({ position, img, name }:{
//   position: string
//   img:string
//   name:string
// }) => (
//   <SwiperSlide className={styles.bla}>
//     <div className={styles.teamItem}>
//       <div
//         className={styles.teamItemImg}
//       >

//         <img src={img} alt="" />
//       </div>

//       <div className={styles.teamDescription}>
//         <div className={styles.teamName}>{name}</div>
//         <div className={styles.teamRole}>{position}</div>
//       </div>
//     </div>
//   </SwiperSlide>
// );

// const SectionTeam = ({
//   refSection,
// }: SectionTeamProps) => {
//   const swiperRef = useRef(null);
//   const [isMounted, setIsMpunted] = useState(false);

//   useEffect(() => {
//     if (swiperRef?.current) {
//       // @ts-ignore
//       // swiperRef?.current?.swiper.loop = true;
//     }
//   }, [swiperRef?.current]);
//   return (
//     <section className={styles.containerTeam} ref={refSection}>
//       <div className={styles.content}>
//         <div className={styles.containerInfo}>
//           <Text type="h2" className={styles.title}>
//             Team
//           </Text>
//           <Text type="p" className={styles.teamInfo}>
//             Our team brings incalculable global metrics for introduction of
//             mass adoption to decentralization. A unique blend of public,
//             private, and professional experience in Technology, Governance,
//             Change Leadership, Education, Finance, Policy, International
//             Business, Entrepreneurship, and Investments, will ensure powerful
//             outcomes for disruptive technology.
//           </Text>
//           {/* <div className={styles.slider}> */}
//           <Swiper
//             modules={[Virtual]}
//             // @ts-ignore
//             spaceBetween={0}
//             slidesPerView={2.5}
//             wrapperClass="swiper-wrapper1"
//             className={styles.slider}
//             slideActiveClass={styles.active}
//             loop
//             loopAdditionalSlides={10}
//             loopFillGroupWithBlank
//             autoplay={{ // add
//               delay: 0, // add
//             }}
//             ref={swiperRef}
//             breakpoints={{
//               500: {
//                 slidesPerView: 3,
//                 loop: true,
//               },
//               600: {
//                 slidesPerView: 4,
//               },
//               675: {
//                 slidesPerView: 4.5,
//               },
//               750: {
//                 slidesPerView: 5,
//               },
//               825: {
//                 slidesPerView: 5.5,
//               },
//               900: {
//                 slidesPerView: 6,
//               },
//             }}
//           >

//             <div className={`swiper-wrapper1 swiper-wrapper ${styles.slider}`}>

//               {team.map((item) => {
//                 const { img, name, position } = item;
//                 return <SliderItem key={name} img={img} name={name} position={position} />;
//               })}

//             </div>
//           </Swiper>
//         </div>
//         {/* </div> */}
//       </div>
//     </section>
//   );
// };
// export { SectionTeam };

// eslint-disable-next-line no-lone-blocks
{ /* eslint-disable-next-line react/jsx-props-no-spreading, no-lone-blocks */ }
{ /* <div className={styles.hui}>
<Swiper
  className={styles.hui}
  spaceBetween={42}
  slidesPerView="auto"
>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[0].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[0].name}</div>
        <div className={styles.teamRole}>{team[0].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[1].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[1].name}</div>
        <div className={styles.teamRole}>{team[1].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[2].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[2].name}</div>
        <div className={styles.teamRole}>{team[2].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[3].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[3].name}</div>
        <div className={styles.teamRole}>{team[3].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[4].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[4].name}</div>
        <div className={styles.teamRole}>{team[4].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[5].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[5].name}</div>
        <div className={styles.teamRole}>{team[5].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[6].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[6].name}</div>
        <div className={styles.teamRole}>{team[6].position}</div>
      </div>

    </div>
  </SwiperSlide>
  <SwiperSlide className={styles.bla}>

    <div className={styles.teamItem}>
      <div
        className={styles.teamItemImg}
      >

        <img src={team[7].img} alt="" />
      </div>

      <div className={styles.teamDescription}>
        <div className={styles.teamName}>{team[7].name}</div>
        <div className={styles.teamRole}>{team[7].position}</div>
      </div>

    </div>
  </SwiperSlide>

</Swiper>

</div> */ }
