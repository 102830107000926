import React, { useState, useMemo, useCallback } from 'react';
import { Modal } from '@components';
import { useSelector } from 'react-redux';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { ButtonTheme } from '@types';

export const useFinishModal = (
  callBack: () => void,
) => {
  const [isFinishModalOpened, setIsFinishModalOpened] = useState(false);

  const priceForCreate = useSelector(
    proposalItemSelectors.getProp('priceForCreate'),
  );

  const closeFinishModal = useCallback(() => {
    setIsFinishModalOpened(false);
  }, []);

  const openFinishModal = useCallback(() => {
    setIsFinishModalOpened(true);
  }, []);

  const finishEditAndSendTransaction = useCallback(() => {
    setIsFinishModalOpened(false);
    callBack();
  }, [callBack]);

  const finishModalButtons = useMemo(() => [
    {
      children: 'Finish',
      theme: ButtonTheme.outline,
      onClick: finishEditAndSendTransaction,
      key: 2,
    },
    {
      children: 'Edit',
      onClick: closeFinishModal,
      key: 1,
    },
  ], [closeFinishModal, finishEditAndSendTransaction]);

  const ModalFinish = useCallback(() => (
    <Modal
      title="List new proposal?"
      description={[`You have to pay ${priceForCreate} ETH to create a new proposal.`, 'After listing it can not be redacted!']}
      isOpen={isFinishModalOpened}
      onClose={closeFinishModal}
      buttons={finishModalButtons}
    />
  ), [closeFinishModal, finishModalButtons, isFinishModalOpened, priceForCreate]);

  return {
    openFinishModal,
    ModalFinish,
  };
};
