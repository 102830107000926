import { createReducer } from '@utils';
import { ProposalStatus, ProposalType } from '@types';
import { ProposalItemState } from '@store/proposalItem/types';
import { PROPOSAL_ITEM_HANDLERS } from '@store/proposalItem/handlers';

const PROPOSAL_ITEM_INITIAL_STATE: Readonly<ProposalItemState> = {
  isSuccess: false,
  isSuccessCreate: false,
  isVoting: false,
  isCreatingProposal: false,
  isFetching: false,
  hash: '',
  address: '',
  status: ProposalStatus.Active,
  name: '',
  description: '',
  imageHash: '',
  imageMimeType: '',
  proposalType: ProposalType.CryptoVoting,
  land: {
    country: '',
    region: '',
    city: '',
    administrativeArea: '',
  },
  orgEmployeesHash: '',
  keywords: [],
  duration: 0,
  startedAt: 0,
  qtyVoters: 0,
  minPercentageVoters: 0,
  countAllVoting: 0,
  percentYes: 0,
  balanceVoting: 0,
  priceForCreate: '0',
  creator: '',
  creatorName: '',
  expiresAt: '',
};

export default createReducer(PROPOSAL_ITEM_INITIAL_STATE, PROPOSAL_ITEM_HANDLERS);
