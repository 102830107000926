import { call, put, takeLatest } from 'redux-saga/effects';
import { ProposalsActionTypes } from '@store/proposals/actionTypes';
import {
  proposalsSetState,
} from '@store/proposals/actionCreators';
import { sagaErrorHandler } from '@utils';
import { getMakeVoteFee } from '@api/ethereum/votingFactory';

function* getFee() {
  try {
    const voteFee = yield call(
      getMakeVoteFee,
    );

    yield put(proposalsSetState({
      voteFee,
    }));
  } catch (error) {
    yield put(proposalsSetState({ isProposalsListLoading: false }));
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* proposalsGetFeeSaga() {
  yield takeLatest(ProposalsActionTypes.GET_PROPOSALS_LIST, getFee);
}
