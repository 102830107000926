export const light = {
  colors: {
    background: '#F8F7F7',
    text: '#101010',
    gray: '#CDC0C0',
    boxShadowOutside:
      '4px 4px 7px 0px rgba(192, 174, 174, 0.4), -4px -4px 7px 0px rgba(255, 255, 255, 1)',
    boxShadowInside:
      '4px 4px 7px 0px rgba(192, 174, 174, 0.35) inset, -4px -4px 7px 0px rgba(255, 255, 255, 0.85) inset',
    tabsBg: '#FFFFFF',
    activeTabBg: '#F3F0F0',
    tabActiveText: '#101010',
    red: '#C6737A',
  },
  searchIcon: '/img/search-light.svg',
};

export const dark = {
  colors: {
    background: '#211D25',
    text: '#F8F7F7',
    gray: '#504D51',
    boxShadowOutside:
      '4px 4px 7px 0px rgba(0, 0, 0, 0.35), -4px -4px 7px 0px rgba(255, 255, 255, 0.15)',
    boxShadowInside:
      '4px 4px 7px 0px rgba(0, 0, 0, 0.35) inset, -4px -4px 7px 0px rgba(255, 255, 255, 0.15) inset',
    tabsBg: '#070608',
    activeTabBg: '#2B272F',
    tabActiveText: '#F8F7F7',
    red: '#FF979F',
  },
  searchIcon: '/img/search-dark.svg',
};
