import {
  call,
  takeLeading,
  put,
  all,
} from 'redux-saga/effects';
import { peelBigNumber, sagaErrorHandler } from '@utils';
import { Unwrap } from '@types';
import { AdminActionTypes } from '@store/admin/actionTypes';
import { adminSetState } from '@store/admin/actionCreators';
import { getUniqueVoters, getIncome, getGasPayments } from '@api/back/admin';

function* getStatisticsSaga() {
  try {
    yield put(adminSetState({ isStatisticsLoading: true }));

    const [
      uniqueVoters,
      incomeRaw,
      gasPaymentsRaw,
    ]: [
      Unwrap<typeof getUniqueVoters>,
      Unwrap<typeof getIncome>,
      Unwrap<typeof getGasPayments>,
    ] = yield all([
      call(getUniqueVoters),
      call(getIncome),
      call(getGasPayments),
    ]);

    const income = peelBigNumber(incomeRaw);
    const gasPayments = peelBigNumber(gasPaymentsRaw);

    yield put(
      adminSetState({
        isStatisticsLoading: false,
        uniqueVoters,
        income,
        gasPayments,
      }),
    );
  } catch (error) {
    yield put(adminSetState({ isStatisticsLoading: false }));
    sagaErrorHandler(error);
  }
}

export function* adminGetStatisticsSaga() {
  yield takeLeading(AdminActionTypes.GET_STATISTICS, getStatisticsSaga);
}
