import { createReducer } from '@utils';
import { ProposalsState } from '@store/proposals/types';
import { PROPOSALS_HANDLERS } from '@store/proposals/handlers';

const PROPOSALS_INITIAL_STATE: Readonly<ProposalsState> = {
  isProposalsListLoading: false,
  isProposalsSearchLoading: false,

  isProfileProposalsLoading: false,

  profileProposals: [],
  profileProposalsTotal: 0,

  commonProposalsList: [],
  commonProposalsTotal: 0,

  searchProposalsList: [],
  searchProposalsTotal: 0,
};

export default createReducer(PROPOSALS_INITIAL_STATE, PROPOSALS_HANDLERS);
