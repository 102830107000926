import {
  call, takeLeading, put, select,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import {
  buyVoting,
} from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalIteGet } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { toastInfo, toastClose, toastSuccess } from '@components';
import { ToastMessages } from '@types';

function* buyVotingTokenSaga() {
  try {
    const hash = yield select(proposalItemSelectors.getProp('hash'));
    const addressVoting = yield select(proposalItemSelectors.getProp('address'));

    const amount = 1;

    toastInfo(ToastMessages.TRANSACTION_REQUEST);
    yield call(buyVoting, addressVoting, amount);
    toastClose();
    toastSuccess(ToastMessages.TRANSACTION_SUCCESS);

    yield put(proposalIteGet(hash));
  } catch (error) {
    sagaErrorHandler(error);
  }
}

export function* proposalItemBuyVotingTokenSaga() {
  yield takeLeading(
    ProposalItemActionTypes.BUY_VOTING_TOKEN,
    buyVotingTokenSaga,
  );
}
