import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useWeb3React } from '@web3-react/core';
import { profileSelectors } from '@store/profile/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { profileGetBalance } from '@store/profile/actionCreators';
import { CurrencyItemProps } from 'components/CurrencyItem';
import {
  ContentTabs, CurrencyName, Proposal, ProposalType, RouteName,
} from '@types';
// import { authLogout } from '@store/auth/actionCreators';
import { authSelectors } from '@store/auth/selectors';
import { history } from '@utils';
import { Button, ProposalsSingleTabContent, SearchInput } from '@components';
import imgFade from '@assets/images/fade.png';
import api from '@api';
import { useMobileTabs } from '../../components/Tabs/hooks/useMobileTabs';
import { MobileTabs } from '../../components/Tabs/components/MobileTabs';
import { useProposalsModal } from '../Proposals/hooks';
import { useInfiniteScroll, useKyc } from '../../hooks';
import { Modal } from '../../components/Modal';
import { WalletCard } from './components/WalletCard';
import styles from './styles.module.scss';
import { CARDS_PER_PAGE } from './constants';

const headers = ['Non Geo Gated', 'Geo Gated', 'my proposals '];
const disabledTabKeys: [] = [];

const fetchCreated = async ({
  limit, offset, creator, name, voter,
}: {
  limit?: number,
  offset?: number,
  creator?: string,
  name?: string,
  voter?:string
}) => {
  try {
    const { data } = await api.get<{ data: Proposal[]; total: number, offset:number, limit: number }>('/proposal/search', {
      params: {
        creator,
        name,
        offset,
        limit,
        voter,
      },
    });

    return data;
  } catch {
    return {
      data: [],
      limit: 0,
      offset: 0,
      total: 0,
    };
  }
};

const tabKeys: ProposalType[] = [
  ProposalType.CryptoVoting,
  ProposalType.LandBasedVoting,
  ProposalType.Created,
];

const Profile = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const hasPassport = useSelector(authSelectors.getProp('hasPassport'));
  const isAdminWallet = useSelector(profileSelectors.getProp('isAdminWallet'));
  // const creator = useSelector(profileSelectors.getProp('id'));
  // const { isVerified, handleVerifyClick, isAddressVerified } = useKyc();
  // const handleVerifyClick = () => {
  //   window.open(process.env.REACT_APP_REPRESENT_PASSPORT_URL, '_blank', 'noopener,noreferrer');
  // };

  const tabKeyValue = tabKeys[tabIndex];
  const [searchInput, setSearchInput] = useState('');
  const { account } = useWeb3React();
  const { data, ref, isLoading } = useInfiniteScroll(
    fetchCreated, {
      creator: tabKeyValue === ProposalType.Created ? account as string : '',
      name: searchInput,
      proposalType: tabKeys[tabIndex],
      voter: tabKeyValue !== ProposalType.Created ? account as string : '',
    },
  );
  const {
    handleVerifyClick,
  } = useKyc();

  useEffect(() => {
    dispatch(profileGetBalance());
  }, [dispatch]);

  const balanceBNB = useSelector(profileSelectors.getProp('balanceBNB'));

  const currencyItemsList: CurrencyItemProps[] = useMemo(() => [
    {
      value: balanceBNB,
      name: CurrencyName.ETH,
    },
  ], [balanceBNB]);

  const {
    isVerifyModalOpened,
    verifyAccountButtons,
    closeModal,
    handleCreateProposal,
    handleShowModal,
  } = useProposalsModal(
    hasPassport,
    handleVerifyClick,
  );

  // const onLogOutClick = useCallback(() => {
  //   dispatch(authLogout());
  // }, [dispatch]);

  // const {
  //   // profileProposals,
  //   profileProposalsTotal,
  //   // isProfileProposalsLoading,
  //   setTabKey: submitTabKey,
  //   setPage,
  // } = useGetProfileProposals({
  //   pageLimit: 2,
  //   tabKeys,
  //   query: searchInput,
  // });

  const isDocVerified = useSelector(profileSelectors.isDocVerified());
  const isAddressVerified = useSelector(profileSelectors.isAddressVerified());

  const onOpenProposalClick = useCallback((hash: string) => {
    history.push(`${RouteName.proposals}/${hash}`);
  }, []);
  // const list = profileProposals.filter((item) => item.name.includes(searchInput));

  const tabContents = useMemo(
    () => tabKeys.map((tabKey) => (
      <ProposalsSingleTabContent
        key={tabKey}
        cards={data || []}
        // cardsTotal={profileProposalsTotal}
        isLoading={isLoading}
        cardsPerPage={CARDS_PER_PAGE}
        classNameGridWrapper={styles.list}
        isAddressVerified={isAddressVerified}
        isDocVerified={isDocVerified}
        tabKey={tabKey}
        // submitNewPageNumber={setPage}
        onOpenProposalClick={onOpenProposalClick}
        // @ts-ignore
        ref={ref}
      />
    )), [
      isAddressVerified,
      isDocVerified,
      isLoading,
      // profileProposalsTotal,
      // setPage,
      onOpenProposalClick,
      data,
      ref,
    ],
  );

  const content: ContentTabs = {
    headers,
    tabContents,
  };

  const mobileTabContent = useMemo(
    () => content.tabContents[tabIndex],
    [content.tabContents, tabIndex],
  );

  const handleTab = useCallback(
    (index) => {
      if (disabledTabKeys !== undefined
        && tabKeys !== undefined
        // @ts-ignore
        && disabledTabKeys.includes(tabKeys[index])
        && handleShowModal !== undefined
      ) {
        // @ts-ignore
        handleShowModal(tabKeys[index]);
      } else {
        setTabIndex(index);
        // if (tabKeys !== undefined && submitTabKey !== undefined) {
        //   submitTabKey(tabKeys[index]);
        // }
      }
    },
    [handleShowModal],
  );

  const { selectOptions, selectedValue, onChange } = useMobileTabs({
    headers,
    tabIndex,
    handleTab,
  });
  const handleSearchChange = useCallback((input: string) => {
    setSearchInput(input);
    // setPage(1);
  }, []);

  return (
    <>
      <div className={styles.container}>
        {/* <div className={styles.inputWrapper}>
          <SearchInput
            value={searchInput}
            onChange={handleSearchChange}
            className={styles.searchInput}
          />
        </div> */}
        <SearchInput
          value={searchInput}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        <MobileTabs
          selectedValue={selectedValue}
          selectOptions={selectOptions}
          onChange={onChange}
          className={styles.centredWrapper}
        />
        <div className={styles.content}>

          <section className={styles.left}>
            <WalletCard
              address={account}
              currencyItemsList={currencyItemsList}
              isVerified={hasPassport}
              onVerifyClick={handleVerifyClick}
            />

          </section>
          <section className={styles.right}>
            <Button
              className={styles.createBtn}
              onClick={handleCreateProposal}
              disabled={!isAdminWallet}
            >
              create a new proposal

            </Button>
            {/* <Text type="h3" className={styles.title}>MY PROPOSALS</Text> */}
            <div className={styles.listWrapper}>

              {mobileTabContent}
              {data?.length > 2 && <img className={styles.fade} src={imgFade} alt="" />}
            </div>

          </section>
        </div>
      </div>
      {/* <div className={styles.logoutContainer}>
        <Button className={styles.logOutButton} onClick={onLogOutClick}>
          <Text className={styles.logOutText} type="p">Log Out</Text>
          <Icon value={Icons.logOut} style={{ color: Colors.red }} />
        </Button>
      </div> */}
      <Modal
        title="NFT passport is needed to participate in the voting"
        // description="Please verify your account with KYC
        // to see land based or organisational votings that you allowed to."
        isOpen={isVerifyModalOpened}
        onClose={closeModal}
        buttons={verifyAccountButtons}
      />
    </>
  );
};

export { Profile };
