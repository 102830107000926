import React from 'react';
import cx from 'classnames';
import { Colors, Icons } from '@types';
import { Icon } from '../Icon';
import styles from './styles.module.scss';

type ButtonIconProps = {
  className?: string;
  iconColor?: Colors;
  icon: Icons;
  onClick?: () => void;
  classNameIcon?: string;
};

const ButtonIcon = ({
  className = '', icon, onClick, iconColor = Colors.lightGrey, classNameIcon,
}: ButtonIconProps) => (
  <button
    type="button"
    className={cx(styles.buttonIcon, className)}
    onClick={onClick}
  >
    <Icon value={icon} style={{ color: iconColor }} className={classNameIcon} />
  </button>
);

export { ButtonIcon };
