// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_h1__2Aogq {\n  font-weight: 500;\n  font-size: 60px;\n  line-height: 140%;\n  text-align: center;\n  color: #1d1d1d;\n  text-transform: uppercase;\n}\n\n.styles_h2__9_wge {\n  font-weight: 500;\n  font-size: 36px;\n  line-height: 140%;\n  text-align: center;\n  color: #1d1d1d;\n  text-transform: uppercase;\n}\n\n.styles_h3__lnRum {\n  font-weight: 500;\n  font-size: 19px;\n  line-height: 140%;\n  text-transform: uppercase;\n  color: #1d1d1d;\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://src/components/Text/styles.module.scss","webpack://src/constants/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cCaO;EDZP,yBAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cCIO;EDHP,yBAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,cCLO;EDMP,SAAA;AADF","sourcesContent":["@import '@constants/styles.scss';\n\n.h1 {\n  font-weight: 500;\n  font-size: 60px;\n  line-height: 140%;\n  text-align: center;\n  color: $black1;\n  text-transform: uppercase;\n}\n\n.h2 {\n  font-weight: 500;\n  font-size: 36px;\n  line-height: 140%;\n  text-align: center;\n  color: $black1;\n  text-transform: uppercase;\n}\n\n.h3 {\n  font-weight: 500;\n  font-size: 19px;\n  line-height: 140%;\n  text-transform: uppercase;\n  color: $black1;\n  margin: 0;\n}\n","$fontFamily: helvetica neue;\n$fontello: fontello;\n\n$futura: futura;\n$openSans: 'Open Sans', sans-serif;\n\n$fontIBM: ibm plex sans;\n\n$pageWidth: 1070px;\n\n$gray: #f2f2f2;\n\n$darkGrey: #828282;\n$darkGrey1: #797979;\n$lightGrey: #bdbdbd;\n$lightGrey1: #e0e0e0;\n$lightGrey2: #fafafa;\n$lightGrey3: #dcdcdc;\n\n$black: #333;\n$black1: #1d1d1d;\n$blackHalfTransparent: rgba(0, 0, 0, 0.5);\n\n$yellow: #e3bd67;\n\n$primaryBlue: #3e66fb;\n\n$blue: #2f80ed;\n\n$black: #1d1d1d;\n$white: #fff;\n\n$gold: #e3bd67;\n\n$red: #eb5757;\n\n$cardHeight: 120px;\n$cardWidth: 330px;\n\n$animationTimeout: 5s;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h1": "styles_h1__2Aogq",
	"h2": "styles_h2__9_wge",
	"h3": "styles_h3__lnRum"
};
export default ___CSS_LOADER_EXPORT___;
