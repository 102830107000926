import { ProposalItemCreate } from '@types';
import { ProposalItemState } from '@store/proposalItem/types';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';

export const proposalItemSetState = (payload: Partial<ProposalItemState>) => ({
  type: ProposalItemActionTypes.SET_STATE,
  payload,
});

export const proposalIteGet = (hashProposal: string) => ({
  type: ProposalItemActionTypes.GET_PROPOSAL,
  payload: hashProposal,
});

export const proposalIteBuyVotingToken = () => ({
  type: ProposalItemActionTypes.BUY_VOTING_TOKEN,
});

export const proposalItemVote = (payload:{
  proposalId:number,
  choice:number,
  value: string,
}) => ({
  type: ProposalItemActionTypes.VOTE,
  payload,
});

export const proposalItemCreate = (proposal: ProposalItemCreate) => ({
  type: ProposalItemActionTypes.CREATE_PROPOSAL,
  payload: proposal,
});

export const bridgeProposalItemCreate = (proposal: ProposalItemCreate) => ({
  type: ProposalItemActionTypes.BRIDGE_CREATE_PROPOSAL,
  payload: proposal,
});

export const proposalItemGetPricesForVoting = () => ({
  type: ProposalItemActionTypes.GET_PRICES_FOR_VOTING,
});
