import { ReduxState } from '@store/types';
import { AdminState } from './types';

export const adminSelectors = {
  getState: () => (
    state: ReduxState,
  ) => state.admin,
  getProp: <PropKey extends keyof AdminState>(propKey: PropKey) => (
    state: ReduxState,
  ) => state.admin[propKey],
};
