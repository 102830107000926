import React from 'react';
import { Text } from '@components';
import styles from './styles.module.scss';

type TagsBuy = {
  labels: string[];
};

const Tags = ({ labels }: TagsBuy) => (
  <div className={styles.containerTags}>
    {labels.map((name) => (
      <Text className={styles.tag} key={`${name}`}>{`@${name}`}</Text>
    ))}
  </div>
);

export { Tags };
