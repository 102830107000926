import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import auth from '@store/auth/reducer';
import admin from '@store/admin/reducer';
import profile from '@store/profile/reducer';
import proposals from '@store/proposals/reducer';
import proposalItem from '@store/proposalItem/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PERSIST_CONFIGS: Record<string, PersistConfig<any>> = {
  auth: {
    storage,
    key: 'auth',
    whitelist: ['accessToken', 'refreshToken', 'exp', 'expRefresh', 'roles', 'addressHash'],
  },
};

export default combineReducers({
  admin,
  auth: persistReducer(PERSIST_CONFIGS.auth, auth),
  profile,
  proposals,
  proposalItem,
});
