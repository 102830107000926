import { styled } from 'styled-components';
import { getIconSize, toEnd } from '../../styles';

export const TabsWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  width: 100%;
  height: 40px;
  padding: 4px;

  border-radius: 8px;

  user-select: none;
  background: #19191a;

  ${toEnd('mobile')} {
    height: 48px;
  }
`;

export const Item = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;
  height: 32px;

  font-size: 16px;
  font-weight: 500;
  line-height: 20.42px;

  background-color: transparent;

  border-radius: 4px;

  transition: all 0.2s ease-in-out, color 0.3s ease-in-out;

  z-index: 1;

  cursor: pointer;

  ${toEnd('mobile')} {
    height: 40px;
  }

  background: ${({ isActive }) => (isActive ? '#2B272F' : 'none')};


`;

export const TabCarriage = styled.div<{ isRightPosition?: boolean; isLight?: boolean }>`
  position: absolute;
  top: 4px;
  left: ${({ isRightPosition }) => (isRightPosition ? 'calc(50% + 2px)' : '4px')};

  width: calc(50% - 6px);
  height: 32px;

  border-radius: 4px;

  transition: left 0.2s ease-in-out;

  ${toEnd('mobile')} {
    height: 40px;
  }
`;

export const IconWrapper = styled.div<{ isActive: boolean }>`
  ${getIconSize()}

  svg {
    ${getIconSize()}

    pointer-events: none;

    path {
      transition: all 0.2s ease-in-out;

      fill: ${({ isActive }) => (isActive ? '#fff' : '#504D51')};
    }
  }
`;
