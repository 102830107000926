import React, {
  FC, ReactElement, useEffect, useState,
} from 'react';

import { HeaderTheme, RouteName, StatusHeader } from '@types';
import { useAuth } from '@hooks';
import { logoFull, logoMob } from '@assets/images';
import { getChainConfig, getShortAddress, switchChainId } from '@utils';
import { chains } from '@constants';
import { useWeb3React } from '@web3-react/core';
import { DropdownSelect } from '../DropdownSelect';
import { useGetScroll } from '../../hooks/useGetScroll';
import { ConnectButton } from '../connect-button';
import { Nav } from './components';
import {
  AddressMob,
  Burger,
  ChainIcon,
  ConnectButtonWrapper,
  Content,
  LogoDesk,
  LogoMob,
  NetworkButton,
  NetworkButtonMob,
  Wrapper,
} from './Header.styled';

const chainConfig = getChainConfig();

export const Header: FC<{
  status: StatusHeader;
  theme?: HeaderTheme;
  address?:string|null
  isAdmin?:boolean
  className?:unknown
}> = ({ status }): ReactElement => {
  const { account } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isScrolled = useGetScroll();
  const isConnected = status === StatusHeader.connected;
  const { chainId } = useWeb3React();
  const [isNetworkSelectOpen, setIsNetworkSelectOpen] = useState(false);

  const setSelectedChain = async (id: number | string) => {
    const chain = chains.find((item) => item.chainId === id);

    if (!chain) {
      return;
    }

    try {
      await switchChainId(chain);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleMenuOpen = () => {
    setIsMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    document.body.style.overflow = isNetworkSelectOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isNetworkSelectOpen]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Wrapper isScrolled={isScrolled || isNetworkSelectOpen} isOpen={isMenuOpen}>
      <Content>
        <LogoMob to={RouteName.home}>
          <img
            src={logoMob}
            alt="logo"
          />
        </LogoMob>
        <LogoDesk to={RouteName.home}>
          <img
            src={logoFull}
            alt="logo"
            className="logoFull"
          />
        </LogoDesk>
        <Nav open={isMenuOpen} />
        {mounted && isConnected && account && (
          <AddressMob>
            <div style={{ maxWidth: 175 }}>
              <DropdownSelect
                items={chainConfig}
                selectedItem={chainConfig.find((item) => item.id === chainId)}
                isWithGrid
                setSelectedItem={setSelectedChain}
                fullScreen
                fullHeight
                customTitle="Select chain"
                onClose={() => setIsNetworkSelectOpen(false)}
                onOpen={() => setIsNetworkSelectOpen(true)}
                customButton={(
                  <NetworkButtonMob>
                    <ChainIcon
                      src={chainConfig.find((item) => item.id === chainId)?.icon}
                      size={28}
                    />
                    {getShortAddress(account)}
                  </NetworkButtonMob>
              )}
                {...{ setSelectedChain }}
              />
            </div>
          </AddressMob>
        )}
        <Burger open={isMenuOpen} onClick={onHandleMenuOpen}>
          <span />
        </Burger>

        <ConnectButtonWrapper>
          {mounted && isConnected && account ? (
            <DropdownSelect
              items={chainConfig}
              selectedItem={chainConfig.find((item) => item.id === chainId)}
              isWithGrid
              setSelectedItem={setSelectedChain}
              customButton={(
                <NetworkButton>
                  <ChainIcon
                    src={chainConfig.find((item) => item.id === chainId)?.icon}
                    size={24}
                  />
                  {getShortAddress(account)}
                </NetworkButton>
  )}
              {...{ setSelectedChain }}
            />
          ) : (
            <ConnectButton />
          )}
        </ConnectButtonWrapper>
      </Content>
    </Wrapper>
  );
};
