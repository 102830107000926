import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from '@store/profile/selectors';

const useLandAreaProposal = () => {
  const locationProfile = useSelector(
    profileSelectors.getProp('location'),
  );

  const countryProfile = locationProfile.country.value;
  const regionProfile = locationProfile.structure.value;
  const cityProfile = locationProfile.city.value;
  // const regionProfile = useSelector(
  //   profileSelectors.getProp('region'),
  // );

  // const cityProfile = useSelector(
  //   profileSelectors.getProp('city'),
  // );

  const areasOption = useMemo(() => [
    {
      label: `${countryProfile || 'Canada'}`,
      value: 'country',
    },
    {
      // @TODO check when logic is ready
      label: 'Alberta',
      value: 'region',
    },
    {
      label: `${cityProfile || 'Calgary'}`,
      value: 'city',
    },
  ], [cityProfile, countryProfile, regionProfile]);
  // @TODO {name:value}
  const formatLand = useCallback(({ label, value }: { label: string, value: string }) => {
    if (value === 'country') {
      return {
        country: label,
      };
    }
    if (value === 'region') {
      return {
        country: countryProfile,
        region: regionProfile,
      };
    }
    return {
      country: countryProfile,
      region: regionProfile,
      city: cityProfile,
    };
  }, [cityProfile, countryProfile, regionProfile]);

  return {
    formatLand,
    areasOption,
  };
};

export { useLandAreaProposal };
