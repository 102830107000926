import api from '@api';
import { throwApiError, formatProposalItemResponse, formatGetProposalItemResponse } from '@utils';
import {
  ProposalRaw,
  ProposalItem,
  ProposalItemCreate,
  ProposalListResponse,
} from '@types';

export const getProfileProposals = async (
  requestUrl: string,
): Promise<ProposalListResponse> => {
  const { data } = await api.get(requestUrl);
  if (data.error) {
    throwApiError(data);
  }

  return { list: data?.data, total: data.total };
};

export const getProposalsList = async (requestUrl: string): Promise<ProposalListResponse> => {
  const { data } = await api.get(requestUrl);
  if (data.error) {
    throwApiError(data);
  }

  return {
    list: data,
    // list:  data.data
    total: data.total,
  };
};

export const getProposalsSearch = async (
  requestUrl: string,
): Promise<{ list: ProposalRaw[], total: number }> => {
  const { data } = await api.get(`/proposal/search${requestUrl}`);

  if (data.error) {
    throwApiError(data);
  }
  return { list: data?.data, total: data.total };
};

export const getProposal = async (hashItem: string): Promise<ProposalItem> => {
  const { data } = await api.get(`/proposal/get/${hashItem}`);

  if (data.error) {
    throwApiError(data);
  }

  return formatGetProposalItemResponse(data);
};

export const addToAllowList = async (address: string) => {
  const { data } = await api.post('/bc-gate/add_to_allow_list', { contract_id: address });

  if (data.error) {
    throwApiError(data);
  }
};

export const createProposal = async (proposal: ProposalItemCreate): Promise<ProposalItem> => {
  const { data } = await api.post('/proposal/create', proposal);

  if (data.error) {
    throwApiError(data);
  }

  return formatProposalItemResponse(data);
};

export const getSignatureBridgeCreateProposal = async (bridgeProposalData:{
  'network': string
  'proposalType':number
  'duration':number
  'totalVoters':number
  'minConsensusVoters': number
  'description': string
  'whitelist': string[]
}) => {
  const { data } = await api.post('/profile/bridge-create-proposal', bridgeProposalData);

  if (data.error) {
    throwApiError(data);
  }

  return data;
};
