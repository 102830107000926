import { gatewayIPFS } from '@constants';
import React from 'react';

type ImageIpfsProps = {
  className?: string;
  imageHash: string;
};

const ImageIpfs = ({ imageHash, className = '' }: ImageIpfsProps) => (
  <img
    src={`${gatewayIPFS}${imageHash}`}
    alt="ImageIpfs"
    className={className}
  />
);

export { ImageIpfs };
