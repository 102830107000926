import React, { useState, useCallback, useMemo } from 'react';
import {
  Text,
  // Modal,
  SearchInput,
} from '@components';
import { ProposalType } from '@types';
import { useGetProposalsList, useGetProposalsSearch } from '@hooks';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/auth/selectors';
import { profileSelectors } from '@store/profile/selectors';
import { useProposalsModal } from './hooks';
import { CARDS_PER_PAGE } from './constants';
import { TabsProposals } from './components/TabsProposals';
import styles from './styles.module.scss';

const Proposals = () => {
  const [searchInput, setSearchInput] = useState('');
  const hasPassport = useSelector(authSelectors.getProp('hasPassport'));
  // const { isVerified, handleVerifyClick, isAddressVerified } = useKyc();
  const location = useSelector(profileSelectors.getProp('location'));
  const isAddressVerified = useMemo(() => hasPassport && Boolean(location?.country?.value),
    [hasPassport, location?.country?.value]);
  const [tabKey, setTabKey] = useState<ProposalType>(ProposalType.CryptoVoting);
  const [page, setPage] = useState(1);
  const handleVerifyClick = () => {
    window.open(process.env.REACT_APP_REPRESENT_PASSPORT_URL, '_blank', 'noopener,noreferrer');
  };

  const {
    // isVerifyModalOpened,
    // verifyAccountButtons,
    // closeModal,
    handleShowModal,
  } = useProposalsModal(
    hasPassport,
    handleVerifyClick,
  );

  const isSearchInputEmpty = useMemo(
    () => searchInput.replace(/\s/g, '') === '',
    [searchInput],
  );

  const {
    commonProposalsList,
    commonProposalsTotal,
    isProposalsListLoading,
  } = useGetProposalsList({
    pageLimit: CARDS_PER_PAGE,
    proposalType: tabKey,
    page,
    isSearchInputEmpty,
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchProposalsList,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchProposalsTotal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isProposalsSearchLoading,
  } = useGetProposalsSearch({
    query: searchInput,
    proposalType: tabKey,
    page,
    pageLimit: CARDS_PER_PAGE,
  });

  const handleSearchChange = useCallback((input: string) => {
    setSearchInput(input);
    setPage(1);
  }, [setPage]);

  return (
    <section className={styles.container}>
      <div className={styles.titleWrapper}>
        <Text type="h3" className={styles.title}>
          PROPOSALS
        </Text>
      </div>
      <div className={styles.inputWrapper}>
        <SearchInput
          value={searchInput}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        {/* <Button onClick={handleCreateProposal} className={styles.smallButton}>
          <Icon
            value={Icons.plus}
            className={styles.smallButtonPlus}
          />
        </Button> */}
        {/* <Button onClick={handleCreateProposal} className={styles.bigButton}>
          <Icon value={Icons.plus} className={styles.plusIcon} />
          Create new proposal
        </Button> */}
      </div>
      {/* {isSearchInputEmpty ? ( */}
      <TabsProposals
        isLoading={isProposalsListLoading}
        proposals={commonProposalsList}
        isAddressVerified={isAddressVerified}
        isVerified={hasPassport}
        total={commonProposalsTotal}
        handleShowModal={handleShowModal}
        setCurrentTabKey={setTabKey}
        setCurrentPage={setPage}
        isSearch
      />
      {/* ) : (
        <TabsProposals
          isLoading={isProposalsSearchLoading}
          proposals={searchProposalsList}
          isAddressVerified={isAddressVerified}
          isVerified={hasPassport}
          total={searchProposalsTotal}
          handleShowModal={handleShowModal}
          setCurrentTabKey={setTabKey}
          setCurrentPage={setPage}
          isSearch
        />
      )} */}
      {/* <Modal
        title="please purchase the nft passport "
        description="You have to mint Represent
        ID NFT passport and hold it in the connected wallet to participate
         in any proposals, please visit Represent ID
          service, go through the verification process and mint your own passport."
        isOpen={isVerifyModalOpened}
        onClose={closeModal}
        buttons={verifyAccountButtons}
      /> */}
    </section>
  );
};

export { Proposals };
