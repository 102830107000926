import { ethers } from 'ethers';
import { BigNumber } from 'bignumber.js';
import { getContract, getAddress, getChainId } from '@api/ethereum';
import { RPVTokenAbi } from '@assets/abi';
import { contracts, decimalPlaces } from '@constants';

async function getContractRPVToken() {
  const chainId = await getChainId();
  return getContract(contracts[chainId]?.RPVtoken, RPVTokenAbi);
}

export const getDecimalsRPVToken = async (): Promise<number> => {
  const contract = await getContractRPVToken();
  const result = await contract.methods.decimals().call();
  return result;
};

export const getBalanceRPVToken = async (address: string): Promise<string> => {
  const contract = await getContractRPVToken();

  const result = await contract.methods.balanceOf(address).call();
  const decimals = await contract.methods.decimals().call();

  return (
    new BigNumber(result)
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces).toString()
  );
};

export const approveRPVToken = async (addressApprove: string) => {
  const contract = await getContractRPVToken();
  const address = await getAddress();
  return contract.methods
    .approve(addressApprove, ethers.constants.MaxUint256)
    .send({ from: address });
};

export const allowanceRPVToken = async (addressApprove: string): Promise<string> => {
  const contract = await getContractRPVToken();
  const address = await getAddress();
  const decimals = await contract.methods.decimals().call();

  const result = await contract.methods
    .allowance(address, addressApprove)
    .call();
  return (
    new BigNumber(result)
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces).toString());
};
