import { ReactNode, createElement } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type TextProps = {
  className?: string;
  type?: 'h1' | 'h2' | 'h3' | 'span' | 'p';
  children: ReactNode;
};

const Text = ({ className = '', type = 'span', children }: TextProps) => {
  const style = cx({ [type]: true }, className);
  return createElement(type, { className: style }, children);
};

export { Text };
